<div class="upload-container">
  <app-loader *ngIf="isLoading"></app-loader>
  <div *ngIf="!isLoading">
    <div *ngIf="!imgSrc" class="thumbnails-empty">
      <button type="button" mat-icon-button color="accent" (click)="onUploadClick()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <img *ngIf="imgSrc" [src]="imgSrc" class="thumbnails" />
    <div *ngIf="imgSrc" class="full-width control-container margin-vertical-10">
      <button mat-icon-button [matTooltip]="'Delete image' | translate" (click)="onCancelUploadImage()">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button [matTooltip]="'Upload new image' | translate" (click)="onUploadClick()">
        <mat-icon>publish</mat-icon>
      </button>
      <button mat-icon-button *ngIf="isImageSet" [matTooltip]="'Edit Image' | translate" (click)="onEditImage()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button *ngIf="isImageSet" [matTooltip]="'Resize Image' | translate"
        (click)="showResize = !showResize">
        <mat-icon>aspect_ratio</mat-icon>
      </button>
    </div>

    <!-- Resize -->
    <app-image-resize [style.display]="showResize ? 'block' : 'none'" [imageUrl]="resizeImageUrl"
      (resize)="onImageResize($event)" (cancel)="showResize = false"></app-image-resize>

  </div>
  <div>
    <mat-icon class="image-from-clipboard" matTooltip="Take image from clipboard" matTooltipPosition="after"
      (click)="onImageFromClipboard()">
      content_paste_go</mat-icon>
    <mat-icon class="image-from-clipboard" *ngIf="isWindows"
      matTooltip="How to save a screenshot of part of your screen to clipboard" matTooltipPosition="after"
      (click)="onShowTakeScreenshot()"> info </mat-icon>
  </div>
</div>

<input type="file" #fileUpload accept="image/*" style="display:none;" (change)="onUploadChange()" />