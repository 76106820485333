import { RuleField, comparisonInputType, comparisonValueType, ruleOperatorType, predicateGroupName } from '../../shared/models/models';

export const AudienceRules: RuleField[] =
  [
    {
      name: 'Pageviews Count',
      predicate: 'PageviewsCount',
      valueType: comparisonValueType.int,
      valuePlaceholder: 'pageviews count',
      isDaysPredicate: false,
      fieldType: comparisonInputType.number,
      operatorType: ruleOperatorType.ruleIntOperators,
      group: predicateGroupName.activity,
      description: 'The number of pages viewed by visitor'
    },
    {
      name: 'Urls Visited',
      predicate: 'PagesVisited',
      valueType: comparisonValueType.string,
      valuePlaceholder: 'page url',
      isDaysPredicate: false,
      fieldType: comparisonInputType.url,
      operatorType: ruleOperatorType.ruleCollectionPositive,
      group: predicateGroupName.activity,
      description: ''
    },
    {
      name: 'Visitor\'s Original UTM Campaign',
      predicate: 'FirstSessionUtmCampaign',
      valueType: comparisonValueType.campaigns_collection,
      valuePlaceholder: 'select campaigns',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'UTM Campaign that the visitor originally came from '
    },
    {
      name: 'Visitor\'s Original UTM Source',
      predicate: 'FirstSessionUtmSource',
      valueType: comparisonValueType.sources_collection,
      valuePlaceholder: 'select sources',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'UTM Source that the visitor originally came from '
    },
    {
      name: 'Passed Via Content Area',
      predicate: 'PassedViaContentArea',
      valueType: comparisonValueType.boolean,
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.activity,
      description: 'Has visitor passed through any content areas'
    },
    {
      name: 'Visitor\'s Original Referral Source',
      predicate: 'FirstSessionSourceDomain',
      valueType: comparisonValueType.referrers_collection,
      valuePlaceholder: 'select referrers',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'Referral Source that the visitor originally came from'
    },
    {
      name: 'Visitor\'s Original Source Type',
      predicate: 'FirstSessionSourceType',
      valueType: comparisonValueType.source_types_collection,
      valuePlaceholder: 'select source types',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'Referral Source Type that the visitor originally came from'
    },
    {
      name: 'Reads',
      predicate: 'ReadsInLast{days}Days',
      valueType: comparisonValueType.int,
      valuePlaceholder: 'reads count',
      isDaysPredicate: true,
      fieldType: comparisonInputType.number,
      operatorType: ruleOperatorType.ruleIntOperators,
      group: predicateGroupName.activity,
      description: 'The number of pages that were read by visitor'
    },
    {
      name: 'Sessions',
      predicate: 'SessionsInLast{days}Days',
      valueType: comparisonValueType.int,
      valuePlaceholder: 'sessions count',
      isDaysPredicate: true,
      fieldType: comparisonInputType.number,
      operatorType: ruleOperatorType.ruleIntOperators,
      group: predicateGroupName.activity,
      description: 'The number of sessions'
    },
    {
      name: 'Areas Visited',
      predicate: 'AreasVisited',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Areas which were visited by user'
    },
    {
      name: 'Page Tags Visited',
      predicate: 'TagsVisited',
      valueType: comparisonValueType.tags_collection,
      valuePlaceholder: 'select tags',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Page tags which were visited by user'

    },
    {
      name: 'Visitor\'s Country',
      predicate: 'OriginalGeoLocation.IsoCode',
      valueType: comparisonValueType.country_iso_collection,
      valuePlaceholder: 'start typing a country name',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.visitor,
      description: ''
    },
    {
      name: 'Visitor\'s Region',
      predicate: 'OriginalGeoLocation.GeoFullLocation',
      valueType: comparisonValueType.region_iso_collection,
      valuePlaceholder: 'start typing a region name',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.visitor,
      description: ''
    },
    {
      name: 'Company Name',
      predicate: 'AccountCompanyDetails.CleanCompanyName',
      valueType: comparisonValueType.string,
      valuePlaceholder: 'e.g. "Microsoft Corporation"',
      isDaysPredicate: false,
      fieldType: comparisonInputType.textField,
      operatorType: ruleOperatorType.ruleStringOperators,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Company Size',
      predicate: 'AccountCompanyDetails.Size',
      valueType: comparisonValueType.company_sizes_collection,
      valuePlaceholder: 'select company size',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperatorsInclude,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Company Revenue',
      predicate: 'AccountCompanyDetails.Revenue',
      valueType: comparisonValueType.company_revenues_collection,
      valuePlaceholder: 'select company revenue',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperatorsInclude,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Company Identified',
      predicate: 'CompanyIdentified',
      valueType: comparisonValueType.boolean,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.visitor,
      description: 'Visitor Company has a name (or not)',
      feature: 'abm'
    },
    {
      name: 'Industries',
      predicate: 'AccountCompanyDetails.Industry',
      valueType: comparisonValueType.industries_collection,
      valuePlaceholder: 'select industries',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Companies Target Lists',
      predicate: 'AbmTargetLists',
      valueType: comparisonValueType.target_lists,
      valuePlaceholder: 'Select target List',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: 'Companies Target List that are associated with the visitor',
      feature: 'abm'
    },
    {
      name: 'Audiences',
      predicate: 'CurrentAudiences',
      valueType: comparisonValueType.audiences_collection,
      valuePlaceholder: 'select audiences',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: 'Visitor belongs to a pre-defined audience'
    },
    {
      name: 'Completed Events Or Goals',
      predicate: 'EventsOrGoals',
      valueType: comparisonValueType.events_and_goals_collection,
      valuePlaceholder: 'select events & goals',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Visitor reached these goals or events'
    },
    {
      name: 'Visitor\'s Email',
      predicate: 'Email',
      valueType: comparisonValueType.string,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.email,
      operatorType: ruleOperatorType.ruleStringOperators,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'identifyJourney'
    },
    {
      name: 'Participating UTM Campaigns',
      predicate: 'Campaigns',
      valueType: comparisonValueType.campaigns_collection,
      valuePlaceholder: 'select campaigns',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Campaigns that participated in the visitor\'s journey'
    },
    {
      name: 'Participating UTM Sources',
      predicate: 'Sources',
      valueType: comparisonValueType.sources_collection,
      valuePlaceholder: 'select sources',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.sources,
      description: 'UTM Sources that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Referral Sources',
      predicate: 'SourceDomains',
      valueType: comparisonValueType.referrers_collection,
      valuePlaceholder: 'select referrers',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'Referrals that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm Mediums',
      predicate: 'UtmMediumList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_medium',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Mediums that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm Contents',
      predicate: 'UtmContentList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_content',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Contents that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm Terms',
      predicate: 'UtmTermList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_term',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Terms that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm AdGroups',
      predicate: 'UtmAdGroupList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_adgroup',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM AdGroups that participated in the visitor\'s journey'
    },
    {
      name: 'Engaged with any TrenDemon Units',
      predicate: 'PassedViaAssistingAction',
      valueType: comparisonValueType.boolean,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.activity,
      description: 'Whether visitor engaged with any TrenDemon Activation Units'
    },
    {
      name: 'Engaged with specific TrenDemon units',
      predicate: 'CompletedUnits',
      valueType: comparisonValueType.units_collection,
      valuePlaceholder: 'select units',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Has visitor engaged with these specific Units'
    },
    {
      name: 'Has Email',
      predicate: 'HasEmail',
      valueType: comparisonValueType.boolean,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.visitor,
      description: 'Visitor is an identified lead on TrenDemon',
      feature: 'identifyJourney'
    },
    {
      name: 'Survey Answers',
      predicate: 'SurveyAnswers',
      valueType: comparisonValueType.question_collection,
      valuePlaceholder: 'select question and answer',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Answers that users gave on survey questions',
      valuesGrouped: true
    },
    {
      name: 'Visited 1-3 Pages in Areas',
      predicate: 'AreasViews1To3',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Areas in which user visited 1 - 3 pages'
    },
    {
      name: 'Visited 4-8 Pages in Areas',
      predicate: 'AreasViews4To8',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Areas in which user visited 4 - 8 pages'
    },
    {
      name: 'Visited more than 8 Pages in Areas',
      predicate: 'AreasViews9AndMore',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Areas in which user visited more than 8 pages'
    },
    {
      name: 'Read 1-3 Pages in Areas',
      predicate: 'AreasReads1To3',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Areas in which user read 1-3 pages'
    },
    {
      name: 'Read 4-8 Pages in Areas',
      predicate: 'AreasReads4To8',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Areas in which user read 4-8 pages'
    },
    {
      name: 'Read more than 8 Pages in Areas',
      predicate: 'AreasReads9AndMore',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Areas in which user read more than 8 pages'
    },
    {
      name: 'Drift Events',
      predicate: 'DriftEventsInLast{days}Days',
      valueType: comparisonValueType.drift_events_collection,
      valuePlaceholder: 'event',
      isDaysPredicate: true,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Engagements with Drift',
      clientApp: 'drift'
    },
    {
      name: '6Sense Segments',
      predicate: 'SixSenseEventsInLast{days}Days',
      valueType: comparisonValueType.sixsense_segments,
      valuePlaceholder: 'segment',
      isDaysPredicate: true,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Belongs to a 6Sense segment',
      clientApp: 'sixsense'
    }
  ];

  export const EngagementReportRules: RuleField[] =
  [
    {
      name: 'Audiences',
      predicate: 'CurrentAudiences',
      valueType: comparisonValueType.audiences_collection,
      valuePlaceholder: 'select audiences',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: 'Visitor belongs to a pre-defined audience'
    },
    {
      name: 'Company Name',
      predicate: 'AccountCompanyDetails.CleanCompanyName',
      valueType: comparisonValueType.string,
      valuePlaceholder: 'e.g. "Microsoft Corporation"',
      // enableConfig: 'user_company_personalization',
      isDaysPredicate: false,
      fieldType: comparisonInputType.textField,
      operatorType: ruleOperatorType.ruleStringOperators,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Company Size',
      predicate: 'AccountCompanyDetails.Size',
      valueType: comparisonValueType.company_sizes_collection,
      valuePlaceholder: 'select company size',
      // enableConfig: 'user_company_personalization',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperatorsInclude,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Company Revenue',
      predicate: 'AccountCompanyDetails.Revenue',
      valueType: comparisonValueType.company_revenues_collection,
      valuePlaceholder: 'select company revenue',
      // enableConfig: 'user_company_personalization',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperatorsInclude,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Company Identified',
      predicate: 'CompanyIdentified',
      valueType: comparisonValueType.boolean,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.visitor,
      description: 'Visitor Company has a name (or not)',
      feature: 'abm'
    },
    {
      name: 'Industries',
      predicate: 'AccountCompanyDetails.Industry',
      valueType: comparisonValueType.industries_collection,
      valuePlaceholder: 'select industries',
      // enableConfig: 'user_company_personalization',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'abm'
    },
    {
      name: 'Companies Target Lists',
      predicate: 'AbmTargetLists',
      valueType: comparisonValueType.target_lists,
      valuePlaceholder: 'Select target List',
      // enableConfig: 'user_company_personalization',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: 'Companies Target List that are associated with the visitor',
      feature: 'abm'
    },
    {
      name: 'Contact Target Lists',
      predicate: 'ContactTargetLists',
      valueType: comparisonValueType.contact_target_lists,
      valuePlaceholder: 'Select target List',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.visitor,
      description: 'Contacts Target List that are associated with the visitor'
    },
    {
      name: 'Visitor\'s Email',
      predicate: 'Email',
      valueType: comparisonValueType.string,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.email,
      operatorType: ruleOperatorType.ruleStringOperators,
      group: predicateGroupName.visitor,
      description: '',
      feature: 'identifyJourney'
    },
    {
      name: 'Passed Via Content Area',
      predicate: 'PassedViaContentArea',
      valueType: comparisonValueType.boolean,
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.activity,
      description: 'Has visitor passed through any content areas'
    },
    {
      name: 'Areas Visited',
      predicate: 'AreasVisited',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Areas which were visited by user'
    },
    {
      name: 'Page Tags Visited',
      predicate: 'TagsVisited',
      valueType: comparisonValueType.tags_collection,
      valuePlaceholder: 'select tags',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Page tags which were visited by user'
    },
    {
      name: 'Current Session Page Tags Visited',
      predicate: 'CurrentSessionTagsVisited',
      valueType: comparisonValueType.tags_collection,
      valuePlaceholder: 'select tags',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.currentSession,
      description: 'Page tags which were visited by user'
    },
    {
      name: 'Current Session Areas Visited',
      predicate: 'CurrentSessionAreasVisited',
      valueType: comparisonValueType.areas_collection,
      valuePlaceholder: 'select areas',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.currentSession,
      description: 'Areas which were visited by user'
    },
    {
      name: 'Streams Viewed',
      predicate: 'StreamsViewed',
      valueType: comparisonValueType.streams_collection,
      valuePlaceholder: 'select streams',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Streams which were viewed by user'
    },
    {
      name: 'One-To-Won Pages Viewed',
      predicate: 'OtwViewed',
      valueType: comparisonValueType.otws_collection,
      valuePlaceholder: 'select One-To-Won pages',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'One-To-Won pages which were viewed by user'
    },
    {
      name: 'Current Session Streams Viewed',
      predicate: 'CurrentSessionStreamsViewed',
      valueType: comparisonValueType.streams_collection,
      valuePlaceholder: 'select streams',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.currentSession,
      description: 'Streams which were viewed by user'
    },
    {
      name: 'Current Session One-To-Won Pages Viewed',
      predicate: 'CurrentSessionOtwViewed',
      valueType: comparisonValueType.otws_collection,
      valuePlaceholder: 'select One-To-Won pages',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.currentSession,
      description: 'One-To-Won pages which were viewed by user'
    },
    {
      name: 'Visitor\'s Original UTM Campaign',
      predicate: 'FirstSessionUtmCampaign',
      valueType: comparisonValueType.campaigns_collection,
      valuePlaceholder: 'select campaigns',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'UTM Campaign that the visitor originally came from '
    },
    {
      name: 'Visitor\'s Original UTM Source',
      predicate: 'FirstSessionUtmSource',
      valueType: comparisonValueType.sources_collection,
      valuePlaceholder: 'select sources',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'UTM Source that the visitor originally came from '
    },
    {
      name: 'Visitor\'s Original Referral Source',
      predicate: 'FirstSessionSourceDomain',
      valueType: comparisonValueType.referrers_collection,
      valuePlaceholder: 'select referrers',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'Referral Source that the visitor originally came from'
    },
    {
      name: 'Visitor\'s Original Source Type',
      predicate: 'FirstSessionSourceType',
      valueType: comparisonValueType.source_types_collection,
      valuePlaceholder: 'select source types',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.sources,
      description: 'Referral Source Type that the visitor originally came from'
    },
    {
      name: 'Visitor\'s Country',
      predicate: 'OriginalGeoLocation.IsoCode',
      valueType: comparisonValueType.country_iso_collection,
      valuePlaceholder: 'start typing a country name',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.visitor,
      description: ''
    },
    {
      name: 'Visitor\'s Region',
      predicate: 'OriginalGeoLocation.GeoFullLocation',
      valueType: comparisonValueType.region_iso_collection,
      valuePlaceholder: 'start typing a region name',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleStringFromListOperators,
      group: predicateGroupName.visitor,
      description: ''
    },
    {
      name: 'Completed Events Or Goals',
      predicate: 'EventsOrGoals',
      valueType: comparisonValueType.events_and_goals_collection,
      valuePlaceholder: 'select events & goals',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.activity,
      description: 'Visitor reached these goals or events'
    },
    {
      name: 'Participating UTM Campaigns',
      predicate: 'Campaigns',
      valueType: comparisonValueType.campaigns_collection,
      valuePlaceholder: 'select campaigns',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Campaigns that participated in the visitor\'s journey'
    },
    {
      name: 'Participating UTM Sources',
      predicate: 'Sources',
      valueType: comparisonValueType.sources_collection,
      valuePlaceholder: 'select sources',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.sources,
      description: 'UTM Sources that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Referral Sources',
      predicate: 'SourceDomains',
      valueType: comparisonValueType.referrers_collection,
      valuePlaceholder: 'select referrers',
      isDaysPredicate: false,
      fieldType: comparisonInputType.autocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperators,
      group: predicateGroupName.sources,
      description: 'Referrals that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm Mediums',
      predicate: 'UtmMediumList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_medium',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Mediums that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm Contents',
      predicate: 'UtmContentList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_content',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Contents that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm Terms',
      predicate: 'UtmTermList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_term',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM Terms that participated in the visitor\'s journey'
    },
    {
      name: 'Participating Utm AdGroups',
      predicate: 'UtmAdGroupList',
      valueType: comparisonValueType.empty_collection,
      valuePlaceholder: 'select utm_adgroup',
      isDaysPredicate: false,
      fieldType: comparisonInputType.freeTextAutocomplete,
      operatorType: ruleOperatorType.ruleCollectionOperatorsInclElementContains,
      group: predicateGroupName.sources,
      description: 'UTM AdGroups that participated in the visitor\'s journey'
    },
    {
      name: 'Has Email',
      predicate: 'HasEmail',
      valueType: comparisonValueType.boolean,
      valuePlaceholder: '',
      isDaysPredicate: false,
      fieldType: comparisonInputType.hidden,
      operatorType: ruleOperatorType.ruleBooleanOperators,
      group: predicateGroupName.visitor,
      description: 'Visitor is an identified lead on TrenDemon',
      feature: 'identifyJourney'
    },
    {
      name: 'Survey Answers',
      predicate: 'SurveyAnswers',
      valueType: comparisonValueType.question_collection,
      valuePlaceholder: 'select question and answer',
      isDaysPredicate: false,
      fieldType: comparisonInputType.multiselect,
      operatorType: ruleOperatorType.ruleCollectionOperatorsReduced,
      group: predicateGroupName.activity,
      description: 'Answers that users gave on survey questions',
      valuesGrouped: true
    }
  ];

export const CompaniesSizes: string[] = [
  'Micro (1 - 9 Employees)',
  'Small (10 - 49 Employees)',
  'Medium-Small (50 - 199 Employees)',
  'Medium (200 - 499 Employees)',
  'Medium-Large (500 - 999 Employees)',
  'Large (1,000 - 4,999 Employees)',
  'XLarge (5,000 - 10,000 Employees)',
  'XXLarge (10,000+ Employees)'
];
export const CompaniesRevenues: string[] = [
  'Micro (<$1MM)',
  'Small ($1MM-$10MM)',
  'Medium-Small ($10MM-$50MM)',
  'Medium ($50MM-$100MM)',
  'Medium-Large ($100MM-$250MM)',
  'Large ($250MM-$500MM)',
  'XLarge ($500MM-$1B)',
  'XXLarge ($1B+)'
];
export const Industries: string[] = [
  'Aerospace and Defense',
  'Agriculture',
  'Apparel',
  'Associations',
  'Automotive',
  'Biotech',
  'Business Services',
  'Construction',
  'Consumer Goods and Services',
  'Education',
  'Energy and Utilities',
  'Financial Services',
  'Food and Beverage',
  'Furniture',
  'Government',
  'Hardware',
  'Healthcare and Medical',
  'Home and Garden',
  'Hospitality and Travel',
  'Manufacturing',
  'Media and Entertainment',
  'Mining',
  'Pharmaceuticals',
  'Printing and Publishing',
  'Real Estate',
  'Recreation',
  'Retail and Distribution',
  'Software and Technology',
  'Telecommunications',
  'Textiles',
  'Transportation and Logistics',
];



