import { RulesConfig } from "./models";

export enum AudienceStatus {
  deleted = -1,
  inactive = 0,
  active = 1
}

export enum AudiencesType {
  Simple = 0,
  Revenue = 1,
  DealRevenue = 2,
  ConnectedContacts = 3
}

export enum AudienceEditMode {
  Advanced = 1,
  CompaniesWizard,
}

export interface AudienceConfig {
  id?: number;
  name: string;
  description: string;
  config: RulesConfig;
  urlRule: RulesConfig;
  status?: AudienceStatus;
  editable?: 0 | 1;
  audienceType?: AudiencesType;
  editMode: AudienceEditMode;
}

export interface UsedAudience {
  id: number;
  streamIds: number[];
  campaignsIds: number[];
  orchestrationIds: number[];
  aceRaceIds: number[];
}

export interface AudienceOpportunity {
  name: string;
  amount: number;
  visitors: number;
  visits: number;
  reads: number;
  goals: number;
}

export interface AudienceCompany {
  name: string;
  clean_name: string;
  visitors: number;
  pageviews: number;
  reads: number;
  goals: number;
}

export interface AudienceSize {
  time: string;
  count: number;
  percentage: number;
  via_content_count: number;
  all_visitors_count: number;
}

export interface AudienceStats {
  members: number;
  engagedPercentage: number;
  visitsPercentage: number;
}

export interface AudienceContentReaders {
  img_source: string;
  title: string;
  audience_readers: number;
  total_readers: number;
  pure_taskurl: string;
}

export interface AudienceSource {
  type: number;
  source: string;
  audience_visits: number;
  all_visits: number;
}

export interface AudienceConversionRate {
  goal_id: number;
  goal_name: string;
  general_conversion_percent: number;
  attentive_conversion_percent: number;
}

export interface AudienceStatusRequest {
  status: AudienceStatus;
}

export interface AudiencesListStatsItem {
  id: number;
  name: string;
  audience_visits: number;
  all_visits: number;
  from_total_visits: number;
  goals: number;
  ctr: number;
  via_content_visits: number;
  via_content_visits_ctr: number;
  in_campaigns: number;
  in_orchestrations: number;
}
