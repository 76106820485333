import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { PreSavedParamsId } from "../models/pre-saved-params.models";
import { Utils } from "../classes/utils";
import { TokenInterceptorSkipAll } from "../constants/interceptors-headers";

@Injectable({
  providedIn: 'root'
})
export class PreSavedParamsService {
  constructor(private http: HttpClient) { }

  create(paramsType: string, params: string): Promise<PreSavedParamsId> {
    var body: Params = { paramsType, params };
    if (Utils.isDemo()) {
      // demo - call production admin
      const adminToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDAiLCJuYW1lIjoiRGFzaGJvYXJkIERlbW8gIiwiaWF0IjoxNjI2MjYwOTU1LCJodHRwczovL3RyZW5kZW1vbi5jb20vY2xhaW1zL2FjY291bnRzIjpbMV0sImFkbWluX3Njb3BlcyI6WyJwcmVzYXZlZHBhcmFtcyJdfQ.uDtw0q83VLW99jqO-JGzetblckume1i5hGM_u-BCRO0';
      const headers = new HttpHeaders()
        .set(TokenInterceptorSkipAll, '')
        .set('Authorization', `Bearer ${adminToken}`)
        .set('AccountId', '1');
      return lastValueFrom(
        this.http.post<PreSavedParamsId>(`https://adminapi.trendemon.com/api/PreSavedParams`, body, { headers })
      );
    } else {
      // not demo - call env admin
      return lastValueFrom(
        this.http.post<PreSavedParamsId>(`${environment.adminBaseUrl}/api/PreSavedParams`, body)
      );
    }
  }

  getQueryParameters(paramsId: PreSavedParamsId, paramsType: string): string {
    return `trd_${paramsType}_params_id=${paramsId.guid}&trd_params_token=${paramsId.token}`;
  }
}