import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoalType } from '../../models/models';
import { AnimationOptions } from 'ngx-lottie';

export class SelectedGoalType {
  goalType: GoalType;
  isCrm?: boolean | null;
}

@Component({
  selector: 'app-select-goal-type',
  templateUrl: './select-goal-type.component.html',
  styleUrls: ['./select-goal-type.component.scss']
})
export class SelectGoalTypeComponent {
  @Input() isGoal = true;
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() disabledTypes: SelectedGoalType[] = [];
  @Input() showTopArrow = false;
  @Output() goalTypeSelected = new EventEmitter<SelectedGoalType>();
  GoalType = GoalType;
  animationOptions: AnimationOptions = {
    path: '/assets/arrow.json'
  };

  onTypeSelected(goalType: GoalType, isCrm: boolean | null = null): void {
    this.goalTypeSelected.emit({ goalType, isCrm });
  }

  isDisabled(goalType: GoalType, isCrm: boolean | null = null): boolean {
    return this.disabledTypes &&
      !!(isCrm === null
        ? this.disabledTypes.find(x => x.goalType === goalType)
        : this.disabledTypes.find(x => x.goalType === goalType && x.isCrm === isCrm));
  }
}
