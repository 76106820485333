import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FunnelData, FunnelGraph } from '@trutoo/funnel-graph';

@Component({
  selector: 'app-funnel-graph',
  templateUrl: './funnel-graph.component.html',
  styleUrls: ['./funnel-graph.component.scss']

})
export class FunnelGraphComponent implements AfterViewInit, OnChanges {
  graphColors = ['#B0ABFF', '#DE6FC0', '#FF4594'];
  graphWidth = 800;
  @ViewChild('graphContainer') graphContainer!: ElementRef;

  @Input() graphValues!: number[];
  @Input() graphLabels!: string[];
  @Input() na = false;
  graph!: FunnelGraph;
  dataSet!: FunnelData;

  constructor() { }

  ngAfterViewInit(): void {
    this.graphWidth = this.graphContainer.nativeElement.offsetWidth;

    this.dataSet = {
      colors: this.graphColors,
      values: this.graphValues,
      labels: this.graphLabels
    };

    this.graph = new FunnelGraph({
      container: '.funnel',
      gradientDirection: 'horizontal',
      data: this.dataSet,
      displayPercent: true,
      direction: 'horizontal',
      width: this.graphWidth,
      height: Math.round(this.graphWidth / 3.5),
      subLabelValue: 'raw'
    });

    this.graph.draw();
    this.checkGraphDirection();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkGraphDirection();
  }

  private checkGraphDirection() {
    if (this.graphContainer.nativeElement) {
      const offsetWidth = this.graphContainer.nativeElement.offsetWidth;
      if (offsetWidth < 640) {
        this.graphWidth = this.graphContainer.nativeElement.offsetWidth - 120;
        this.graph.updateWidth(this.graphWidth);
        this.graph.updateHeight(this.graphWidth * 2);
        this.graph.makeVertical();
      } else {
        this.graphWidth = this.graphContainer.nativeElement.offsetWidth;
        this.graph.updateWidth(this.graphWidth);
        this.graph.updateHeight(Math.round(this.graphWidth / 3.5));
        this.graph.makeHorizontal();
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.graph && changes.graphValues) {
      this.dataSet.values = this.graphValues = changes.graphValues.currentValue;
      this.graph.updateData(this.dataSet);
    }
  }
}
