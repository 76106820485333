import { RulesConfig, Status } from '../../shared/models/models';
import { ImageResizeType } from '../settings/models/settings-models';
import { FormField, AbTestingType } from './experiences';

export type UnitUiTypeName = 'basic' | 'form' | 'recommend' | 'script' | 'hub' | 'survey' | 'fastext';
export type CloseButtonShape = 'square' | 'round' | 'no';
export type CloseButtonPosition = 'inside' | 'outside';

export enum UnitType {
  Campaign = 1,
  Offer,
  PreStyle,
  Autopilot
}

export enum CampaignCategory
{
    General = 1,
    Ace,
    Remarketing,
    ContentHubs
}

export interface ExperienceGeneral {
  name: string;
  unitId?: number;
  status: Status;
  createdDate: string;
}

export interface ExperienceTheme {
  theme: Theme;
  secondaryTextColor: any;
  backgroundColor?: string;
  textColor?: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  css?: string;
}

export interface ExperienceCreativeBasic extends ExperienceTheme {
  name: string;
  uiType: UnitUiTypeName;
  layoutId: number;
  libPreStyleId?: number;
  visibilityType?: UnitVisibilityType;
  screenPosition?: number;
  classes: string[];
  customHtml: string;
  height: string;
  width: string;
  // text
  buttonText: string;
  title: string;
  subTitle: string;
  successText: string;
  // links
  imgUrl?: string;
  isOpenInNewWindow: boolean;
  isUnitLink: boolean; // wheather the entire unit is clickable
  // form
  scriptCode?: string;
  formFields: FormField[];
  maProvider?: number;
  maProviderListToSendLeads?: string;
  maFormReference?: string;
  emailNotificationList?: string;
  emailNotificationEnabled: boolean;
  // recommend
  recommendationsLimit: number;
  showRecommendTags?: boolean;
  showRecommendArea?: boolean;
  recommendIsCarousel?: boolean;
  recommendCarouselDisplayLimit?: number;
  recommendCarouselShowIndicator?: boolean;
  recommendCarouselIndicatorColor?: string;
  // survey
  surveyQuestions?: SurveyQuestion[];
  videoScript?: string;
  closeButton?: CustomCloseButton;
}

export interface CustomCloseButton{
  shape: CloseButtonShape;
  color: string;
  position: CloseButtonPosition;
  background: string;
  border: string;
  size: string;
}


export interface ExperienceCreative extends ExperienceCreativeBasic {
  preStyleId?: number;
  link: string;
  contentPoolId?: number;
  recommendUrlParams?: string;
}

export interface ExperienceSchedule {
  fromDate?: string;
  toDate?: string;
}

export interface ExperienceTriggers {
  triggers: string[];
  scrollDepth?: number;
}

export interface ExperienceDevice {
  deviceFilter?: RulesConfig;
}

export interface ExperienceEmbedded {
  guid?: string;
  groupId?: number;
}

export interface ExperienceTargets extends ExperienceDevice {
  audienceFilter?: RulesConfig;
  urlFilter?: RulesConfig;
  maFilter?: RulesConfig;
  predefinedAudiences?: number[];
  segmentIds?: number[];
}

export interface ExperienceUnit extends ExperienceGeneral, ExperienceCreative, ExperienceTargets, ExperienceSchedule, ExperienceTriggers, ExperienceEmbedded {
  tags?: string[];
  exposureRate: number;
  abTestingPairId?: number;
  abTestingPairIdPrv?: number;
  abTestingEnabled: boolean;
  abTestingType: AbTestingType;
  abTestExposureRate: number;
  abTestExposureRatePrv: number;
  postCompletionScript?: string;
  displayFrequency?: DisplayFrequencyConfig;
  isCompletionAgnostic: boolean;
  // publishLead: boolean;
}

export interface ExperienceOffer extends ExperienceGeneral, ExperienceCreative, ExperienceSchedule, ExperienceTriggers, ExperienceDevice, ExperienceEmbedded {
  gatedOffer: boolean;
  offerFunnelPosition: OfferFunnelPosition;
  offerTagIds: number[];
}

export interface ExperiencePreStyle extends ExperienceGeneral, ExperienceCreativeBasic {
  thumbnailUrl: string;
}

export interface ExperienceUnitListItem extends ExperienceGeneral {
  unitType: UnitType;
  guid: string;
  order: number;
  visibilityType: UnitVisibilityType;
  triggers: string[];
  uiType: UnitUiTypeName;
}

export interface DisplayFrequencyConfig {
  displayAgain: DisplayAgain;
}

export interface DisplayAgain {
  frequency: Frequency;
}

export enum Frequency {
  EveryPage = 0,
  EverySession = 1,
  Never = 3
}

export enum OfferFunnelPosition {
  Automatic = 1,
  Top,
  Middle,
  Bottom
}

export interface ExperienceUnitImage {
  imgFile?: File;
  resizeType?: ImageResizeType;
}

export interface ExperienceUnitGroup {
  id?: number;
  name: string;
  groupType: UnitGroupType;
  selector?: string;
  pageRuleType?: UnitGroupPageRuleType;
  areaIds?: number[];
  pageTagIds?: number[];
  urls?: string[];
  placementRules?: UnitGroupPlacementRule[];
}

export interface ExperienceUnitGroupListItem extends ExperienceUnitGroup {
  usageCount: number;
}

export enum UnitGroupType {
  Manual = 1,
  AutoEmbed,
}

export enum UnitGroupPageRuleType {
  Areas = 1,
  PageTags,
  Urls,
}

export type UnitGroupRuleType = 'AfterParagraph' | 'beforeAfterSelector' | 'startEndContainer';

export interface UnitGroupPlacementRule {
  placementType: UnitGroupRuleType;
  params: string;
}

export type Theme = 'trendemon' | 'light' | 'dark' | 'rich' | 'vivid' | 'purple' | 'custom' | string;
export type UnitVisibilityType = 'light' | 'full' | 'slider' | 'fixed' | 'embedded' | 'none';

export enum UnitUiTypeId {
  Basic = 1,
  Recommend,
  Form,
  Script,
  Hub,
  Survey,
  Fastext,
  Autopilot
}

export interface ThemeSpec {
  name: Theme;
  backgroundColor: string;
  textColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  secondaryTextColor?: string;
  carouselIndicatorColor?: string;
  css?: string;
}

export interface UnitLead {
  clientUrl: string;
  countryCode: string | null;
  countryName: string | null;
  createdAt: Date;
  leadIdentity: string;
  leadId: string;
  name: string;
  leadIp: string;
  leadDetails?: UnitLeaField[];
}
export interface UnitLeaField {
  name: string;
  value: string;
}

export interface PersonalUnit {
  id: number;
  orchestrationId: number;
  name: string;
  visibilityType: UnitVisibilityType;
  typeId: PersonaUnitTypeId;
}

export enum PersonaUnitTypeId {
  Text,
  Content,
  Form,
  Script,
  Hub,
  Survey
}

export interface SurveyQuestion {
  questionId: number;
  question: string;
  answers: string[];
}


export interface SurveyQuestionForAudience {
  key: string;
  fullName: string;
  surveyName: string;
  surveyId: number;
}

export interface Orchestration {
  id: number;
  name: string;
  status: Status;
  createdDate: string;
  isAllVisitors: boolean;
  audienceIds: number[];
  audienceFilter?: RulesConfig;
  segmentIds: number[];
  maFilter?: RulesConfig;
  goalIds: number[];
  offerTagIds: number[];
  areaIds: number[];
  pageTagIds: number[];
  urlFilter?: RulesConfig;
}

export interface OrchestrationAccountConfig {
  showIntro: boolean;
  hasActiveGoals: boolean;
  hasOffers: boolean;
}
