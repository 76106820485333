<div class="filter-item">
  <mat-form-field class="date-range-form-field" subscriptSizing="dynamic">
    <mat-label></mat-label>
    <mat-date-range-input #range [rangePicker]="picker" [max]="maxDate" separator="-" (click)="picker.open()">
      <input matStartDate [value]="value.begin" readonly>
      <input matEndDate [value]="value.end" readonly>
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker (closed)="apply(range.value)"></mat-date-range-picker>
  </mat-form-field>
</div>