import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { predefinedThemes } from '../experience/experience-configs';
import { SupportedCreativeExperience } from '../experience/experiences';
import { ExperienceTheme, Theme, ThemeSpec } from '../experience/models';

export const DEFAULT_THEME: Theme = 'purple';

export const DEFAULT_CSS = `#trd_cta_{{ID}}.{{VISIBILITY}} {\n  /* CSS */\n}\n`;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themeChanged$ = new Subject<void>();
  private prvThemes: ThemeSpec[];

  constructor() {
    this.prvThemes = [...this.getSavedThemes(), ...predefinedThemes];
  }

  initTheme(unit: ExperienceTheme, layout: SupportedCreativeExperience): void {
    unit.css = layout.overrides.css || DEFAULT_CSS;
    this.changeTheme(unit, layout.defaultTheme || unit.theme || DEFAULT_THEME, layout);
    unit.textColor = layout.overrides.textColor || unit.textColor;
    unit.secondaryTextColor = layout.overrides.secondaryTextColor || unit.secondaryTextColor;
    unit.backgroundColor = layout.overrides.backgroundColor || unit.backgroundColor;
    unit.buttonTextColor = layout.overrides.buttonTextColor || unit.buttonTextColor;
    unit.buttonBackgroundColor = layout.overrides.buttonBackgroundColor || unit.buttonBackgroundColor;
    setTimeout(() => this.themeChanged$.next(), 0);
  }

  changeTheme(unit: ExperienceTheme, theme: Theme, layout: SupportedCreativeExperience): void {
    unit.theme = theme || (layout.forceConfig ? layout.defaultTheme : DEFAULT_THEME);
    const themeColors = this.getThemeFromPreStyleLayout(layout)
      || this.getThemeByName(theme)
      || this.getThemeByName((layout.forceConfig ? layout.defaultTheme : DEFAULT_THEME));

    unit.backgroundColor = themeColors.backgroundColor;
    unit.textColor = themeColors.textColor;
    unit.buttonBackgroundColor = themeColors.buttonBackgroundColor;
    unit.buttonTextColor = themeColors.buttonTextColor;
    unit.secondaryTextColor = themeColors.secondaryTextColor || themeColors.textColor;
    unit.css = themeColors.css || unit.css || '';
    setTimeout(() => this.themeChanged$.next(), 0);
  }

  getSavedThemes(): ThemeSpec[] {
    const savedThemes = localStorage.getItem('themes') || '[]';
    const themes: ThemeSpec[] = JSON.parse(savedThemes);
    return themes;
  }

  saveTheme(unit: ExperienceTheme, name: string) {
    let themes: ThemeSpec[] = this.getSavedThemes();
    themes = themes.filter(t => t.name !== name);
    const newTheme = {
      name,
      backgroundColor: unit.backgroundColor,
      textColor: unit.textColor,
      buttonBackgroundColor: unit.buttonBackgroundColor,
      buttonTextColor: unit.buttonTextColor,
      css: unit.css || ''
    };
    themes.push(newTheme);
    localStorage.setItem('themes', JSON.stringify(themes));
    this.themes.push(newTheme);
    unit.theme = name;
  }

  get themes(): ThemeSpec[] { return this.prvThemes; }

  private getThemeByName(name: Theme) {
    const ind = this.prvThemes.findIndex(t => t.name === name);
    if (ind >= 0) {
      return this.prvThemes[ind];
    }
    return null;
  }

  private getThemeFromPreStyleLayout(layout: SupportedCreativeExperience): ThemeSpec {
    return layout.preStyleId
      ? {
        name: layout.defaultTheme,
        textColor: layout.overrides.textColor,
        secondaryTextColor: layout.overrides.secondaryTextColor,
        backgroundColor: layout.overrides.backgroundColor,
        buttonBackgroundColor: layout.overrides.buttonBackgroundColor,
        buttonTextColor: layout.overrides.buttonTextColor,
        css: layout.overrides.css,
      } as ThemeSpec
      : null;
  }
}
