import { style, animate, trigger, transition } from '@angular/animations';

export const collapseExpandAnimation = trigger('collapseExpandAnimation', [
  transition(':enter', [
    style({ height: '0', overflow: 'hidden' }),
    animate('0.2s', style({ height: '*' }))
  ]),
  transition(':leave', [
    style({ overflow: 'hidden' }),
    animate('0.2s', style({ height: 0, overflow: 'hidden' }))
  ])
]);
