<form [formGroup]="nameForm">
    <div class="unit-name-box">
        <label>{{label | translate}} :</label>
        <mat-form-field [hideRequiredMarker]="true">
            <mat-label></mat-label>
            <input matInput [required]="!isDemo" formControlName="name">
            <mat-error *ngIf="nameField.hasError('inList')" translate>
                Campaign or offer with this name already exists
            </mat-error>
        </mat-form-field>
    </div>
</form>