<form #customizeForm="ngForm" class="customize">

  <!-- Fastext -->
  <ng-container *ngIf="isFastext">
    <div *ngIf="titleErrors?.maxlength" class="error-msg">
      Formatted text exceeds maximum length of {{titleErrors.maxlength.requiredLength}}
      chars
      (current length is {{titleErrors.maxlength.actualLength}} chars).
    </div>
    <!-- fastext text -->
    <app-ck-classic-editor class="rich-edit" name="Title" [(ngModel)]="unit.title" maxlength="1000">
    </app-ck-classic-editor>
    <!-- fastext button text -->
    <mat-form-field *ngIf="showField('fastextButtonText')">
      <mat-label>{{'Button Text' | translate}}</mat-label>
      <input matInput name="buttonText" [(ngModel)]="unit.buttonText">
    </mat-form-field>
    <div *ngIf="unit.buttonText" class="horizontal-form-fields" style="margin-bottom: 20px;">
      <!-- fastext button background color -->
      <app-color-picker-input [(ngModel)]="unit.buttonBackgroundColor" name="buttonBackgroundColor"
        label="{{'Buttons Background Color' | translate}}"></app-color-picker-input>
      <!-- fastext button text color -->
      <app-color-picker-input [(ngModel)]="unit.buttonTextColor" name="buttonTextColor"
        label="{{'Buttons Text Color' | translate}} "></app-color-picker-input>
    </div>
    <!-- fastext background type -->
    <mat-form-field>
      <mat-label>{{'Background' | translate}}</mat-label>
      <mat-select [value]="backgroundType" (selectionChange)="onBackgroundTypeChange($event.value)"
        class="padding-horizontal-4">
        <mat-option value="color" translate>Color</mat-option>
        <mat-option value="image" translate>Image</mat-option>
        <mat-option value="none" translate>No Background</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- fastext background color -->
    <app-color-picker-input *ngIf="backgroundType === 'color'" [(ngModel)]="unit.backgroundColor" name="backgroundColor"
      label="Background Color" style="margin-bottom: 20px"></app-color-picker-input>
    <!-- fastext background image -->
    <div *ngIf="backgroundType === 'image'">
      <div translate>Media</div>
      <app-img-uploader uploadPath="tasks_logo" [imageLink]="unit.imgUrl" [isTemplateLibImage]="isPreStyleEdit"
        (imageUploaded)="unit.imgUrl = $event; lastImageUrl = $event">
      </app-img-uploader>
    </div>
    <!-- height -->
    <div class="horizontal-form-fields">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{'Height' | translate}}</mat-label>
        <mat-select [(value)]="isHeightCustom" (selectionChange)="unit.height = $event.value ? unit.height : undefine">
          <mat-option [value]="false" translate>Auto</mat-option>
          <mat-option [value]="true" translate>Custom</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="isHeightCustom" subscriptSizing="dynamic">
        <mat-label>{{'Height' | translate}}</mat-label>
        <input matInput name="height" [(ngModel)]="unit.height" appDigitsOnly autocomplete="off" max="500" required>
        <span matTextSuffix>px</span>
        <mat-error *ngIf="customizeForm.form.get('height')?.errors?.max">
          <span translate>Max height</span> {{customizeForm.form.get('height').errors.max.value}}px
        </mat-error>
      </mat-form-field>
    </div>
    <!-- width -->
    <div class="horizontal-form-fields">
      <mat-form-field>
        <mat-label>{{'Width' | translate}}</mat-label>
        <mat-select [(value)]="isWidthCustom" (selectionChange)="unit.width = $event.value ? unit.width : undefine">
          <mat-option [value]="false" translate>Auto</mat-option>
          <mat-option [value]="true" translate>Custom</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="isWidthCustom">
        <mat-label>{{'Width' | translate}}</mat-label>
        <input matInput name="width" [(ngModel)]="unit.width" appDigitsOnly autocomplete="off" required>
        <span matTextSuffix>px</span>
      </mat-form-field>
    </div>
    <!-- fastext custom css -->
    <mat-form-field class="form-field-textarea" subscriptSizing="dynamic">
      <mat-label>{{'Custom CSS' | translate}}</mat-label>
      <textarea matInput [placeholder]="cssPattern" name="css" rows="5" [(ngModel)]="unit.css"></textarea>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!isFastext">
    <mat-form-field class="form-field-textarea" *ngIf="showField('customHtml')">
      <mat-label>{{'Custom Html' | translate}}</mat-label>
      <textarea matInput rows="5" placeholder="&lt;!-- YOUR HTML GOES HERE, NO JS ALLOWED--&gt;" name="customHtml"
        [(ngModel)]="unit.customHtml"></textarea>
    </mat-form-field>
    <mat-form-field *ngIf="showField('title')" subscriptSizing="dynamic" class="form-field-bottom-marging">
      <mat-label>{{'Title' | translate}}</mat-label>
      <input matInput name="Title" [(ngModel)]="unit.title">
    </mat-form-field>
    <mat-form-field *ngIf="showField('subTitle')" subscriptSizing="dynamic" class="form-field-bottom-marging">
      <mat-label>{{'Subtitle' | translate}}</mat-label>
      <input matInput name="Subtitle" [(ngModel)]="unit.subTitle">
    </mat-form-field>
    <mat-form-field *ngIf="showField('successText')" subscriptSizing="dynamic" class="form-field-bottom-marging">
      <mat-label>{{'Thank you Text' | translate}}</mat-label>
      <input matInput name="successText" [(ngModel)]="unit.successText" required>
    </mat-form-field>
    <mat-form-field *ngIf="showField('buttonText') && !isRecommendCarousel" subscriptSizing="dynamic"
      class="form-field-bottom-marging">
      <mat-label>Button Text</mat-label>
      <input matInput name="buttonText" [(ngModel)]="unit.buttonText" required>
    </mat-form-field>

    <div *ngIf="unit.uiType ==='form'" style="height: 15px;"></div>

    <!-- recommend config -->
    <ng-container *ngIf="unit.uiType ==='recommend' && !isRecommendCarousel">
      <!-- recommend max pages -->
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{'Max Pages to display' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.recommendationsLimit" class="padding-horizontal-4" name="recommendationsLimit">
          <mat-option *ngFor="let rec of setOfAllowedRecommendations" [value]="rec"> {{rec}} </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- recommend content pool -->
      <mat-form-field *ngIf="!isPreStyleEdit">
        <mat-label>{{'Content pool source' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.contentPoolId" class="padding-horizontal-4" name="contentPoolId">
          <mat-option [value]="-1" translate>All Content</mat-option>
          <mat-option *ngFor="let pool of contentPools" [value]="pool.id"> {{pool.name}}
          </mat-option>
        </mat-select>
        <mat-hint><a routerLink="/cm/configure/website/content_pool/add" (click)="beforeUnloadingMarker()">
            {{'create new' | translate}}</a>
        </mat-hint>
      </mat-form-field>
      <!-- recommend tags -->
      <div *ngIf="showField('recommendTags')">
        <mat-checkbox class="no-left-margin" [checked]="unit.showRecommendTags" [(ngModel)]="unit.showRecommendTags"
          name="showRecommendTags">{{'Show recommend tags' | translate}} </mat-checkbox>
      </div>
      <!-- recommend areas -->
      <div *ngIf="showField('recommendArea')">
        <mat-checkbox class="no-left-margin" [checked]="unit.showRecommendArea" [(ngModel)]="unit.showRecommendArea"
          name="showRecommendArea">{{'Show recommend area' | translate}} </mat-checkbox>
      </div>

      <div class="vertical-field-spacer"></div>
    </ng-container>

    <!-- recommend carousel config -->
    <ng-container *ngIf="unit.uiType ==='recommend' && isRecommendCarousel">
      <!-- recommend carousel max display pages -->
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{'Max Pages to display' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.recommendCarouselDisplayLimit" (ngModelChange)="onMaxDisplayPagesChange()"
          class="padding-horizontal-4" name="recommendCarouselDisplayLimit">
          <mat-option *ngFor="let rec of maxRecommendCarouselDisplayOption" [value]="rec"> {{rec}} </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- recommend carousel max pages -->
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{'Max Pages to recommend' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.recommendationsLimit" class="padding-horizontal-4" name="recommendationsLimit">
          <mat-option *ngFor="let rec of setOfAllowedRecommendations" [value]="rec"
            [disabled]="rec < unit.recommendCarouselDisplayLimit"> {{rec}} </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- recommend carousel content pool -->
      <mat-form-field *ngIf="!isPreStyleEdit">
        <mat-label>{{'Content pool source' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.contentPoolId" class="padding-horizontal-4" name="contentPoolId">
          <mat-option [value]="-1" translate>All Content</mat-option>
          <mat-option *ngFor="let pool of contentPools" [value]="pool.id"> {{pool.name}}
          </mat-option>
        </mat-select>
        <mat-hint><a routerLink="/cm/configure/website/content_pool/add" (click)="beforeUnloadingMarker()">
            {{'create new' | translate}}</a>
        </mat-hint>
      </mat-form-field>
      <!-- recommend carousel bg color -->
      <mat-checkbox class="no-left-margin" [checked]="isAdvancedBg" (change)="onAdvanceBgChange($event.checked)">
        {{'Advanced Background' | translate}}
      </mat-checkbox>
      <app-color-picker-input *ngIf="!isAdvancedBg" [(ngModel)]="unit.backgroundColor" name="backgroundColor"
        label="Background Color"> </app-color-picker-input>
      <mat-form-field *ngIf="isAdvancedBg">
        <mat-label>{{'Background' | translate}}</mat-label>
        <input matInput placeholder="url()" required [(ngModel)]="unit.backgroundColor" name="backgroundColor"
          spellcheck="false">
        <mat-hint>url() or gradient()
          <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/background" target="_blank">more info</a>
        </mat-hint>
      </mat-form-field>
      <!-- recommend carousel text color -->
      <app-color-picker-input [(ngModel)]="unit.textColor" name="textColor"
        label="{{'Text Color' | translate}}"></app-color-picker-input>
      <!-- recommend carousel indicator -->
      <div style="margin-top: 10px;">
        <mat-checkbox class="no-left-margin" [checked]="unit.recommendCarouselShowIndicator"
          [(ngModel)]="unit.recommendCarouselShowIndicator" name="recommendCarouselShowIndicator">
          {{'Show Carousel Indicators' | translate}} </mat-checkbox>
      </div>
      <app-color-picker-input *ngIf="unit.recommendCarouselShowIndicator"
        [(ngModel)]="unit.recommendCarouselIndicatorColor" name="recommendCarouselIndicatorColor"
        label="Indicator Color" @collapseExpandAnimation>
      </app-color-picker-input>
      <!-- recommend carousel button -->
      <div style="margin-top: 10px;">
        <mat-checkbox class="no-left-margin" [checked]="isShowButton" [(ngModel)]="isShowButton"
          (ngModelChange)="onShowButtonChange($event)" name="isShowButton">
          {{'Show Read More Button' | translate}}
        </mat-checkbox>
      </div>
      <div *ngIf="isShowButton" style="max-width: 400px;" @collapseExpandAnimation>
        <mat-form-field class="full-width" subscriptSizing="dynamic">
          <mat-label>Button Text</mat-label>
          <input matInput name="buttonText" [(ngModel)]="unit.buttonText" required>
        </mat-form-field>
        <app-color-picker-input [(ngModel)]="unit.buttonBackgroundColor" name="buttonBackgroundColor"
          label="Buttons Background Color"></app-color-picker-input>
        <app-color-picker-input [(ngModel)]="unit.buttonTextColor" name="buttonTextColor"
          label="Buttons Text Color"></app-color-picker-input>
      </div>
      <!-- recommend carousel tags -->
      <div>
        <mat-checkbox class="no-left-margin" [checked]="unit.showRecommendTags" [(ngModel)]="unit.showRecommendTags"
          name="showRecommendTags"> {{'Show recommend tags' | translate}} </mat-checkbox>
      </div>
      <!-- recommend carousel areas -->
      <div>
        <mat-checkbox class="no-left-margin" [checked]="unit.showRecommendArea" [(ngModel)]="unit.showRecommendArea"
          name="showRecommendArea"> {{'Show recommend area' | translate}} </mat-checkbox>
      </div>
      <!-- recommend carousel custom css -->
      <mat-form-field class="form-field-textarea" style="margin: 5px 0 10px;" subscriptSizing="dynamic">
        <mat-label>Custom CSS</mat-label>
        <textarea matInput [placeholder]="cssPattern" name="css" rows="5" [(ngModel)]="unit.css"></textarea>
      </mat-form-field>
    </ng-container>

    <!-- survey config -->
    <ng-container *ngIf="unit.uiType === 'survey'">
      <app-experience-survey-edit [questions]="unit.surveyQuestions" [isPreStyleEdit]="isPreStyleEdit"
        (save)="onSurveyChange($event)">
      </app-experience-survey-edit>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>

  <!-- theme -->
  <div [style.display]="isFastext || isPowerbarImage || isRecommendCarousel ? 'none' : 'block'">
    <app-unit-theme [unit]="unit" [selectedLayout]="selectedLayout"></app-unit-theme>
  </div>

  <div *ngIf="isPowerbarImage" style="margin-bottom: 15px;">
    Click
    <a href="https://support.trendemon.com/powerbar-as-an-image" target="_blank">here</a>
    for guidelines and best practices.
  </div>

  <!-- media -->
  <div *ngIf="showField('media') && (unit.visibilityType!=='fixed' || isPowerbarImage)">
    <div>Media</div>
    <app-img-uploader uploadPath="tasks_logo" [imageLink]="unit.imgUrl" [isTemplateLibImage]="isPreStyleEdit"
      (imageUploaded)="unit.imgUrl = $event">
    </app-img-uploader>
  </div>

  <!-- Powerbar image -->
  <ng-container *ngIf="isPowerbarImage">
    <!-- background color -->
    <app-color-picker-input [(ngModel)]="unit.backgroundColor" name="backgroundColor" label="Background Color"
      style="margin-bottom: 15px"></app-color-picker-input>
    <!-- custom css -->
    <mat-form-field class="form-field-textarea">
      <mat-label>{{'Custom CSS' | translate}}</mat-label>
      <textarea matInput name="css" rows="5" [(ngModel)]="unit.css"></textarea>
    </mat-form-field>
  </ng-container>

  <!-- media video -->
  <ng-container *ngIf="showField('script')">
    <div class="form-field-textarea">
      <mat-form-field class="form-field-textarea">
        <mat-label>{{'Video code' | translate}}</mat-label>
        <textarea matInput placeholder="Video Code Snippet" name="Video" [(ngModel)]="unit.videoScript" rows="5"
          required appSupportedVideo></textarea>
      </mat-form-field>
    </div>
    <mat-error *ngIf="videoScript?.hasError('supportedScripts')" translate>
      For now we don't support video platform that you are trying to use
    </mat-error>
    <mat-error *ngIf="videoScript?.hasError('inlineScripts')" translate>
      The inline scripts are not allowed
    </mat-error>
    <p translate>*recommended video size: 260 X 146px</p>
  </ng-container>

  <ng-container *ngIf="unit.visibilityType !== 'embedded'">

    <!-- customize close button -->
    <mat-checkbox class="no-left-margin" [checked]="isCustomCloseBtn && !!unit.closeButton"
      (change)="onCustomCloseBtnChange($event.checked)">
      {{'Customize Close Button' | translate}}
    </mat-checkbox>
    <ng-container *ngIf="isCustomCloseBtn && !!unit.closeButton">
      <mat-form-field subscriptSizing="dynamic" class="form-field-bottom-marging">
        <mat-label>{{'Shape' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.closeButton.shape" class="padding-horizontal-4" name="closeButtonShape">
          <mat-option value="square" translate>Square</mat-option>
          <mat-option value="round" translate>Round</mat-option>
          <mat-option value="no" translate>Blank</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="form-field-bottom-marging"
        *ngIf="unit.visibilityType!=='fixed' && unit.visibilityType!=='full' && unit.closeButton.shape !== 'no'">
        <mat-label>{{'Position' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.closeButton.position" class="padding-horizontal-4" name="closeButtonPosition">
          <mat-option [value]="'inside'" translate>Within Unit</mat-option>
          <mat-option [value]="'outside'" translate>On Corner</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="form-field-bottom-marging">
        <mat-label>{{'Size (px)' | translate}}</mat-label>
        <mat-select [(ngModel)]="unit.closeButton.size" class="padding-horizontal-4" name="closeButtonSize">
          <mat-option value="17" translate>17 px</mat-option>
          <mat-option value="18" translate>18 px</mat-option>
          <mat-option value="19" translate>19 px</mat-option>
          <mat-option value="20" translate>20 px</mat-option>
          <mat-option value="21" translate>21 px</mat-option>
          <mat-option value="22" translate>22 px</mat-option>
          <mat-option value="23" translate>23 px</mat-option>
          <mat-option value="24" translate>24 px</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="isCustomCloseBtn" class="colors-container">
        <app-color-picker-input [(ngModel)]="unit.closeButton.color" label="X color" name="closeButton_color"
          style="margin-bottom: 15px"></app-color-picker-input>
        <ng-container *ngIf="unit.closeButton.shape !== 'no'">
          <app-color-picker-input [(ngModel)]="unit.closeButton.background" label="Background Color"
            name="closeButton_background" position="top-left" style="margin-bottom: 15px"></app-color-picker-input>
          <app-color-picker-input [(ngModel)]="unit.closeButton.border" label="Border Color" name="closeButton_border"
            position="top-left" style="margin-bottom: 15px"></app-color-picker-input>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <!-- marketing automation -->
  <ng-container *ngIf="showField('fields')">
    <mat-divider></mat-divider>
    <div>
      <div *ngIf="isAnyExportLeadsIntegration">
        {{'Publish Lead to:' | translate }}
        <mat-form-field class="padding-horizontal-4">
          <mat-label translate>Choose Marketing Automation</mat-label>
          <mat-select matNativeControl [(ngModel)]="unit.maProvider" name="maProvider"
            (selectionChange)="loadIntegrationsFieldsLists($event.value)">
            <mat-option translate>Without export</mat-option>
            <mat-option *ngFor="let integration of exportLeadsIntegrations" [value]="integration.integrationId">
              {{IntegrationType[integration.integrationId]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <div>Form Fields</div>
        <app-experience-form-fields [formFields]="unit.formFields" [maFields]="exportLeadsMaFields"
          [integrationId]="unit.maProvider" [formReset]="formReset" (saved)="onFieldsChange($event)">
        </app-experience-form-fields>
      </div>
    </div>

    <div *ngIf="unit.maProvider">
      <div *ngIf="unit.maProvider!==IntegrationType.salesforce && exportLeadsMaLists &&
              exportLeadsMaLists.length>0">
        <mat-form-field>
          <mat-label translate>{{IntegrationType[unit.maProvider]}} Static List Id
            (not required)
          </mat-label>
          <mat-select [(ngModel)]="unit.maProviderListToSendLeads" class="padding-horizontal-4"
            name="maProviderListToSendLeads">
            <mat-option translate>Nothing Selected</mat-option>
            <mat-option *ngFor="let list of exportLeadsMaLists" [value]="list.id" translate>
              {{list.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div *ngIf="unit.maProvider===IntegrationType.hubspot">
        <mat-form-field appearance="fill">
          <mat-label>{{'HubSpot Form GUID (not required):' | translate}}</mat-label>
          <input matInput placeholder="Form GUID" [(ngModel)]="unit.maFormReference" name="maFormReference">
        </mat-form-field>
        <div class="small-text" translate>If you want lead to be sent to HubSpot with it's website activity history,
          enter here HubSpot Form GUID.
          The requirement to this form - it should have the same fields as the current form.
        </div>
      </div>
    </div>
  </ng-container>
  <!-- leads notification -->
  <div class="margin-vertical-10" *ngIf="showField('leadsNotification') && !isPreStyleEdit">
    <mat-checkbox name="emailNotificationEnabled" [(ngModel)]="unit.emailNotificationEnabled" translate>
      Send Leads Notification
    </mat-checkbox>
    <div *ngIf="unit.emailNotificationEnabled">
      <mat-form-field class="full-width">
        <mat-label>my&#64;email.com OR my&#64;email.com,colleague&#64;email.com</mat-label>
        <input matInput name="emailNotificationList" [(ngModel)]="unit.emailNotificationList" required
          pattern="^((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*([,])*)*$" />
      </mat-form-field>
    </div>
  </div>


</form>