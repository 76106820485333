import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { EmailValidationRegEx, UrlRegEx } from '../constants/validation-regex';

export const urlOrMailtoValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }
    const value = control.value as string;
    const isValid = value.toLowerCase().startsWith('mailto:') ? EmailValidationRegEx.test(value.substring(7)) : UrlRegEx.test(value);
    return isValid ? null : { urlOrMailto: true };
};

@Directive({
    selector: '[appUrlOrMailto]',
    providers: [{ provide: NG_VALIDATORS, useExisting: UrlOrMailtoValidatorDirective, multi: true }]
})
export class UrlOrMailtoValidatorDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return urlOrMailtoValidator(control);
    }
}
