import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as config } from '../../../../environments/environment';
import { ContentPoolListItem, ContentPool, ContentPoolPage, ContentPoolAddResponse } from '../models/content-pool';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RulesConfig } from '../../shared/models/models';

@Injectable({
    providedIn: 'root'
})
export class ContentPoolService {
    constructor(private http: HttpClient) { }

    getList(): Observable<ContentPoolListItem[]> {
        return this.http.get<any[]>(`${config.adminBaseUrl}/api/contentPool`).pipe(
            map((apiPools: any[]) =>
                apiPools.map(item => {
                    const contentConfig: RulesConfig = JSON.parse(item.contentConfig);
                    return {
                        ...item,
                        contentConfig,
                        areaIds: this.getRuleAreaIds(contentConfig).map(x => Number(x)),
                        tagIds: this.getRulePageTagIds(contentConfig).map(x => Number(x)),
                        contentOperator: contentConfig?.rulesGroups[0]?.rulesOperator
                    };
                })
            )
        );
    }

    getById(contentPoolId: number): Observable<ContentPool> {
        return this.http.get<any>(`${config.adminBaseUrl}/api/contentPool/${contentPoolId}`).pipe(
            map(item => ({
                ...item,
                contentConfig: JSON.parse(item.contentConfig)
            }))
        );
    }

    getAreaContentCount(areaId: number): Observable<number> {
        return this.http.get<{ contentCount: number }>(`${config.adminBaseUrl}/api/contentPool/area/${areaId}/count`)
            .pipe(
                map((value: { contentCount: number }) => value.contentCount)
            );
    }

    getTagContentCount(tagId: number): Observable<number> {
        return this.http.get<{ contentCount: number }>(`${config.adminBaseUrl}/api/contentPool/tag/${tagId}/count`)
            .pipe(
                map((value: { contentCount: number }) => value.contentCount)
            );
    }

    getAreaContent(contentPoolId: number, areaId: number): Observable<ContentPoolPage[]> {
        return this.http.get<ContentPoolPage[]>(`${config.adminBaseUrl}/api/contentPool/${contentPoolId}/area/${areaId}/content`);
    }

    getTagContent(contentPoolId: number, tagId: number): Observable<ContentPoolPage[]> {
        return this.http.get<ContentPoolPage[]>(`${config.adminBaseUrl}/api/contentPool/${contentPoolId}/tag/${tagId}/content`);
    }

    update(contentPool: ContentPool): Observable<void> {
        const post = {
            ...contentPool,
            contentConfig: JSON.stringify(contentPool.contentConfig)
        };
        return this.http.post<void>(`${config.adminBaseUrl}/api/contentPool/${contentPool.id}`, post);
    }

    add(contentPool: ContentPool): Observable<ContentPoolAddResponse> {
        const post = {
            ...contentPool,
            contentConfig: JSON.stringify(contentPool.contentConfig)
        };
        return this.http.post<ContentPoolAddResponse>(`${config.adminBaseUrl}/api/contentPool`, post);
    }

    delete(contentPoolId: number): Observable<void> {
        return this.http.delete<void>(`${config.adminBaseUrl}/api/contentPool/${contentPoolId}`);
    }

    getRuleAreaIds(rule: RulesConfig): string[] {
        return rule?.rulesGroups[0]?.rules[0]?.comparisonValue?.split('|').filter(id => !!id) || [];
    }

    getRulePageTagIds(rule: RulesConfig): string[] {
        return rule?.rulesGroups[0]?.rules[1]?.comparisonValue?.split('|').filter(id => !!id) || [];
    }
}

