import { AfterViewInit, Component, Input, OnInit, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NgControl, ValidationErrors, Validator, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Utils } from 'src/app/modules/shared/classes/utils';
import { notInListValidator } from 'src/app/modules/shared/validators/not-in-list-validator';
import { ExperienceService } from '../../services/experience.service';
import { PreStyleService } from '../../services/pre-style.service';

@Component({
  selector: 'app-unit-name',
  templateUrl: './unit-name.component.html',
  styleUrls: ['./unit-name.component.scss']
})
export class UnitNameComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
  @Input() label = '';
  @Input() excludeName = '';
  @Input() isPreStyleEdit = false;

  nameForm = new UntypedFormGroup({
    name: new UntypedFormControl('')
  });
  onValueChange = (_: string) => { };

  constructor(
    @Self() private ngControl: NgControl,
    private experienceService: ExperienceService,
    private preStyleService: PreStyleService,
  ) {
    this.ngControl.valueAccessor = this;
    this.ngControl.control.setValidators(this.validate.bind(this));
  }

  async ngOnInit(): Promise<void> {
    let unitNames: string[] = [];
    if (this.isPreStyleEdit) {
      const units = await lastValueFrom(this.preStyleService.getLibListMinData());
      unitNames = units.items.map(x => x.name);
    } else {
      const units = await lastValueFrom(this.experienceService.getAllUnits({}));
      unitNames = units.items.map(x => x.name);
    }
    if (this.excludeName) {
      const nameIndex = unitNames.indexOf(this.excludeName);
      unitNames.splice(nameIndex, 1);
    }

    this.nameField.setValidators([Validators.required, notInListValidator(unitNames)]);
    this.nameField.valueChanges.subscribe(name => this.onValueChange(name));
  }

  ngAfterViewInit(): void {
    // notify the inner nameField when the control markAllAsTouched is called
    const origFunc = this.ngControl.control.markAllAsTouched;
    this.ngControl.control.markAllAsTouched = () => {
      origFunc.apply(this.ngControl.control, arguments);
      this.nameField.markAllAsTouched();
    };
  }

  // ControlValueAccessor interface - start
  writeValue(name: string): void {
    this.nameField.setValue(name);
  }
  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }
  registerOnTouched(fn: any): void {
    // console.log('registerOnTouched');
  }
  // ControlValueAccessor interface - end

  validate(control: AbstractControl): ValidationErrors {
    return this.nameField?.errors;
  }

  onNameChange(name: string): void {
    this.onValueChange(name);
  }

  get nameField(): AbstractControl { return this.nameForm?.get('name'); }
  get isDemo(): boolean { return Utils.isDemo(); }
}
