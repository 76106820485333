import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { AccountContextService } from '../modules/shared/services/account-context.service';
import { TokenInterceptorSkipAccountHeader, TokenInterceptorSkipAll } from '../modules/shared/constants/interceptors-headers';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, public selectionService: AccountContextService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(TokenInterceptorSkipAll)) {
      return next.handle(request);
    }

    let headers = request.headers.set('Authorization', `Bearer ${this.authService.getAccessToken()}`);
    let authRequest = null;

    if (this.selectionService.accountId && !request.headers.has(TokenInterceptorSkipAccountHeader)) {
      headers = headers.set('AccountId', this.selectionService.accountId.toString());
      const params = request.params.has('account_id')
        ? request.params
        : request.params.append('account_id', this.selectionService.accountId.toString());
      authRequest = request.clone({ params, headers });
    } else {
      authRequest = request.clone({ headers });
    }

    return next.handle(authRequest);
  }
}
