import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { ValidVideoSources } from '../constants/validation-regex';

export const videoValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  let hasInlineScripts = false;
  const dummy = document.createElement('dummy');
  dummy.innerHTML = control.value;
  const inlineScripts = Array.from(dummy.querySelectorAll('script')).filter(s => !s.src);
  if (inlineScripts.length > 0) {
    hasInlineScripts = true;
  }

  let hasNotSupportedScripts = false;
  const scriptsWithSrc = Array.from(dummy.querySelectorAll('script')).filter(s => s.src);
  scriptsWithSrc.forEach(script => {
    if (!ValidVideoSources.some(allowedSource => script.src.startsWith(allowedSource))) {
      hasNotSupportedScripts = true;
    }
  });
  return hasNotSupportedScripts || hasInlineScripts ? { supportedScripts: hasNotSupportedScripts, inlineScripts: hasInlineScripts } : null
};

@Directive({
  selector: '[appSupportedVideo]',
  providers: [{ provide: NG_VALIDATORS, useExisting: VideoValidatorDirective, multi: true }]
})
export class VideoValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return videoValidator(control);
  }
}
