import { UnitUiTypeName, UnitVisibilityType, SurveyQuestion, ExperienceCreative } from './models';
import { environment } from 'src/environments/environment';

export enum ExperienceUnitTrigger {
  PageLoad,
  PageRead,
  ExitIntent,
  TimerShort,
  Scroll,
  FirstInteraction,
  TimerLong
}

export type SupportedCreativeExperience = SupportedExperience<ExperienceCreative>;

export const SupportedExperiences: SupportedCreativeExperience[] = [
  {
    layoutId: 110,
    type: 'fastext',
    classes: [],
    description: 'Banner 1',
    demoImage: 'assets/experiences/fastext-pre-style-1.png',
    showFields: ['titleEditor', 'fastextButtonText', 'buttonText'],
    showInVisibiltyTypes: ['embedded', 'light'],
    overrides: {
      title: '<p><span style="color:hsl(0, 0%, 100%);font-size:18px;">Watch our webinar:</span></p><p><span style="color:hsl(0, 0%, 100%);font-size:24px;"><strong>New Strategies To Drive WFH Success</strong></span></p>',
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/fastext-pre-style-1.jpg`,
      customHtml: '',
      backgroundColor: '',
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#e6994c'
    },
    purpose: 'promote',
    name: 'Banner 1',
    forceConfig: true,
  },
  {
    layoutId: 111,
    type: 'fastext',
    classes: [],
    description: 'Banner 2',
    demoImage: 'assets/experiences/fastext-pre-style-3.png',
    showFields: ['titleEditor', 'fastextButtonText', 'buttonText'],
    showInVisibiltyTypes: ['embedded', 'light'],
    overrides: {
      title: '<p style="text-align:center;"><span style="color:hsl(0,0%,100%);font-size:24px;"><strong>Get Your Free Security Assessment</strong></span></p>',
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/fastext-pre-style-3.jpg`,
      customHtml: '',
      backgroundColor: '',
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#e6994c'
    },
    purpose: 'promote',
    name: 'Banner 2',
    forceConfig: true,
  },
  {
    layoutId: 112,
    type: 'fastext',
    classes: [],
    description: 'Banner 3',
    demoImage: 'assets/experiences/fastext-pre-style-2.png',
    showFields: ['titleEditor', 'fastextButtonText', 'buttonText'],
    showInVisibiltyTypes: ['embedded', 'light', 'slider'],
    overrides: {
      title: '<p><span style="color:hsl(0,0%,100%);font-size:18px;">Download eBook:</span></p><p><span style="color:hsl(0,0%,100%);font-size:24px;"><strong>Defending Assessment Security in a Digital World</strong></span></p>',
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/fastext-pre-style-2.jpg`,
      customHtml: '',
      backgroundColor: '',
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#e6994c',
      width: '200',
      css: /*css*/`#trd_cta_{{ID}}.{{VISIBILITY}} p {
        line-height: 1.4;
        margin: 8px 0;
        }`
    },
    purpose: 'promote',
    name: 'Banner 3',
    forceConfig: true,
  },
  {
    layoutId: 113,
    type: 'fastext',
    classes: [],
    description: 'Banner 4',
    demoImage: 'assets/experiences/fastext-pre-style-4.png',
    showFields: ['titleEditor', 'fastextButtonText', 'buttonText'],
    showInVisibiltyTypes: ['embedded', 'light', 'slider'],
    overrides: {
      title: `<p style="text-align:center;"><span style="color:hsl(0,0%,100%);font-size:24px;">Get Whitepaper</span></p>
        <p style="text-align:center;"><span style="color:hsl(0,0%,100%);font-size:30px;"><strong>[Playbook]</strong></span></p>
        <p style="text-align:center;"><span style="color:hsl(0,0%,100%);font-size:30px;"><strong>
          How to Orchestrate Buyer Journeys Through Your Content</strong></span></p>`,
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/fastext-pre-style-4.jpg`,
      customHtml: '',
      backgroundColor: '',
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#e6994c',
      width: '230',
      css: /*css*/`#trd_cta_{{ID}}.{{VISIBILITY}} p {
        line-height: 1.8;
      }`
    },
    purpose: 'promote',
    name: 'Banner 4',
    forceConfig: true,
  },
  {
    layoutId: 114,
    type: 'fastext',
    classes: [],
    description: 'Banner 5',
    demoImage: 'assets/experiences/fastext-pre-style-5.png',
    showFields: ['titleEditor', 'fastextButtonText', 'buttonText'],
    showInVisibiltyTypes: ['embedded', 'light', 'slider'],
    overrides: {
      title: `<p style="text-align:center;"><span style="color:hsl(0, 0%, 100%);font-size:24px;">Get Whitepaper</span></p>
              <p style="text-align:center;"><span style="color:hsl(0, 0%, 100%);font-size:30px;"><strong>
                [Playbook] How to Orchestrate Buyer Journeys Through Your Content</strong></span></p>`,
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/fastext-pre-style-5.jpg`,
      customHtml: '',
      backgroundColor: '',
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#e6994c',
      width: '500',
      css: /*css*/`#trd_cta_{{ID}}.{{VISIBILITY}} p {
        line-height: 1.8;
      }`
    },
    purpose: 'promote',
    name: 'Banner 5',
    forceConfig: true,
  },
  {
    layoutId: 105,
    type: 'fastext',
    classes: [],
    description: 'Fastext Banner',
    demoImage: 'assets/experiences/fastext.png',
    showFields: ['titleEditor', 'fastextButtonText', 'buttonText'],
    showInVisibiltyTypes: ['embedded', 'light', 'slider'],
    overrides: {
      title: '<p style="text-align:center;"><span style="color:hsl(0, 0%, 0%);font-size:30px;"><strong>Your text goes here</strong></span></p>',
      subTitle: '',
      buttonText: '',
      imgUrl: '',
      customHtml: '',
      backgroundColor: '#e8e6fe',
      buttonTextColor: '#e8e6fe',
      buttonBackgroundColor: '#7348fe',
    },
    purpose: 'promote',
    name: 'Fastext'
  },
  {
    layoutId: 103,
    type: 'basic',
    classes: ['single-img', 'trd-close-btn'],
    description: 'Promote your Product - right content',
    demoImage: 'assets/experiences/image_banner_thumb.png',
    showFields: ['link', 'media'],
    showInVisibiltyTypes: ['embedded', 'full', 'light', 'slider'],
    overrides: {
      title: '',
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/image-banner.png`,
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Image'
  },
  {
    layoutId: 101,
    type: 'basic',
    classes: ['padded', 'single-vertical', 'trd-close-btn'],
    description: 'Promote your Product',
    demoImage: 'assets/experiences/basic-img.png',
    showFields: ['title', 'subTitle', 'buttonText', 'link', 'media'],
    overrides: {
      title: 'Headline text',
      subTitle: 'Sub header',
      buttonText: 'Click Here',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Product promotion'
  },
  {
    layoutId: 102,
    type: 'basic',
    classes: ['right-content', 'trd-close-btn'],
    description: 'Promote your Product - right content',
    showInVisibiltyTypes: ['embedded', 'full', 'light', 'slider'],
    demoImage: 'assets/experiences/basic-right.png',
    showFields: ['title', 'subTitle', 'buttonText', 'link', 'media'],
    overrides: {
      title: 'Headline text',
      subTitle: 'Sub header',
      buttonText: 'Click Here',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x400.png`,
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Product promotion'
  },
  {
    layoutId: 133,
    type: 'basic',
    classes: ['right-content', 'rounded-light', 'rounded', 'trd-close-btn', 'right-img'],
    description: 'Promote your Product - right content',
    showInVisibiltyTypes: ['light'],
    demoImage: 'assets/experiences/basic-right-img.png',
    showFields: ['title', 'subTitle', 'buttonText', 'link', 'media'],
    overrides: {
      title: 'Headline text',
      subTitle: 'Sub header',
      buttonText: 'Click Here',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Product promotion'
  },
  {
    layoutId: 134,
    type: 'basic',
    classes: ['single-img', 'trd-close-btn', 'fixed-image'],
    description: 'PowerBar as an image',
    demoImage: 'assets/experiences/image_banner_thumb.png',
    showFields: ['link', 'media'],
    showInVisibiltyTypes: ['fixed'],
    overrides: {
      title: '',
      subTitle: '',
      buttonText: '',
      imgUrl: `${environment.mediaUrl}/units-graphics/image-banner.png`,
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Powerbar Image'
  },
  {
    layoutId: 130,
    type: 'basic',
    positions: [0, 2, 3, 5, 6, 8],
    classes: ['arrow-button', 'close-btn-grey'],
    description: 'Promote your Product - Classic Slider',
    demoImage: 'assets/experiences/slider-side-arrow.png',
    showFields: ['title', 'subTitle', 'link', 'media'],
    showInVisibiltyTypes: ['slider'],
    overrides: {
      title: 'Headline text',
      subTitle: 'Sub header',
      buttonText: '<svg height="24" width="12"> <polyline points="0,0 11,12 0,24" stroke-width="2" stroke="#FFFFFF" fill="none"></polyline></svg>',
      imgUrl: `${environment.mediaUrl}/units-graphics/165x105.jpg`,
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Side Arrow slider'
  },
  {
    layoutId: 135,
    type: 'basic',
    classes: ['padded', 'single-vertical', 'trd-close-btn', 'trd-video'],
    description: 'Promote your Product with Video',
    demoImage: 'assets/experiences/basic-video.png',
    showFields: ['title', 'subTitle', 'buttonText', 'link', 'script'],
    showInVisibiltyTypes: ['slider','light', 'embedded'],
    overrides: {
      title: 'Headline text',
      subTitle: 'Sub header',
      buttonText: 'Click Here',
      imgUrl: '',
      customHtml: ''
    },
    purpose: 'promote',
    name: 'Product promotion',
  },
  {
    layoutId: 200,
    type: 'recommend',
    classes: ['padded', 'trd-close-btn'],
    description: 'Engage audience with content',
    demoImage: 'assets/experiences/content.png',
    purpose: 'engagment',
    showFields: ['title', 'subTitle', 'content', 'recommendTags', 'recommendArea'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 3,
      contentPoolId: -1,
    },
    name: 'Recommend'
  },
  {
    layoutId: 201,
    type: 'recommend',
    classes: ['large', 'padded', 'trd-close-btn'],
    description: 'Engage audience with content, large recommend',
    demoImage: 'assets/experiences/recommend-xl.png',
    purpose: 'engagment',
    showFields: ['title', 'subTitle', 'content', 'recommendTags', 'recommendArea'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 2,
      contentPoolId: -1
    },
    name: 'Recommend large icons'
  },
  {
    layoutId: 202,
    type: 'recommend',
    classes: ['horizontal', 'padded', 'trd-close-btn'],
    description: 'Engage audience with content, horizontal recommend',
    demoImage: 'assets/experiences/recommend-horizontal-item.png',
    purpose: 'engagment',
    showFields: ['title', 'subTitle', 'content'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 3,
      contentPoolId: -1,
      showRecommendTags: false,
      showRecommendArea: false,
    },
    name: 'Recommend horizontal item'
  },
  {
    layoutId: 204,
    type: 'recommend',
    classes: ['horizontal', 'vertical-items', 'padded', 'no-pad-items', 'trd-close-btn'],
    description: 'Engage audience with content, horizontal items',
    demoImage: 'assets/experiences/recommend-horizontal-items.png',
    purpose: 'engagment',
    showFields: ['title', 'subTitle', 'content', 'recommendTags', 'recommendArea'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 3,
      contentPoolId: -1,
    },
    name: 'Recommend protrait mode'
  },
  {
    layoutId: 205,
    type: 'recommend',
    classes: ['large', 'padded', 'text-over', 'trd-close-btn'],
    description: 'Engage audience with content, large recommend, text on image',
    demoImage: 'assets/experiences/recommend-xl-to.png',
    purpose: 'engagment',
    showFields: ['title', 'subTitle', 'content', 'recommendTags', 'recommendArea'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 2,
      contentPoolId: -1
    },
    name: 'Recommend large icons with text overlay'
  },
  {
    layoutId: 206,
    type: 'recommend',
    classes: ['large', 'padded', 'read-more', 'trd-close-btn'],
    description: 'Engage audience with content, large recommend, read more text',
    demoImage: 'assets/experiences/recommend-xl-rm.png',
    purpose: 'engagment',
    showFields: ['title', 'subTitle', 'content', 'buttonText', 'recommendTags', 'recommendArea'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 2,
      buttonText: 'Read more',
      contentPoolId: -1
    },
    name: 'Recommend large icons with read more'
  },
  {
    layoutId: 207,
    type: 'recommend',
    classes: ['horizontal', 'arrow-button', 'close-btn-grey'],
    positions: [6, 8],
    description: 'Engage audience with content - Swider',
    demoImage: 'assets/experiences/recommend-xl-sw.png',
    showInVisibiltyTypes: ['slider'],
    purpose: 'engagment',
    showFields: ['title', 'content'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 1,
      contentPoolId: -1,
      buttonText: '<svg height="24" width="12"> <polyline points="0,0 11,12 0,24" stroke-width="2" stroke="#FFFFFF" fill="none"></polyline></svg>',
      showRecommendTags: false,
      showRecommendArea: false,
    },
    name: 'Recommend horizontal item in slider'
  },
  {
    layoutId: 208,
    type: 'recommend',
    classes: ['large', 'padded', 'read-more', 'trd-close-btn'],
    description: 'Engage audience with content carousel',
    demoImage: 'assets/experiences/recommend-xl-carousel.png',
    purpose: 'engagment',
    showFields: ['title', 'buttonText', 'recommendTags', 'recommendArea'],
    showInVisibiltyTypes: ['embedded'],
    overrides: {
      title: 'Recommended for you',
      recommendationsLimit: 5,
      buttonText: 'Read more',
      contentPoolId: -1,
      recommendIsCarousel: true,
      recommendCarouselDisplayLimit: 3,
      recommendCarouselShowIndicator: true,
      recommendCarouselIndicatorColor: '#0fbfbf',
    },
    name: 'Recommend carousel'
  },
  {
    layoutId: 300,
    disabled: true,
    type: 'form',
    description: 'Collect leads emails',
    demoImage: 'assets/experiences/email-vertical.png',
    purpose: 'leads',
    name: 'Email Collect',
    overrides: {
      title: 'Sign up to our Webinar',
      subTitle: 'A free webinar of your topic of choice.',
      successText: 'Thanks for signing up!',
      buttonText: 'SUBMIT',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      scriptCode: ''
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'Your email, please',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      },
      {
        label: 'I Agree to our <a target="_blank" href="https://terms.mydomain.com">Privacy Policy</a>',
        name: 'policy',
        validation: 'This field is required',
        validationType: 'privacy-policy',
        required: true
      }
    ]
  },
  {
    layoutId: 307,
    type: 'form',
    classes: ['right-content', 'trd-close-btn', 'trd-field-outlined', 'trd-form-predefined-subscribe'],
    showInVisibiltyTypes: ['embedded', 'full', 'light', 'slider'],
    description: 'Prestyled Form - Subscribe',
    demoImage: 'assets/experiences/subscribe_form_image_left.jpg',
    purpose: 'leads',
    name: 'Form Submission Collect',
    overrides: {
      buttonText: 'SUBSCRIBE',
      title: 'Stay in touch',
      subTitle: 'We share only quality content, tips and insights about once a month. No spam, promise :)',
      successText: 'Thanks for signing up!',
      imgUrl: `${environment.mediaUrl}/units-graphics/subscribe.jpg`,
      scriptCode: '',
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'First Name*',
        name: 'name',
        validation: 'This field is required',
        validationType: 'name',
        required: true
      },
      {
        label: 'Your Email*',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      }
    ],
    defaultTheme: 'Prestyled Subscribe',
    forceConfig: true,
  },
  {
    layoutId: 308,
    type: 'form',
    classes: ['trd-close-btn', 'rounded', 'trd-field-outlined', 'trd-form-predefined-download', 'right-content', 'trd-right-img', 'trd-rounded'],
    showInVisibiltyTypes: ['embedded', 'full', 'light', 'slider'],
    description: 'Download Gate Image Right',
    demoImage: 'assets/experiences/download_gate_image_right.jpg',
    purpose: 'leads',
    name: 'Prestyled Form - Download',
    overrides: {
      buttonText: 'Download',
      title: 'Get our latest ebook',
      subTitle: 'How to orchestrate your buyers journeys',
      successText: 'Thanks for signing up!',
      imgUrl: `${environment.mediaUrl}/units-graphics/form_download.jpg`,
      scriptCode: '',
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'Your name',
        name: 'name',
        validation: 'This field is required',
        validationType: 'name',
        required: true
      },
      {
        label: 'Your email',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      }
    ],
    defaultTheme: 'Prestyled Download',
    forceConfig: true,
  },
  {
    layoutId: 302,
    type: 'form',
    classes: ['right-content', 'rounded', 'trd-close-btn'],
    showInVisibiltyTypes: ['embedded', 'full', 'light', 'slider'],
    description: 'Request Demo Right Content',
    demoImage: 'assets/experiences/form-right.png',
    purpose: 'leads',
    name: 'Form Submission Collect',
    overrides: {
      buttonText: 'Request A Demo',
      title: 'Sign up to our Webinar',
      subTitle: 'A free webinar of your topic of choice.',
      successText: 'Thanks for signing up!',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x400.png`,
      scriptCode: ''
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'Your name',
        name: 'name',
        validation: 'This field is required',
        validationType: 'name',
        required: true
      },
      {
        label: 'Enter your e-mail',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      },
      {
        label: 'privacy policy text and checkbox',
        name: 'policy',
        validation: 'This field is required',
        validationType: 'privacy-policy',
        required: true
      }
    ]
  },
  {
    layoutId: 304,
    type: 'form',
    description: 'Collect leads emails Single Vertical',
    demoImage: 'assets/experiences/email-singleform-vertical.png',
    demoImageFixed: 'assets/experiences/power_bar_round.png',
    purpose: 'leads',
    name: 'Email Collect Vertical Button',
    classes: ['single-vertical', 'rounded', 'trd-close-btn', 'padded'],
    overrides: {
      title: 'Sign up to our Webinar',
      subTitle: 'A free webinar of your topic of choice.',
      successText: 'Thanks for signing up!',
      buttonText: 'SUBMIT',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      scriptCode: ''
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'Your email, please',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      },
      {
        label: 'I Agree to our <a target="_blank" href="https://terms.mydomain.com">Privacy Policy</a>',
        name: 'policy',
        validation: 'This field is required',
        validationType: 'privacy-policy',
        required: true
      }
    ]
  },
  {
    layoutId: 301,
    type: 'form',
    classes: ['padded', 'trd-close-btn', 'single-vertical'],
    description: 'Request Demo',
    demoImage: 'assets/experiences/form-vertical.png',
    demoImageFixed: 'assets/experiences/powerbar_square.png',
    purpose: 'leads',
    name: 'Form Submission Collect',
    overrides: {
      buttonText: 'Request A Demo',
      title: 'Leave Your Details',
      subTitle: 'Fill your details to get his offer',
      successText: 'Thanks! We will sure contact you soon',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      scriptCode: ''
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'Your email, please',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      },
      {
        label: 'Tell us your name',
        name: 'name',
        validation: 'This field is required',
        validationType: 'name',
        required: true
      },
      {
        label: 'I Agree to our <a target="_blank" href="https://terms.mydomain.com">Privacy Policy</a>',
        name: 'policy',
        validation: 'This field is required',
        validationType: 'privacy-policy',
        required: true
      }
    ]
  },
  {
    layoutId: 306,
    type: 'form',
    description: 'Collect leads emails Single Vertical',
    demoImage: 'assets/experiences/form-single-rounded-vertical.png',
    showInVisibiltyTypes: ['embedded', 'full', 'light', 'slider'],
    purpose: 'leads',
    name: 'Email Collect Vertical Button Right',
    classes: ['right-content', 'rounded-light', 'rounded', 'trd-close-btn'],
    overrides: {
      title: 'Let\'s be in touch',
      subTitle: 'sub header',
      buttonText: 'SUBMIT',
      successText: 'Thanks for sharing',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      scriptCode: ''
    },
    showFields: ['title', 'subTitle', 'successText', 'buttonText', 'media', 'fields', 'leadsNotification'],
    defaultFields: [
      {
        label: 'Your email, please',
        name: 'email',
        validation: 'This field is required',
        validationType: 'email',
        required: true
      },
      {
        label: 'I Agree to our <a target="_blank" href="https://terms.mydomain.com">Privacy Policy</a>',
        name: 'policy',
        validation: 'This field is required',
        validationType: 'privacy-policy',
        required: true
      }
    ]
  },
  {
    layoutId: 350,
    type: 'form',
    description: 'Collect leads with your marketing automation external form',
    demoImage: 'assets/experiences/ma-form.png',
    purpose: 'leads-ma',
    name: 'Marketing Automation Collect Vertical',
    classes: ['single-vertical', 'trd-close-btn'],
    overrides: {
      title: 'Let\'s be in touch',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x300.png`,
      scriptCode: `
      <script>
        /* EMBED EXTERNAL FORM */
      </script>`
    },
    showFields: ['title', 'subTitle', 'scriptCode', 'successText', 'media', 'leadsNotification'],
  },
  {
    layoutId: 351,
    type: 'form',
    classes: ['right-content', 'trd-close-btn'],
    description: 'Collect leads with your marketing automation external form',
    demoImage: 'assets/experiences/ma-form-right.png',
    purpose: 'leads-ma',
    name: 'Marketing Automation Collect Vertical',
    overrides: {
      title: 'Let\'s be in touch',
      imgUrl: `${environment.mediaUrl}/units-graphics/300x400.png`,
      scriptCode: `
      <script>
        /* EMBED EXTERNAL FORM */
      </script>`
    },
    showFields: ['title', 'subTitle', 'scriptCode', 'successText', 'media', 'leadsNotification'],
  }, {
    layoutId: 400,
    classes: ['trd-close-btn'],
    type: 'basic',
    description: 'Custom Html',
    demoImage: 'assets/experiences/custom.png',
    purpose: 'promote',
    name: 'Custom manual html',
    overrides: {
      title: '',
      customHtml: `
<style>
  .trd-cstm-title {
    color:black;
  }
</style>
<div>
  <div class="trd-cstm-title">Style your own unit!
  </div>
  <div>This is just an example</div>
  <small>No scripts allowed</small>
</div>
`
    },
    showFields: ['customHtml', 'media']
  },
  {
    layoutId: 100,
    type: 'basic',
    classes: ['trd-blank-promo', 'trd-close-btn'],
    description: 'Basic blank unit',
    demoImage: 'assets/experiences/basic.png',
    purpose: 'promote',
    name: 'Basic image with text',
    showFields: ['title', 'subTitle', 'buttonText', 'link', 'media'],
    overrides: {
      customHtml: '',
      imgUrl: undefined,
      title: '',
      subTitle: '',
      link: '',
      buttonText: ''
    }
  },
  {
    layoutId: 501,
    type: 'survey',
    classes: ['padded', 'trd-close-btn', 'single-vertical', 'trd-rounded', 'trd-survey-predefined'],
    description: 'Survey',
    demoImage: 'assets/experiences/survey_styled.jpg',
    purpose: 'survey',
    name: 'Survey',
    showFields: ['title', 'successText', 'media'],
    defaultSurveyQuestions: [
      {
        questionId: 0,
        question: 'Let us personalize this experience for you:',
        answers: ['Persona A', 'Persona B', 'Persona C', 'Other']
      }
    ],
    overrides: {
      title: '',
      successText: 'Thank you',
      imgUrl: `${environment.mediaUrl}/units-graphics/welcome.jpg`,
    },
    defaultTheme: 'Prestyled Survey',
    forceConfig: true
  },
  {
    layoutId: 500,
    type: 'survey',
    classes: ['padded', 'trd-close-btn', 'single-vertical'],
    description: 'Survey',
    demoImage: 'assets/experiences/survey.png',
    purpose: 'survey',
    name: 'Survey',
    showFields: ['title', 'successText', 'media'],
    defaultSurveyQuestions: [
      {
        questionId: 0,
        question: 'Mind sharing with us what you\'re interested in?',
        answers: ['DEMAND GENERATION', 'CONTENT MARKETING', 'PERSONALIZATION']
      }
    ],
    overrides: {
      title: 'Hi!',
      successText: 'Thank you',
      imgUrl: undefined
    }
  },

];
export type UnitPurpose = 'leads' | 'engagment' | 'promote' | 'leads-ma' | 'survey' | 'script' | 'hub';
export type UnitsAnalyzeType = 'byArea' | 'byUnit' | 'byAce' | 'byOtw' | 'byStreams';
export interface SupportedExperience<T> {
  layoutId: number;
  preStyleId?: number;
  libPreStyleId?: number;
  disabled?: boolean;
  type: UnitUiTypeName;
  description: string;
  demoImage?: string;
  thumbnailUrl?: string;
  demoImageFixed?: string;
  classes?: string[];
  purpose: UnitPurpose;
  showInVisibiltyTypes?: UnitVisibilityType[];
  name: string;
  defaultFields?: FormField[];
  defaultSurveyQuestions?: SurveyQuestion[];
  overrides?: Partial<T>;
  showFields?: FieldName[];
  positions?: number[];
  defaultTheme?: string;
  forceConfig?: boolean;
}
export interface FormField {
  label: string;
  name: string;
  validation?: string;
  validationType: 'text' | 'email' | 'phone' | 'fullname' | 'name' | 'checkbox' | 'privacy-policy' | 'terms';
  required: boolean;
  maName?: string;
}
export type FieldName = string;


export enum AbTestingType {
  impact = 1,
  comparison
}


