<app-loader *ngIf="isLoadingFields"></app-loader>
<ng-container [formGroup]="form" *ngIf="!isLoadingFields">
  <div formArrayName="rules" class="full-width">

    <div [formGroupName]="j" *ngFor="let field of rules; let j = index" class="rulesGroup">
      <ng-container>
        <app-autocomplete class="field padding-horizontal-4" [itemsList]="maFieldsAutocomplete"
          [label]="'Select a Field' | translate" [required]="true" formControlName="field">
        </app-autocomplete>

        <mat-form-field class="field_comparison_operator margin-horizontal-10" floatLabel="always"
          [hideRequiredMarker]="true">
          <mat-label></mat-label>
          <mat-select matNativeControl formControlName="operator"
            (selectionChange)="resetLineOnChange($event.value, field)">
            <mat-option *ngFor="let operator of maFieldsOperators" [value]="operator.name">
              {{operator.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="field_value">
          <ng-container *ngIf="getMaRuleInputType(field.get('operator').value) === MaFieldType.Array">
            <mat-form-field class="full-width">
              <mat-label>add a value</mat-label>
              <mat-chip-grid #value aria-label="Field Value">
                <mat-chip-row *ngFor="let value of field.get('value').value" (removed)="maValueRemove(field, value)">
                  {{value}}
                  <button matChipRemove> <mat-icon>cancel</mat-icon> </button>
                </mat-chip-row>
                <input [matChipInputFor]="value" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="maValueAdd(field, $event)">
              </mat-chip-grid>
            </mat-form-field>
            <div *ngIf="field.get('value').invalid && (field.get('value').dirty || field.get('value').touched)"
              class="error rule-error">
              <div *ngIf="field.get('value').errors.required" translate>
                At least 1 value need to be added
              </div>
            </div>
          </ng-container>

          <mat-form-field class="full-width input-numeric"
            *ngIf="getMaRuleInputType(field.get('operator').value) === MaFieldType.Numeric">
            <mat-label>set a value</mat-label>
            <input matInput formControlName="value">
            <mat-error *ngIf="field.get('value').hasError('required')">The field is required.</mat-error>
            <mat-error *ngIf="field.get('value').hasError('number')">The field must contain only digits.</mat-error>
          </mat-form-field>
        </div>

      </ng-container>
      <div class="action-icons">
        <mat-icon *ngIf="j>0" matTooltip="{{'Delete' | translate}}" (click)="removeRuleLine(j)"
          class="margin-horizontal-4">remove</mat-icon>
        <mat-icon *ngIf="j===rules.length-1" matTooltip="{{'Add' | translate}}" (click)="addRuleLine()"
          class="margin-horizontal-4">add_circle</mat-icon>
      </div>
    </div>
  </div>
</ng-container>