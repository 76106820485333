import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExperienceService } from 'src/app/modules/account/services/experience.service';
import { ModalsService } from '../../services/modals.service';
import { environment as config } from '../../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { PreStyleService } from 'src/app/modules/account/services/pre-style.service';
import { ImageResizeType } from 'src/app/modules/account/settings/models/settings-models';
import { ImageResizeComponent } from '../image-resize/image-resize.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../submodule-material.module';


@Component({
  selector: 'app-img-uploader',
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule, ImageResizeComponent],
  templateUrl: './img-uploader.component.html',
  styleUrls: ['./img-uploader.component.scss']
})
export class ImgUploaderComponent implements OnInit {
  @Input() imageLink: string;
  @Input() uploadPath: string;
  @Input() returnFullPath = false;
  @Input() isTemplateLibImage = false;
  @Input() resizeType = ImageResizeType.NoResize;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Output() imageUploaded = new EventEmitter<string>();

  uploadImageUrl: string;
  showErrors = false;
  mediaUrl = environment.mediaUrl;
  isLoading = false;

  // resize
  showResize = false;
  imageBlobUrl: string;

  constructor(
    private experienceService: ExperienceService,
    private preStyleService: PreStyleService,
    private modals: ModalsService
  ) { }

  ngOnInit() {
    if (!this.uploadPath) {
      throw new TypeError('\'uploadPath\' is required');
    }
    this.uploadImageUrl = null;
  }

  onUploadClick() {
    this.fileUpload.nativeElement.click();
  }
  get imgSrc(): string {
    let src = null;
    if (this.uploadImageUrl) {
      src = this.uploadImageUrl;
    } else if (this.imageLink?.length) {
      src = this.imageLink;
      return this.imageLink?.startsWith('http') ? this.imageLink : `${this.mediaUrl}/${this.uploadPath}/${this.imageLink}`;
    }

    if (src && !src.startsWith('http')) {
      src = `${this.mediaUrl}/${this.uploadPath}/${src}`;
    }
    return src;
  }

  onCancelUploadImage() {
    this.fileUpload.nativeElement.value = null;
    this.uploadImageUrl = null;
    this.imageUploaded.emit(this.uploadImageUrl);
  }

  async onUploadChange() {
    if (this.fileUpload.nativeElement.files.length > 0) {
      await this.uploadImage(this.uploadImageFile);
    } else {
      this.uploadImageUrl = '';
    }
  }

  async onImageFromClipboard(): Promise<void> {
    const clipboardItems: ClipboardItems = await navigator.clipboard.read();
    const imageItems = clipboardItems.filter(item => item.types.filter(t => t.includes('image')).length > 0);
    if (imageItems.length === 0) {
      await this.modals.Alert('No image was found in clipboard.');
      return;
    }

    const imageItem = imageItems[0];
    const imageItemType = imageItem.types.filter(t => t.includes('image'))[0];
    try {
      const blob = await imageItem.getType(imageItemType);
      if (blob) {
        await this.uploadImage(new File([blob], 'edited_file.png'));
      }
    } catch {
      await this.modals.Alert('Failed to get image from clipboard.');
    }
  }

  async onShowTakeScreenshot(): Promise<void> {
    await this.modals.Alert('Press “Windows + Shift + S”. Your screen will appear grayed out and your mouse cursor will change.<br>Click and drag on your screen to select the part of your screen you want to capture. A screenshot of the screen region you selected will be copied to your clipboard.');
  }

  async onEditImage(): Promise<void> {
    const blob = await this.modals.ImageEditor(this.imgSrc);
    if (blob) {
      await this.uploadImage(new File([blob], 'edited_file.png'));
    }
  }

  async onImageResize(imageBlob: Blob): Promise<void> {
    this.showResize = false;
    await this.uploadImage(new File([imageBlob], 'edited_file.png'));
  }

  get isWindows(): boolean { return window.navigator.platform ? window.navigator.platform === 'Win32' : true; }

  private async uploadImage(imageFile: File): Promise<void> {
    this.isLoading = true;
    const uploadImageObsrv = this.isTemplateLibImage
      ? this.preStyleService.uploadLibImage({ imgFile: imageFile })
      : this.experienceService.uploadImage({ imgFile: imageFile, resizeType: this.resizeType });
    const res = await lastValueFrom(uploadImageObsrv);
    this.uploadImageUrl = res.imgLink;
    this.isLoading = false;
    this.imageUploaded.emit(this.returnFullPath ? this.imgSrc : this.uploadImageUrl);
  }

  get uploadImageFile(): any {
    return this.fileUpload?.nativeElement.files.length ? this.fileUpload.nativeElement.files[0] : null;
  }

  get resizeImageUrl(): string { return this.imgSrc ? `${config.fetchFileUrl}?url=${encodeURIComponent(this.imgSrc)}` : null; }

  get isImageSet(): boolean { return !!this.uploadImageUrl || !this.imageLink?.startsWith('http'); }
}
