import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Subscription, Observable, from } from 'rxjs';
import { FiltersService } from '../filters.service';
import { KeyValuePair } from '../../../models/models';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-filter',
  templateUrl: './autocomplete-filter.component.html',
  styleUrls: ['./autocomplete-filter.component.scss']
})
export class AutocompleteFilterComponent implements OnInit, OnDestroy, OnChanges {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() items: KeyValuePair[];
  @Input() asyncItems = true;
  @Input() serverItems = false;
  @Input() width: string;
  @Output() searchChange = new EventEmitter<string>();

  sub: Subscription;
  filterControl = new UntypedFormControl();
  filteredItems: Observable<KeyValuePair[]>;
  initId: string;
  isItemSelected = false;
  lastSelectId: string;
  isFocused = false;

  constructor(private filterService: FiltersService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.sub = this.filterService.currentFilter.subscribe(p => {
      this.setValue(p[this.name]);
    });

    if (this.serverItems) {
      this.filterControl.valueChanges
        .pipe(
          startWith(''),
          debounceTime(500),
          map(value => typeof value === 'string' ? value : value?.name),
        ).subscribe(search => this.searchChange.emit(search));
    } else {
      this.filteredItems = this.filterControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name),
          map(name => this.filterItems(name))
        );

      this.filteredItems.subscribe(() => this.changeDetectorRef.detectChanges());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.serverItems && changes.items.currentValue) {
      this.filteredItems = from([changes.items.currentValue as KeyValuePair[]]);
    }
    if (this.asyncItems && this.initId &&
      changes.items.currentValue && (changes.items.currentValue as KeyValuePair[]).length) {
      this.setValue(this.initId);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onResetSelection(): void {
    this.filterControl.setValue('');
    this.lastSelectId = undefined;
    this.apply();
  }

  onPanelClosed(): void {
    if (!this.isItemSelected) {
      this.setValue(this.lastSelectId);
    }
    this.isItemSelected = false;
  }

  apply(): void {
    this.isItemSelected = true;
    this.lastSelectId = this.filterControl.value.id;
    this.filterService.apply(this.name, this.filterControl.value.id);
  }

  displayValue(item: KeyValuePair): string {
    return item?.name || '';
  }

  private filterItems(name: string): KeyValuePair[] {
    const filterValue = name?.toLowerCase() || '';
    return this.items.filter(item => item.name.toLowerCase().includes(filterValue));
  }

  private setValue(id: string): void {
    if (this.items?.length) {
      const filterItem = this.items?.find(x => x.id === id);
      this.filterControl.setValue(filterItem);
      this.lastSelectId = filterItem?.id;
      if (filterItem) {
        this.initId = undefined;
        return;
      } else if (id) {
        this.filterControl.setValue(id);
        this.lastSelectId = id;
      }
    }
    this.initId = id;
  }

  handleEmptyInput(event: any): void {
    if (event.target.value === '') {
      this.apply();
    }
  }

}
