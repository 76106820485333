<div class="icon-wrapper" [style.background-color]="color ? color : '#ffffff'"
  [style.line-height]="color ? 'inherit' : 'initial'" *ngIf="iconType && iconType !== 'bg-icon' && iconType !== 'big-image-bottom'"
  [ngClass]="{ imgblock: (iconType === 'big-image' || iconType === 'graph')}">
  <img *ngIf="iconType === 'image' || iconType === 'big-image'" src="{{icon}}">
  <mat-icon *ngIf="iconType === 'material'" [inline]="true" mat-header>
    {{icon}}</mat-icon>
  <div class="contentG" *ngIf="iconType === 'graph'">
    <div class="box gauge--1">
      <div class="mask">
        <div class="semi-circle"></div>
        <div class="semi-circle--mask" [ngStyle]="graphStyles(value)"></div>
      </div>
    </div>
  </div>
</div>
<div class="details d{{iconType}}">
  <div class="title t{{iconType}}" [ngStyle]="{background: iconType === 'big-image-bottom' ? 'url('+icon+') no-repeat bottom right' : 'none'}">
    <span *ngIf="iconType !== 'bg-icon'" class="no-wrap" [matTooltip]="tooltip" matTooltipPosition="above">{{title}}</span>
    <span *ngIf="iconType === 'bg-icon'" class="bg-icon" [ngStyle]="{background:'url('+icon+')'}" [matTooltip]="tooltip" matTooltipPosition="above">{{title}}</span>
  </div>
  <ng-container *ngIf="(value !== undefined && value !== null) || na">
    <div class="value t{{iconType}}">
      <img *ngIf="iconType === 'big-image-bottom'" src="{{icon}}"> {{ na ? 'N/A' : formattedValue }}
      <a *ngIf="linkUrl && linkText" class="support-link" href="{{linkUrl}}" target="_blank">{{linkText}}</a>
    </div>
    <div *ngIf="prvValue !== null && prvValue !== undefined && period" class="change"
      class="{{percentClass(value, prvValue)}}">
      <span class="change-arrow">
        <mat-icon *ngIf="value > prvValue" class="">call_made</mat-icon>
        <mat-icon *ngIf="value < prvValue">call_received</mat-icon>
        <mat-icon *ngIf="value === prvValue || value === null">remove</mat-icon>
      </span>
      <span class="change-value">{{ changePercent }}%</span>
      <span>{{'inLastDays' | translate: { val: period } }} </span>
    </div>
    <div class="info-msg">{{info}}</div>
  </ng-container>
  <ng-container *ngIf="(value === undefined || value === null) && !na">
    <app-tiny-loader></app-tiny-loader>
  </ng-container>
</div>
