import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { lastValueFrom } from 'rxjs';
import { MaFieldType, MaRule, MaRuleOperator } from 'src/app/modules/account/models/goals';
import { MaField } from 'src/app/modules/account/models/integrations';
import { KeyValue } from 'src/app/modules/account/models/models';
import { IntegrationType } from 'src/app/modules/account/settings/integrations/integrations';
import { IntegrationsService } from 'src/app/modules/account/settings/integrations/integrations.service';
import { UserNotificationService } from '../../user-notification.service';
import { numberValidator } from '../../validators/number.validator';

export interface IntegrationRulesValue {
  isValid: boolean;
  value: MaRule[];
}

@Component({
  selector: 'app-integration-fields-values-edit',
  templateUrl: './integration-fields-values-edit.component.html',
  styleUrls: ['./integration-fields-values-edit.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: IntegrationFieldsValuesEditComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: IntegrationFieldsValuesEditComponent }
  ]
})
export class IntegrationFieldsValuesEditComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input() integrationId: IntegrationType;
  @Input() integrationRules: MaRule[] = [];
  @Output() integrationFieldsSet = new EventEmitter<MaField[]>();
  MaFieldType = MaFieldType;
  maFieldsAutocomplete: KeyValue[];
  isLoadingFields = true;
  initRules = true;
  form: UntypedFormGroup = new UntypedFormGroup({
    rules: new UntypedFormArray([])
  });
  private maFieldsOperators = [
    { name: MaRuleOperator.Equals, label: 'Is' },
    { name: MaRuleOperator.NotIn, label: 'Is Not' },
    { name: MaRuleOperator.GreaterThan, label: 'Greater Than' },
    { name: MaRuleOperator.LessThan, label: 'Less Than' },
    { name: MaRuleOperator.NotEmpty, label: 'Not Empty' },
    { name: MaRuleOperator.Contains, label: 'Contains' },
  ];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  onValidatorChange = () => { };
  onChange: (value: MaRule[]) => void = (value: MaRule[]) => { };
  onTouched = () => { };

  constructor(
    private integrationsService: IntegrationsService,
    private notification: UserNotificationService,
  ) { }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(formValue => {
      this.onChange(this.rulesFormArray.value);
      this.onValidatorChange();
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.integrationId && this.integrationId) {
      try {
        const maFields = await lastValueFrom(this.integrationsService.getIntegrationsFields(this.integrationId));
        this.integrationFieldsSet.emit(maFields);
        this.maFieldsAutocomplete = maFields.map(f => ({ id: f.name, name: f.label }));

        if (this.initRules) {
          this.rulesFormArray.clear();
          this.addRuleLine();
        } else {
          this.initRules = true;
        }
        this.isLoadingFields = false;
      } catch (ex) {
        this.notification.error(ex, 'Something went wrong. Please check Integration Connection.');
      }
    }
  }

  // ControlValueAccessor interface - start
  writeValue(maRules: MaRule[]): void {
    if (maRules) {
      maRules?.forEach(rule => this.addRuleLine(rule));
      this.initRules = false;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // ControlValueAccessor interface - end

  // Validator interface - start
  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { invalid: true };
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }
  // Validator interface - end

  addRuleLine(maRule?: MaRule) {
    this.rulesFormArray.push(this.initRuleLine(maRule));
  }

  removeRuleLine(lineIndex: number) {
    this.rulesFormArray.removeAt(lineIndex);
  }

  resetLineOnChange(operator: MaRuleOperator, line: UntypedFormGroup): void {
    const defaultValue = this.getMaRuleInputType(operator) === MaFieldType.Array ? [] : '';
    line.patchValue({
      value: defaultValue
    });
    this.setValidators(line, operator);
  }

  maValueAdd(line: UntypedFormGroup, event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      line.get('value').value.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    line.get('value').updateValueAndValidity();
  }

  maValueRemove(line: UntypedFormGroup, value: string): void {
    const controller = line.get('value');
    const index = line.get('value').value.indexOf(value);
    if (index >= 0) {
      line.get('value').value.splice(index, 1);
    }
    controller.updateValueAndValidity();
    controller.markAsDirty();
  }

  get rulesFormArray(): UntypedFormArray { return this.form.get('rules') as UntypedFormArray; }
  get rules(): AbstractControl[] { return this.rulesFormArray.controls; }

  private initRuleLine(maRule?: MaRule) {
    let value: string | number;
    if (maRule) {
      switch (maRule.operator) {
        case MaRuleOperator.NotIn:
        case MaRuleOperator.Equals:
        case MaRuleOperator.Contains: {
          value = JSON.parse(maRule.value);
          break;
        }
        default: {
          value = + maRule.value;
          break;
        }
      }
    }

    const line = new UntypedFormGroup({
      field: new UntypedFormControl(maRule ? maRule.field : '', [Validators.required]),
      operator: new UntypedFormControl(maRule ? maRule.operator : this.maFieldsOperators[0].name, [Validators.required]),
      value: new UntypedFormControl(value !== undefined ? value : []),
    });

    if (maRule == null) {
      this.setValidators(line, this.maFieldsOperators[0].name);
    } else {
      this.setValidators(line, maRule.operator);
    }

    return line;
  }

  private setValidators(line: UntypedFormGroup, operator: MaRuleOperator) {
    const valueField = line.get('value');
    if (this.getMaRuleInputType(operator) === MaFieldType.Array) {
      valueField.setValidators(Validators.required);
    } else if (this.getMaRuleInputType(operator) === MaFieldType.Numeric) {
      valueField.setValidators([Validators.required, numberValidator]);
    } else {
      valueField.clearValidators();
    }
    valueField.updateValueAndValidity();
  }

  private getMaRuleInputType(operator: MaRuleOperator) {
    switch (operator) {
      case MaRuleOperator.Equals:
      case MaRuleOperator.NotIn:
      case MaRuleOperator.Contains: {
        return MaFieldType.Array;
      }
      case MaRuleOperator.GreaterThan:
      case MaRuleOperator.LessThan: {
        return MaFieldType.Numeric;
      }
      default: {
        return MaFieldType.Hidden;
      }
    }
  }
}
