import { Pipe, PipeTransform } from '@angular/core';
import { CompaniesTargetListItemType } from '../../account/models/companies-target-lists.models';

@Pipe({name: 'targetListType'})
export class TargetListTypePipe implements PipeTransform {

  transform(targetListType: CompaniesTargetListItemType) {
    switch (targetListType) {
      case CompaniesTargetListItemType.Company: return 'Company';
      case CompaniesTargetListItemType.CompanyExact: return 'Company (Exact Match)';
      case CompaniesTargetListItemType.Domain: return 'Domain';
      default: return '';
    }
  }
}
