import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export const CssSelectorValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }
    try {
        document.createDocumentFragment().querySelector(control.value);
        return null;
    } catch (ex) {
        return { cssSelector: true };
    }
};

@Directive({
    selector: '[appCssSelector]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CssSelectorValidatorDirective, multi: true }]
})
export class CssSelectorValidatorDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return CssSelectorValidator(control);
    }
}
