import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeEvent, CKEditorModule } from '@ckeditor/ckeditor5-angular';

import {
  ClassicEditor, AccessibilityHelp, Alignment, AutoImage, AutoLink, Autosave, Bold, Essentials, FontBackgroundColor, FontColor, FontFamily, FontSize,
  ImageBlock, ImageCaption, ImageInline, ImageInsert, ImageInsertViaUrl, ImageResize, ImageStyle, ImageToolbar, ImageUpload, Italic,
  Link, LinkImage, List, MediaEmbed, Paragraph, SelectAll, SimpleUploadAdapter, Undo, type EditorConfig
} from 'ckeditor5';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ck-classic-editor',
  standalone: true,
  imports: [CommonModule, CKEditorModule],
  templateUrl: './ck-classic-editor.component.html',
  styleUrl: './ck-classic-editor.component.scss',
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CkClassicEditorComponent }
  ]
})
export class CkClassicEditorComponent implements ControlValueAccessor, AfterViewInit {
  constructor(private changeDetector: ChangeDetectorRef) { }

  public isLayoutReady = false;
  public Editor = ClassicEditor;
  public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.

  private isViewInit = false;
  private isValueSet = false;
  public htmlText = '';

  private onValueChange = (_: string) => { };

  public ngAfterViewInit(): void {
    this.isViewInit = true;
    this.InitEditor();
  }

  // ControlValueAccessor interface - start
  async writeValue(htmlText: string): Promise<void> {
    this.htmlText = htmlText;
    this.isValueSet = htmlText !== null && htmlText !== undefined;
    this.InitEditor();
  }
  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }
  registerOnTouched(fn: any): void {
    // console.log('registerOnTouched');
  }
  // ControlValueAccessor interface - end

  onHtmlTextChange({ editor }: ChangeEvent) {
    this.htmlText = editor.getData();
    this.onValueChange(this.htmlText);
  }

  private InitEditor(): void {
    if (!this.isViewInit || !this.isValueSet) { return; }

    this.config = {
      toolbar: {
        items: ['undo', 'redo', '|',
          // {
          //   label: 'Font',
          //   icon: 'text',
          //   items: ['fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor']
          // },
          'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
          'bold', 'italic', 'alignment', '|',
          'link', /*'insertImage', 'mediaEmbed',*/ '|',
          'bulletedList', 'numberedList'
        ],
        shouldNotGroupWhenFull: false
      },
      plugins: [
        AccessibilityHelp, Alignment, AutoImage, AutoLink, Autosave, Bold, Essentials, FontBackgroundColor, FontColor, FontFamily, FontSize,
        ImageBlock, ImageCaption, ImageInline, ImageInsert, ImageInsertViaUrl, ImageResize, ImageStyle, ImageToolbar, ImageUpload,
        Italic, Link, LinkImage, List, MediaEmbed, Paragraph, SelectAll, SimpleUploadAdapter, Undo
      ],
      fontFamily: {
        supportAllValues: true
      },
      fontSize: {
        options: ['default', 8, 10, 12, 14, 18, 24, 30, 36, 48],
        supportAllValues: true
      },
      image: {
        toolbar: [
          'toggleImageCaption', 'imageTextAlternative', '|',
          'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
          'resizeImage'
        ]
      },
      initialData: this.htmlText,
      link: {
        addTargetToExternalLinks: false,
        defaultProtocol: 'https://',
        decorators: {
          openInNewTab: {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
              target: '_blank'
            }
          }
        }
      },
      placeholder: 'Type or paste your content here!'
    };

    this.isLayoutReady = true;
    this.changeDetector.detectChanges();
  }
}
