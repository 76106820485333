import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export const notInListValidator = (list: string[]): ValidatorFn => {
    const testList = list.map(s => s.toLocaleLowerCase());
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value && testList.includes(control.value.toLocaleLowerCase()) ? { inList: true } : null;
};

@Directive({
    selector: '[appNotInList]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NotInListValidatorDirective, multi: true }]
})
export class NotInListValidatorDirective implements Validator {
    @Input('appNotInList') stringList: string[];

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.stringList ? notInListValidator(this.stringList)(control) : null;
    }
}
