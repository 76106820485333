import { Directive, ElementRef, HostListener } from '@angular/core';

const ControlKeys = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];
const DigitsKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

@Directive({
    selector: '[appDigitsOnly]'
})
export class DigitsOnlyDirective {

    constructor(private el: ElementRef<HTMLInputElement>) { }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        if (ControlKeys.includes(event.code) ||
            // Allow: Ctrl+A
            (event.code === 'KeyA' && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+C
            (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+V
            (event.code === 'KeyV' && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+X
            (event.code === 'KeyX' && (event.ctrlKey || event.metaKey))
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if (!(DigitsKeys.includes(event.key))) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.pasteText(event.clipboardData.getData('text/plain'));
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        console.log('onDrop', event);
        event.preventDefault();
        this.pasteText(event.dataTransfer.getData('text/plain'));
    }

    private pasteText(text: string): void {
        const pastedInput: string = text.replace(/\D/g, ''); // get a digit-only string

        const element = this.el.nativeElement;
        element.focus();
        element.value = element.value.substr(0, element.selectionStart)
            + pastedInput + element.value.substr(element.selectionEnd, element.value.length);
    }
}
