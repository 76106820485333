import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-strings-list',
  templateUrl: './edit-strings-list.component.html',
  styleUrls: ['./edit-strings-list.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: EditStringsListComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: EditStringsListComponent, multi: true }
  ]
})
export class EditStringsListComponent implements AfterViewInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() addLabel = 'Add Page';
  @Input() validators = [Validators.required];
  @Input() required = false;
  @ViewChildren(NgModel) valuesModels: QueryList<NgModel>;
  stringsList: string[];
  isValid = true;
  subscriptions: Subscription[] = [];

  private onValueChange = (_: string[]) => { };
  private onTouched = () => { };
  private onValidatorChange = () => { };

  constructor(private changeDetector: ChangeDetectorRef) { }

  // ControlValueAccessor interface - start
  writeValue(stringsList: string[]): void {
    if (Array.isArray(stringsList)) {
      this.stringsList = stringsList.slice();
      this.changeDetector.detectChanges();
    }
  }
  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // ControlValueAccessor interface - end

  // Validator interface - start
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.isValid) {
      return { invalid: true };
    }
    if (this.required && (this.stringsList?.length ?? 0) === 0) {
      return { required: true };
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }
  // Validator interface - end

  ngAfterViewInit(): void {
    this.subscribeToValuesStatusChange();
    this.valuesModels.changes.subscribe(() => this.subscribeToValuesStatusChange());
    setTimeout(() => this.setIsValid(), 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  onDeleted(index: number): void {
    this.stringsList.splice(index, 1);
    this.onValueEditChange();
  }

  onAddPage(): void {
    // The timeout fixes the problem of duplicate values
    // when a value is in edit model and Add button is clicked
    setTimeout(() => {
      this.stringsList.push('');
      this.onValueEditChange();
    }, 300);
  }

  onValueEditChange(): void {
    this.onValueChange(this.stringsList);
    this.onTouched();
    this.changeDetector.detectChanges();
  }

  private subscribeToValuesStatusChange(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = this.valuesModels.map(v => v.statusChanges.subscribe((x) => {
      this.setIsValid();
    }));
    this.setIsValid();
  }

  private setIsValid(): void {
    this.isValid = this.valuesModels.filter(v => v.invalid).length === 0;
    this.onValidatorChange();
  }
}
