import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FiltersService } from '../filters.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent implements OnInit, OnChanges, OnDestroy {

  @Input() placeholder: string;
  @Input() name: string;
  @Input() validators: ValidatorFn[] = [];
  @Input() invalidText = 'Invalid input';
  @Input() disabled = false;
  valueControl: UntypedFormControl;
  sub: Subscription;
  valueSub: Subscription;
  txtQueryChanged: Subject<string> = new Subject<string>();
  @Input() onlyCompare = false;
  @Output() filterChanged = new EventEmitter<{ onlyComapre: boolean }>();
  constructor(private filterService: FiltersService) {

  }

  ngOnInit(): void {
    this.placeholder = this.placeholder || 'search..';

    this.valueControl = new UntypedFormControl('', this.validators);
    if (this.disabled) {
      this.valueControl.disable();
    }

    this.valueSub = this.valueControl.valueChanges.subscribe(value => {
      if (this.valueControl.valid) {
        this.txtQueryChanged.next(this.valueControl.value);
      }
    });

    this.sub = this.filterService.currentFilter.subscribe(p => {
      this.valueControl.setValue(p[this.name]);
    });

    this.txtQueryChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(model => {
        this.filterService.apply(this.name, model);
        this.filterChanged.emit({ onlyComapre: this.onlyCompare });
        // api call
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.valueControl?.disable();
      } else {
        this.valueControl?.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.valueSub.unsubscribe();
  }
}
