<form [formGroup]="form" class="rulesForm">
  <div formArrayName="fields" class="full-width">
    <div [formGroupName]="j" *ngFor="let field of fieldLines; let j = index;first as isFirst; last as isLast;"
      class="padding-vertical-4 margin-vertical-4 rulesGroup">
      <ng-container>
        <mat-form-field class="margin-horizontal-4" subscriptSizing="dynamic">
          <mat-label>Name (shown on TrenDemon Dashboard)</mat-label>
          <input type="text" matInput formControlName="name" />
          <mat-error *ngIf="field.get('name').hasError('nameValidator')">
            can't contain spaces or special symbols
          </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-horizontal-4" subscriptSizing="dynamic">
          <mat-label>{{field.get('validationType').value === 'hidden' ? 'Field Value' : 'Field Label'}}</mat-label>
          <input type="text" matInput formControlName="label" />
        </mat-form-field>

        <mat-form-field class="margin-horizontal-4">
          <mat-label translate>Field Type</mat-label>
          <mat-select formControlName="validationType" class="padding-horizontal-4">
            <mat-option value="text" translate> Text</mat-option>
            <mat-option value="email" translate> Email</mat-option>
            <mat-option value="name" translate> Name</mat-option>
            <mat-option value="checkbox" translate> Checkbox</mat-option>
            <mat-option value="phone" translate> Phone No.</mat-option>
            <mat-option value="privacy-policy" translate> Privacy Policy</mat-option>
            <mat-option value="hidden" translate> Hidden</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="margin-horizontal-4" *ngIf="integrationId && maFields">
          <mat-label translate>Field Name in {{maDisplayName}}</mat-label>
          <mat-select formControlName="maName" class="padding-horizontal-4">
            <mat-option *ngFor="let maField of maFields" [value]="maField.name" translate> {{maField.label}}
            </mat-option>

          </mat-select>
        </mat-form-field>
        <mat-checkbox class="margin-horizontal-4" formControlName="required" class="padding-horizontal-4">Required
        </mat-checkbox>
      </ng-container>
      <div class="action-icons">
        <mat-icon *ngIf="j>0" matTooltip="{{'Delete' | translate}}" (click)="removeFieldLine(j)"
          class="margin-horizontal-4">
          remove</mat-icon>
        <mat-icon *ngIf="j===fieldLines.length-1" matTooltip="{{'Add' | translate}}" (click)="addFieldLine()"
          class="margin-horizontal-4">
          add_circle</mat-icon>
        <div class="ordering-buttons">
          <mat-icon *ngIf="fieldLines.length>1 && !isFirst" class="ordering-icon" (click)="move(j, j-1)">arrow_upward
          </mat-icon>
          <mat-icon *ngIf="fieldLines.length>1 && !isLast" class="ordering-icon" (click)="move(j, j+1)">arrow_downward
          </mat-icon>

        </div>
      </div>
    </div>
  </div>
</form>