import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { FormField } from '../experiences';
import { MaField } from '../../models/integrations';
import { IntegrationType } from '../../settings/integrations/integrations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-experience-form-fields',
  templateUrl: './experience-form-fields.component.html',
  styleUrls: ['./experience-form-fields.component.scss']
})
export class ExperienceFormFieldsComponent implements OnChanges {
  form = new UntypedFormGroup({
    fields: new UntypedFormArray([])
  });

  @Input() maFields: MaField[];
  @Input() formReset: boolean;
  @Input() formFields: FormField[];
  @Input() integrationId: IntegrationType;
  @Output() saved = new EventEmitter<{ isValid: boolean; formFields: FormField[]; fromInit: boolean }>();
  private subscr: Subscription;

  initForm() {
    this.subscr?.unsubscribe();
    while ((this.form.get('fields') as UntypedFormArray).length !== 0) {
      (this.form.get('fields') as UntypedFormArray).removeAt(0);
    }

    if (this.formFields != null) {
      this.formFields.forEach(line => {
        this.addFieldLine(line);
      });
    } else {
      this.addFieldLine();
    }

    this.buildFieldsAndSend(this.form, true);

    this.subscr = this.form.valueChanges
      .subscribe(() => this.buildFieldsAndSend(this.form));
  }

  addFieldLine(field?: FormField) {
    const control = this.form.get('fields') as UntypedFormArray;
    if (field) {
      control.push(this.initFieldLine(field));
    } else {
      control.push(this.initFieldLine());
    }
    // this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    // document.scrollIn
  }

  initFieldLine(field?: FormField) {
    const line = new UntypedFormGroup({
      name: new UntypedFormControl(field?.name || '', [Validators.required, this.nameValidator]),
      label: new UntypedFormControl(field?.label || '', [Validators.required]),
      // validation: new FormControl(field?.validation || '', [Validators.required]),
      validationType: new UntypedFormControl(field?.validationType || 'text', [Validators.required]),
      required: new UntypedFormControl(field?.required ?? true),
      maName: new UntypedFormControl(field?.maName || '', [this.maFieldValidator.bind(this)])
    });
    return line;
  }
  get fieldLines(): AbstractControl[] {
    const formArray = this.form.controls.fields as UntypedFormArray;
    return formArray.controls;
  }

  removeFieldLine(lineIndex: number) {
    const control = this.form.get('fields') as UntypedFormArray;
    control.removeAt(lineIndex);
  }

  buildFieldsAndSend(form: UntypedFormGroup, fromInit = false) {
    const fields = form.get('fields') as UntypedFormArray;
    const formFields: FormField[] = fields.getRawValue();
    this.saved.emit({ isValid: form.valid, formFields, fromInit });
  }

  move(fieldIndex: number, newIndex: number) {
    const control = this.form.get('fields') as UntypedFormArray;
    const f = control.at(fieldIndex);
    control.removeAt(fieldIndex);
    control.insert(newIndex, f);
  }
  nameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== null && control.value !== '') {
      const isValid = /^-?[_a-zA-Z]+[_a-zA-Z0-9-]*$/.test(control.value);
      if (isValid) {
        return null;
      } else {
        return { nameValidator: true };
      }
    }
    return null;
  }
  maFieldValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.integrationId > 0 && !control.value) {
      return { maFieldValidator: true };
    }
    return null;
  }
  get maDisplayName(): string { return IntegrationType[this.integrationId]; }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.integrationId) {
      if (changes.integrationId.previousValue) {
        this.formFields.forEach(f => f.maName = null);
      }
      this.initForm();
    }
    if (changes.formReset && this.formReset === true) {
      this.subscr?.unsubscribe();
      this.subscr = null;
      const fields = this.form.get('fields') as UntypedFormArray;
      this.clearFormArray(fields);
      this.initForm();
    }
  }

  get formName(): AbstractControl { return this.form.controls.name; }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

}
