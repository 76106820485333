<form #themeForm="ngForm">
  <mat-checkbox #bgType="matCheckbox" class="no-left-margin" [checked]="advancedBg" (change)="changeBgType($event)">
    {{'Advanced Background' | translate}}
  </mat-checkbox>

  <div class="colors-container">
    <app-color-picker-input *ngIf="!bgType.checked" [(ngModel)]="unit.backgroundColor" name="backgroundColor"
      label="Background Color"></app-color-picker-input>

    <mat-form-field *ngIf="bgType.checked">
      <mat-label>Background</mat-label>
      <input matInput placeholder="url()" required [(ngModel)]="unit.backgroundColor" name="backgroundColor">
      <mat-hint>url() or gradient()<a href="https://developer.mozilla.org/en-US/docs/Web/CSS/background"
          target="_blank">more info</a></mat-hint>
    </mat-form-field>

    <app-color-picker-input [(ngModel)]="unit.textColor" name="textColor"
      label="{{'Text Color' | translate}}"></app-color-picker-input>
    <app-color-picker-input [(ngModel)]="unit.secondaryTextColor" name="secondaryTextColor"
      label="{{'Secondary Text Color' | translate}}"></app-color-picker-input>
    <app-color-picker-input [(ngModel)]="unit.buttonBackgroundColor" name="buttonBackgroundColor"
      label="{{'Buttons Background Color' | translate}}"></app-color-picker-input>
    <app-color-picker-input [(ngModel)]="unit.buttonTextColor" name="buttonTextColor"
      label="{{'Buttons Text Color' | translate}}"></app-color-picker-input>
  </div>

  <mat-form-field class="form-field-textarea">
    <mat-label>{{'Custom CSS' | translate}}</mat-label>
    <textarea matInput name="css" rows="5" [(ngModel)]="unit.css"></textarea>
  </mat-form-field>
</form>