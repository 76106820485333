import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { comparisonInputType, predicateGroups, RuleField, RulesOptions } from '../../../models/models';

@Component({
  selector: 'app-rule-engine-predicate',
  templateUrl: './rule-engine-predicate.component.html',
  styleUrls: ['./rule-engine-predicate.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: RuleEnginePredicateComponent }
  ]
})
export class RuleEnginePredicateComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() rulesOptions: RulesOptions;
  @Input() ruleFields: RuleField[];
  @Output() selectionChange = new EventEmitter<string>();
  tooManyValuesTooltip = 'There are too many available values. We can\'t show them by name. Please set the Ids in field\'s value.';
  searchText = new UntypedFormControl(null);
  selectedItem: RuleField;
  ruleFieldGrouped: { group: string; fields: RuleField[]; hidden: boolean }[];
  onChange = (id: string) => { };

  constructor() { }
  ngOnInit(): void {
    this.searchText.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.filteredList(name) : this.ruleFields.slice())
    )
      .subscribe(itemsList => this.groupRulesFields(itemsList));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ruleFields) {
      this.groupRulesFields(this.ruleFields);
    }
  }

  // ControlValueAccessor interface - starts
  writeValue(predicate: string): void {
    if (predicate) {
      this.selectedItem = this.ruleFields.find(x => x.predicate === predicate);
      this.searchText.setValue(this.selectedItem);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // console.log('RuleEnginePredicate registerOnTouched');
  }
  // ControlValueAccessor interface - end

  onItemSelected(item: RuleField): void {
    this.selectedItem = item;
    this.onChange(item.predicate);
    this.selectionChange.emit(item.predicate);
  }

  onPanelClosed(): void {
    // if panel is closed without selection - set input to the last selection
    if (this.selectedItem && typeof this.searchText.value === 'string') {
      this.searchText.setValue(this.selectedItem);
    } else if (!this.selectedItem) {
      this.searchText.setValue('');
    }
  }

  dispalyName(item: RuleField): string {
    return item?.name;
  }

  predicateGroupNameByValue(name: string): string {
    const group = predicateGroups.find(e => +e.name === +name);
    if (group.showTitle) {
      return predicateGroups.find(e => +e.name === +name).label;
    }
    return '';
  }

  groupRulesFields(usedRuleFields: RuleField[]) {
    const groupToValues = usedRuleFields.reduce((obj, item) => {

      let isValid = true;
      if ((this.getCollection(item.predicate) === undefined || this.getCollection(item.predicate).length === 0)
        && (item.fieldType === comparisonInputType.multiselect || item.fieldType === comparisonInputType.autocomplete)) {
        isValid = false;
      }

      if (isValid) {
        obj[item.group] = obj[item.group] || [];
        obj[item.group].push(item);
      }
      return obj;

    }, {});

    this.ruleFieldGrouped = Object.keys(groupToValues).map((key) => ({ group: key, fields: groupToValues[key], hidden: false }));
  }

  private getCollection(value: string): any {
    const field = this.ruleFields.find(f => f.predicate === value);    
    return field.availableValues ?? this.rulesOptions.data[field.valueType];
  }

  private filteredList(name: string): RuleField[] {
    const filterValue = name.toLowerCase();
    return this.ruleFields.filter(i => i.name.toLowerCase().includes(filterValue));
  }
}
