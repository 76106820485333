import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Utils } from "../classes/utils";

export const stripeDomainValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!value) {
    return null; 
  }
  
  // cleaning domain from http,https,www
  const cleaned = Utils.stripDomain(value);
  if (!cleaned) {
    return { invalidUrl: true };
  }

  return null; 
};