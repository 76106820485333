import { CompaniesTargetListBuildType, CompaniesTargetListItemType } from '../../account/models/companies-target-lists.models';
import { GoalSettings } from '../../account/models/goals';
import { KeyValue } from '../../account/models/models';
import { ClientAppType } from '../../account/settings/models/settings-models';
import { AccountFeature } from './IAccount';

export type OrderDirection = 'desc' | 'asc';

export interface KeyValuePair {
  id: string;
  name: string;
}

export interface KeyValueGroup {
  groupName: string;
  items: KeyValuePair[];
}

export interface RecordsResponse<T> {
  total: number;
  data: T[];
}

export interface DataResponse<T> {
  data: T;
}

export interface SelectOption<T> {
  label: string,
  value: T
}

export interface AccountAsset {
  id: number;
  name: string;
  features: string[];
}

export interface GenericKeyValuePair<TKey, TValue> {
  id: TKey;
  name: TValue;
}

export type GoalOrEvent = 'Event' | 'Goal';

export interface AccountGoal {
  id: number;
  name: string;
  type: GoalType;
  status: Status;
  actionType: GoalOrEvent;
}

export interface AbmTargetList {
  list_id: number;
  list_name: string;
  list_type: CompaniesTargetListItemType;
  build_type: CompaniesTargetListBuildType;
}

export interface AccountArea {
  areaId: number;
  by: string;
  contentType: 'none' | 'general' | 'content';
  pattern: string;
  name: string;
}

export interface AccountCta {
  id: number;
  name: string;
  type: string;
  behavior: string;
  status: string;
}

export enum Status {
  'Active' = 1,
  'Paused' = 2,
  'Closed' = 3,
}

export enum UnitStatus {
  'Active' = 1,
  'Paused' = 2,
  'Closed' = 3,
  'Scheduled' = 4,
  'Expired' = 5
}

export const statusesSelect: KeyValuePair[] = [
  { id: null, name: 'All' },
  { id: Status.Active.toString(), name: 'Active' },
  { id: Status.Paused.toString(), name: 'Paused' },
  { id: Status.Closed.toString(), name: 'Deleted' }
];

export interface StatusRequest {
  status: Status;
}

/*export enum GoalType {
  GOURL = 1,
  PAGEVIEWS = 2,
  ACTIONCONVERSION = 3,
  BUTTONCLICK = 4,
  THIRDPARTY = 5
}*/

export enum GoalType {
  'Destination' = 1,
  'Action Conversion' = 3,
  'Button click' = 4,
  '3rd party' = 5
}

export interface Country {
  label: string;
  code_country: string;
  code_state: string;
}

export interface State {
  id: string;
  name: string;
}

export interface GoalStats {
  id: string;
  count: number;
  contentInfluancePercent?: number;
  changePercent?: number;
  period?: number;
}

export interface DashboardNavigatorItem {
  title: string;
  icon: string;
  links: { avatar: string; text: string; href: string }[];
}

export interface PageTag {
  id: number;
  name: string;
  autoGenerated: boolean;
}

export interface RuleField {
  name: string;
  predicate: string;
  valueType: comparisonValueType;
  valuePlaceholder?: string;
  isDaysPredicate?: boolean;
  fieldType?: comparisonInputType;
  operatorType: ruleOperatorType;
  group: predicateGroupName;
  description?: string;
  feature?: AccountFeature;
  valuesGrouped?: boolean;
  clientApp?: ClientAppType;
  availableValues?: KeyValuePair[];
  isTooManyValues?: boolean;
}

export interface RulesConfig {
  rulesOperator: string;
  rulesGroups: RulesGroup[];
}

export interface RulesGroup {
  rulesOperator: string;
  rules: Rule[];

}

export interface Rule {
  comparisonPredicate: string;
  comparisonOperator: string;
  comparisonValue: string;
  daysPredicate?: number;
  comparisonArray?: string[];
  predicateType?: string;
}


export interface RulesOptions {
  config: RuleOptionsConfig;
  data: RuleOptionsData;
}

export interface RuleOptionsConfig {
  singleGroup?: boolean;
  fixedRules?: boolean;
  rulesRequired?: boolean;
  hideGroupsOperatorToggle?: boolean;
  hideInGroupOperatorToggle?: boolean;
}

export type RuleOptionsData = {
  [key in comparisonValueType]?: RuleOptionsDataValue;
} | []

export type RuleOptionsDataValue =
  (KeyValue | string | GoalSettings | Country | { id: number; name: string; })[]
  | { [key: string]: { id: string; name: string; group: string }[] } // survey quesions
  | undefined;

export enum comparisonInputType {
  textField,
  number,
  multiselect,
  chips,
  hidden,
  email,
  url,
  domain,
  dateTime,
  autocomplete,
  select,
  freeTextAutocomplete,
}

export enum comparisonValueType {
  int,
  boolean,
  string,
  collection,
  campaigns_collection,
  sources_collection,
  source_types_collection,
  referrers_collection,
  areas_collection,
  country_iso_collection,
  region_iso_collection,
  industries_collection,
  target_lists,
  events_and_goals_collection,
  tags_collection,
  platforms_collection,
  devices_collection,
  malists_collection,
  domains_collection,
  units_collection,
  question_collection,
  sf_opportunity_stages_collection,
  drift_events_collection,
  company_sizes_collection,
  company_revenues_collection,
  sixsense_segments,
  audiences_collection,
  otws_collection,
  streams_collection,
  contact_target_lists,
  empty_collection,
  deals_stages,
}

export enum ruleOperatorType {
  ruleCollectionOperators,
  ruleIntGreaterThanOperators,
  ruleIntOperators,
  ruleStringOperators,
  ruleStringFromListOperators,
  ruleBooleanOperators,
  ruleSelectOneOperators,
  ruleIntOperatorsFull,
  ruleStringOperatorsFull,
  ruleCollectionOperatorsReduced,
  ruleCollectionOperatorContainsAny,
  ruleStringFromListOperatorsInclude,
  ruleCollectionOperatorsInclElementContains,
  ruleStringOperatorsForQuery,
  ruleStringOperatorsReduced,
  ruleCollectionPositive
}

export interface IdNamePair {
  id: string;
  name: string;
}

export enum predicateGroupName {
  visitor,
  activity,
  pages,
  sources,
  url,
  device,
  maGeneral,
  maFields,
  salesforce,
  crmAccount,
  crmOpportunity,
  crmLead,
  currentSession,
  company,
  visitorsActivity,
  deals,
}

export interface PredicateGroupSpec {
  name: predicateGroupName;
  label: string;
  showTitle: boolean;
}

export const predicateGroups: PredicateGroupSpec[] = [
  {
    name: predicateGroupName.activity,
    label: 'Visitor\'s Activity',
    showTitle: true
  },
  {
    name: predicateGroupName.visitor,
    label: 'Visitor Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.pages,
    label: 'Pages',
    showTitle: true
  },
  {
    name: predicateGroupName.sources,
    label: 'Sources that Visitor came from',
    showTitle: true
  },
  {
    name: predicateGroupName.maGeneral,
    label: 'General',
    showTitle: true
  },
  {
    name: predicateGroupName.maFields,
    label: 'Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.url,
    label: 'Page Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.device,
    label: 'Device Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.salesforce,
    label: 'SalesForce Data',
    showTitle: true
  },
  {
    name: predicateGroupName.crmAccount,
    label: 'CRM Account Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.crmOpportunity,
    label: 'CRM Opportunity Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.crmLead,
    label: 'CRM Lead Properties',
    showTitle: true
  },
  {
    name: predicateGroupName.currentSession,
    label: 'Current Session',
    showTitle: true
  },
  {
    name: predicateGroupName.company,
    label: 'Company',
    showTitle: true
  },
  {
    name: predicateGroupName.visitorsActivity,
    label: 'Visitors\' Activity',
    showTitle: true
  },
  {
    name: predicateGroupName.deals,
    label: 'Deals',
    showTitle: true
  },
];

export interface ChangeOrderRequest {
  ids: number[];
}
