import { Router, ActivatedRoute, Params, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash-es';


@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  defaults: Params = {};
  currentFilter = new BehaviorSubject<{ [key: string]: string }>({});
  filterActivelyChanged = new Subject<any>();

  constructor(private router: Router, private route: ActivatedRoute, private ngZone: NgZone) {
    document.addEventListener('accountChanged', () => {
      this.currentFilter.next(this.currentFilter.value);
    });
    route.queryParams.subscribe((p: Params) => {
      const filter = Object.assign({}, this.defaults, p);
      if (!_.isEqual(this.currentFilter.value, filter)) {
        this.currentFilter.next(filter);
      }
    });
  }
  public setDefault(key: string, value: string) {
    this.defaults[key] = value;
    this.currentFilter.next(Object.assign({}, this.defaults, this.currentFilter.value));
  }
  public setDefaults(defaults: { key: string; value: string }[]) {
    for (const def of defaults) {
      this.defaults[def.key] = def.value;
    }

    this.currentFilter.next(Object.assign({}, this.defaults, this.currentFilter.value));
  }
  async apply(key: string, value: string, bubbleChange = true) {
    const queryParams: Params = {};

    queryParams[key] = value;
    // using ngZone.run() eliminates warning 'Navigation triggered outside Angular zone'
    this.ngZone.run(async () => {
      if (this.routePath === window.location.pathname) {
        await this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge'
          });
        if (bubbleChange) {
          this.filterActivelyChanged.next(new Date().getTime());
        }
      }
    });
    return;
  }

  async applyM(queryParams: Params, bubbleChange = true) {
    // using ngZone.run() eliminates warning 'Navigation triggered outside Angular zone'
    this.ngZone.run(async () => {
      if (this.routePath === decodeURIComponent(window.location.pathname)) {
        await this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge'
          });
        if (bubbleChange) {
          this.filterActivelyChanged.next(new Date().getTime());
        }
      }
    });
  }

  private get routePath(): string {
    const rootPath = this.route.snapshot.pathFromRoot.flatMap(x => x.url).map(x => x.path).join('/');
    const childrenPath = this.childrenPath;
    const fullPath = [
      ...(rootPath ? [rootPath] : []),
      ...(childrenPath ? [childrenPath] : []),
    ].join('/')
    return '/' + fullPath;
  }
  private get childrenPath(): string {
    return this.route.snapshot.children.length > 0 ? this.childPath(this.route.snapshot.children[0]) : '';
  }

  private childPath(route: ActivatedRouteSnapshot): string {
    const path = route.url.map(x => x.path).join('/');
    const childPath = route.children.length > 0 ? this.childPath(route.children[0]) : '';
    return [
      ...(path ? [path] : []),
      ...(childPath ? [childPath] : []),
    ].join('/');
  }
}
