<div class="filter-item">
  <mat-form-field class="full-width" [style.width]="width" subscriptSizing="dynamic">
    <mat-label>{{placeholder && !isItemSelected && !isFocused ? (placeholder | translate) : (label | translate)}}</mat-label>
    <input type="text" matInput [formControl]="filterControl" [matAutocomplete]="auto"
      (change)="handleEmptyInput($event)" (focus)="isFocused = true" (blur)="isFocused = false">
    <button class="reset-btn" *ngIf="filterControl.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="onResetSelection()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayValue" panelWidth="auto" (optionSelected)="apply()"
      (closed)="onPanelClosed()">
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{item.name | translate}}
      </mat-option>
      <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
      <mat-option *ngIf="!(filteredItems | async) || (filteredItems | async).length === 0" [value]="null"
        [disabled]="true" translate>Not found</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>