import { Component, Input, OnInit } from '@angular/core';

const SOURCES_COLORS = {
  direct: '#949988',
  organic: '#92C9B1',
  paid: '#571F4E',
  email: '#5D5179',
  social: '#4F759B',
  referral: '#61A9E4'
};

@Component({
  selector: 'app-source-type',
  template: `
        <div *ngIf="type && typeColor" class="typebox" [style.background-color]="typeColor" [matTooltip]="type" matTooltipPosition="right">
            {{type[0].toUpperCase()}}
        </div>
    `,
  styles: [
    `
        .typebox {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: relative;
            display: inline-block;
            color: white;
            font-weight: bold;
            font-size: 16px;
            padding-top: 13px;
            text-align: center;
        }`
  ]
})
export class SourceTypeComponent implements OnInit {
  @Input() type = '';
  typeColor: string;

  ngOnInit() {
    if (this.type) {
      this.typeColor = SOURCES_COLORS[this.type];
    }
  }
}
