import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'app-info-tooltip',
    template: `<mat-icon [matTooltip]="tooltipText" [matTooltipPosition]="tooltipPosition">info</mat-icon>`,
    styles: [
        `mat-icon {
            color: #aaaaaa;
            font-size: 1.1em;
            height: auto;
            vertical-align: middle;
            margin-left: 8px;
        }`
    ]
})
export class InfoTooltipComponent {
    @Input() tooltipText = '';
    @Input() tooltipPosition: TooltipPosition = 'above';
}
