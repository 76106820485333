<form class="resize-container" [formGroup]="resizeForm" (ngSubmit)="onResizeSubmit()">
    <div style="margin-bottom: 15px;" translate="">Resize Image</div>
    <mat-form-field class="resize-field">
        <mat-label translate>Width</mat-label>
        <input matInput type="number" class="hide-spinner" formControlName="width" required>
    </mat-form-field>
    <mat-form-field class="resize-field">
        <mat-label translate>Height</mat-label>
        <input matInput type="number" class="hide-spinner" formControlName="height" required>
    </mat-form-field>
    <mat-checkbox formControlName="keepAspectRatio" translate>Keep Aspect Ratio</mat-checkbox>
    <div class="resize-buttons-container">
        <a class="flex-item-vertical-center" style="font-size: smaller;" href="#"
            (click)="onResetSize(); $event.preventDefault();" translate="">Reset</a>
        <div class="flex-filler"></div>
        <button type="button" class="app-secondary-rounded-btn" (click)="onCancel()" translate>Cancel</button>
        <button class="app-rounded-btn" translate>Save</button>
    </div>
</form>