import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { FiltersService } from '../filters.service';
import { KeyValuePair, KeyValueGroup } from '../../../models/models';

@Component({
  selector: 'app-drop-down-filter',
  templateUrl: './drop-down-filter.component.html',
  styleUrls: ['./drop-down-filter.component.scss']
})
export class DropDownFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder: string; // used as a label
  @Input() emptyPlaceholder: string; // used as a placeholder
  @Input() name: string;
  @Input() items: KeyValuePair[];
  @Input() groupedItems: KeyValueGroup[]; // currently used only in autocomplete
  @Input() firstAsDefault = true;
  @Input() asyncItems = true;
  @Input() onlyCompare = false;
  @Input() showClearButton = false;
  @Input() autocompleteEmptyValue: string = null;
  @Input() isAutocomplete = false;
  @Input() width: string;
  @Input() multiSelect = false;
  @Input() disabled = false;
  @Input() tooltip: string;
  value: string;
  sub: Subscription;
  autocompleteValue: string;

  @Output() filterChanged = new EventEmitter<{ onlyComapre: boolean }>();

  constructor(private filterService: FiltersService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const isItemsSet = (changes.items?.currentValue && (changes.items?.currentValue as any[]).length)
      || (changes.groupedItems?.currentValue && (changes.groupedItems?.currentValue as any[]).length);

    if (this.asyncItems && !this.value && isItemsSet) {
      if (this.firstAsDefault) {
        this.value = (changes.items.currentValue as KeyValuePair[])[0].id;
        this.filterService.setDefault(this.name, this.value);
      } else if (this.isAutocomplete && this.autocompleteEmptyValue) {
        this.autocompleteValue = this.autocompleteEmptyValue;
        this.filterService.setDefault(this.name, this.autocompleteValue);
      }
    }
  }

  ngOnInit(): void {
    this.sub = this.filterService.currentFilter.subscribe(p => {
      this.value = p[this.name];
      this.autocompleteValue = p[this.name];
      if (this.firstAsDefault) {
        if (p[this.name] === undefined && this.items && this.items.length) {
          this.value = this.items[0].id;
          this.autocompleteValue = this.items[0].id;
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  valueChanged(value: string): void {
    this.filterService.apply(this.name, value);
    this.filterChanged.emit({ onlyComapre: this.onlyCompare });
  }

  valueChangedMulti(value: string[]): void {
    this.filterService.apply(this.name, value.join(','));
    this.filterChanged.emit({ onlyComapre: this.onlyCompare });
  }

  clearValue(): void {
    this.value = undefined;
    this.valueChanged(undefined);
  }

}
