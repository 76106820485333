import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AccountContextService } from '../../services/account-context.service';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../classes/utils';


export type InclineClass = 'green' | 'red' | 'grey' | 'green-negative' | 'red-negative';
@Component({
  selector: 'app-total-statistics',
  templateUrl: './total-statistics.component.html',
  styleUrls: ['./total-statistics.component.scss']
})
export class TotalStatisticsComponent {

  @Input() icon: string;
  @Input() iconRotate: number | string;
  @Input() iconType: string;
  @Input() color: string;
  @Input() title: string;
  @Input() value: number;
  @Input() period: string;
  @Input() prvValue?: number;
  @Input() units: 'accountCurrency' | 'percents' | 'number' | 'days' | 'x' | any = 'number';
  @Input() changeDirection: 'positive' | 'negative' | 'empty' | 'auto' = 'auto';
  @Input() tooltip: string;
  @Input() info: string;
  @Input() na = false;
  @Input() linkUrl: string;
  @Input() linkText: string;


  constructor(private accountContext: AccountContextService, private translate: TranslateService) { }

  get formattedValue(): string {
    const currency = this.accountContext.accountCurrency;
    if (this.units === 'accountCurrency') {
      const format = Number(this.value).toLocaleString('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 2 });
      return format;
    }
    if (this.units === 'percents') {
      return Number(this.value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + '%';
    }
    if (this.units === 'x') {
      return Number(this.value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + 'X';
    }
    if (this.units === 'number') {
      return Number(this.value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 });
    }
    if (this.units === 'days') {
      if (Number(this.value) === 1) {
        return this.translate.instant('1day');
      }
      return this.translate.instant('daysParam', { value: this.value });
    }
    if (this.units) {
      return `${this.units} ${(this.value as any).toLocaleString('en-US')} `;
    }
    return this.value.toString();
  }

  percentClass(value: number, prvValue: number) {
    if (this.changeDirection === 'auto') {
      return (value > prvValue) ? 'up' : ((value < prvValue) ? 'down' : 'no-change');
    }
    return this.changeDirection === 'positive' ? 'up' : (this.changeDirection === 'negative' ? 'down' : 'no-change');
  }

  get changePercent(): string {
    const changePercent = Utils.getDiffPercents(this.value, this.prvValue);
    return changePercent.toString();

  }

  graphStyles(value: number): any {
    if (value !== undefined && value !== null) {
      const relVal = value * 180 / 100;
      const deg = relVal > 10 ? relVal : 10;
      return { transform: 'rotate(' + deg + 'deg) translate3d(0,0,0)' };
    }
    return { transform: 'none' };
  }
}
