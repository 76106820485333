import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Pica from 'pica';
import { numberValidator } from '../../validators/number.validator';
import { MaterialModule } from '../../submodule-material.module';

@Component({
  selector: 'app-image-resize',
  standalone: true,
  imports: [ReactiveFormsModule, MaterialModule],
  templateUrl: './image-resize.component.html',
  styleUrls: ['./image-resize.component.scss']
})
export class ImageResizeComponent implements OnInit, OnChanges {
  @Input() imageUrl: string;
  @Output() cancel = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() resize = new EventEmitter<Blob>();

  resizeForm: UntypedFormGroup;
  imageWidth = 0;
  imageHeight = 0;

  constructor() { }

  ngOnInit(): void {
    this.resizeForm = new UntypedFormGroup({
      width: new UntypedFormControl(0, [Validators.required, numberValidator, Validators.min(10)]),
      height: new UntypedFormControl(0, [Validators.required, numberValidator, Validators.min(10)]),
      keepAspectRatio: new UntypedFormControl(true)
    });

    this.widthField.valueChanges.subscribe(width => {
      if (this.keepAspectRatio) {
        this.heightField.setValue(Math.round(width * this.imageHeight / this.imageWidth), { emitEvent: false });
      }
    });
    this.heightField.valueChanges.subscribe(height => {
      if (this.keepAspectRatio) {
        this.widthField.setValue(Math.round(height * this.imageWidth / this.imageHeight), { emitEvent: false });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getImageSize();
  }

  onResetSize(): void {
    this.resizeForm.patchValue({
      width: this.imageWidth,
      height: this.imageHeight,
    }, { emitEvent: false });
  }

  onResizeSubmit() {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = this.imageUrl;

    image.onload = async () => {
      const srcCanvas = document.createElement('canvas');
      srcCanvas.width = this.imageWidth;
      srcCanvas.height = this.imageHeight;
      const ctx = srcCanvas.getContext('2d');
      ctx.drawImage(image, 0, 0);

      const destCanvas = document.createElement('canvas');
      destCanvas.width = this.widthField.value;
      destCanvas.height = this.heightField.value;

      await Pica().resize(srcCanvas, destCanvas, { alpha: true });

      const blob = await (await fetch(destCanvas.toDataURL())).blob();
      this.resize.emit(blob);
    };
  }

  onCancel(): void {
    this.cancel.emit();
  }

  private getImageSize() {
    if (this.imageUrl) {
      const img = document.createElement('img');
      img.src = this.imageUrl;
      img.onload = () => {
        this.imageWidth = img.width;
        this.imageHeight = img.height;
        this.resizeForm.setValue({
          width: img.width,
          height: img.height,
          keepAspectRatio: true
        }, { emitEvent: false });
      };
    }
  }

  get widthField(): UntypedFormControl { return this.resizeForm.get('width') as UntypedFormControl; }
  get heightField(): UntypedFormControl { return this.resizeForm.get('height') as UntypedFormControl; }
  get keepAspectRatio(): boolean { return this.resizeForm.get('keepAspectRatio').value; }
}
