import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupportedCreativeExperience } from 'src/app/modules/account/experience/experiences';
import { Status } from '../../models/models';

@Component({
  selector: 'app-pre-style-thumbnail',
  templateUrl: './pre-style-thumbnail.component.html',
  styleUrls: ['./pre-style-thumbnail.component.scss']
})
export class PreStyleThumbnailComponent {
  @Input() layout: SupportedCreativeExperience;
  @Input() status: Status = Status.Active;
  @Input() isSelected = false;
  @Input() showActions = true;
  @Input() showStatusChange = true;
  @Output() selected = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @Output() statusChange = new EventEmitter<Status>();

  Status = Status;

  onSelected(): void {
    this.selected.emit();
  }

  onEdit(): void {
    this.edit.emit();
  }

  onStatusChange(status: Status): void {
    this.statusChange.emit(status);
  }

}
