import { Injectable } from '@angular/core';
import '@auth0/auth0-spa-js';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { BehaviorSubject } from 'rxjs';
import { Auth0User, Permission } from './auth0-user';
import { environment as config } from 'src/environments/environment';
import { IAuthUser } from '../modules/shared/models/IAuthUser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated = new BehaviorSubject(false);
  profile = new BehaviorSubject<IAuthUser>(null);

  private auth0Client: Auth0Client;
  private auth0User: Auth0User = null;
  private auth0Token: string = null;
  private isAuthCallbackHandledValue = false;

  /**
   *
   */
  constructor() {
    if (config.auth0.mockUser) {
      this.isAuthenticated.next(true);
      this.auth0User = new Auth0User(config.auth0.mockUser);
      this.auth0Token = config.auth0.localToken || '12345';
      this.profile.next(this.auth0User);
    }
  }

  public async getIsAuthenticated(refresh: boolean = false): Promise<boolean> {
    await this.getAuth0Client();
    if (refresh) {
      const res = await this.auth0Client.isAuthenticated();
      if (res) {
        await this.initAuthenticatedUser();
      }
      return res;
    }
    return this.isAuthenticated.value;
  }

  public async getUser(): Promise<IAuthUser> {
    return await this.getAuth0User();
  }

  getAccessToken(): string {
    return this.auth0Token;
  }

  async login(targetUrl: string): Promise<any> {
    await this.getAuth0Client();
    await this.auth0Client.loginWithRedirect({
      authorizationParams: {
        response_type: 'web_message',
        redirect_uri: `${window.location.origin}/callback`
      },
      appState: { target: targetUrl }
    });
  }

  async logout(): Promise<void> {
    await this.auth0Client.logout({
      clientId: config.auth0.clientId,
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  }

  async handleRedirectCallback(): Promise<RedirectCallbackResponse> {
    await this.getAuth0Client();
    try {
      const result = await this.auth0Client.handleRedirectCallback();
      this.isAuthCallbackHandledValue = true;
      const target = result.appState && result.appState.target ? result.appState.target : '';
      return { target };
    }
    catch (ex) {
      console.error('handleRedirectCallback exception', ex);
      return {
        error: { error: ex.error || ex.message, error_description: ex.error_description || '' }
      };
    }
  }

  async isSysAdmin(): Promise<boolean> {
    const user = await this.getAuth0User();
    return user ? user.permissions.includes('system:admin') : false;
  }

  async isAccountAdmin(): Promise<boolean> {
    const user = await this.getAuth0User();
    return user ? user.permissions.includes('account:admin') : false;
  }

  async isAccountEditor(): Promise<boolean> {
    const user = await this.getAuth0User();
    return user ? user.permissions.includes('account:editor') : false;
  }

  async userDefaultRoute(): Promise<string> {
    if (await this.isSysAdmin()) {
      return 'admin';
    } else {
      return 'cm';
    }
  }

  private async getAuth0Client(): Promise<Auth0Client> {
    if (!this.auth0Client) {

      if (config.auth0.mockUser) {
        this.auth0Client = await Promise.resolve({
          getUser: () => config.auth0.mockUser,
          isAuthenticated: () => Promise.resolve(true),
          getTokenSilently: (opt) => Promise.resolve(config.auth0.localToken || '12345')
        } as unknown as Auth0Client);
      }
      else {
        this.auth0Client = await createAuth0Client({
          domain: config.auth0.domain,
          clientId: config.auth0.clientId,
          authorizationParams: {
            audience: config.auth0.audience,
            redirect_uri: `${window.location.origin}/callback`,
          }
        });
      }

      try {
        this.auth0Client.isAuthenticated().then(async isAuthenticated => {
          if (isAuthenticated) {
            await this.initAuthenticatedUser();
          } else {
            this.profile.next(null);
          }
        });
      } catch { }

      return this.auth0Client;
    }

    return this.auth0Client;
  }
  isPermitted(user: IAuthUser, permission: Permission): boolean {
    return user && user.rawUser && (
      user.rawUser['https://trendemon.com/claims/permissions'].includes('system:admin') ||
      user.rawUser['https://trendemon.com/claims/permissions'].includes(permission));
  }

  isAuthCallbackHandled(reset = false): boolean {
    const value = this.isAuthCallbackHandledValue;
    if (reset) {
      this.isAuthCallbackHandledValue = false;
    }
    return value;
  }

  private async getAuth0User(): Promise<Auth0User> {
    if (this.auth0User == null) {
      await this.getAuth0Client();
      await this.setAuth0User();
    }
    return this.auth0User;
  }

  private async initAuthenticatedUser(): Promise<void> {
    this.auth0Token = await this.auth0Client.getTokenSilently();
    this.setAuth0User();
    this.isAuthenticated.next(true);
  }

  private async setAuth0User(): Promise<void> {
    const auth0NativeUser = await this.auth0Client.getUser();
    this.auth0User = auth0NativeUser ? new Auth0User(auth0NativeUser) : null;
    this.profile.next(this.auth0User);
  }
}

export interface RedirectCallbackResponse {
  error?: { error: string; error_description: string };
  target?: string;
}
