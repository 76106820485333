<div class="container">
  <span style="display:none;">{{previewUrlDetector}}</span>
  <!-- top buttons -->
  <div class="platform-switch" *ngIf="!hideTopButtons">
    <mat-icon (click)="previewType = 'desktop'" matTooltip="Desktop view"
      [ngClass]="{active: previewType === 'desktop'}" matTooltipPosition="above">desktop_mac
    </mat-icon>
    <mat-icon (click)="previewType = 'mobile'" matTooltip="Mobile view" [ngClass]="{active: previewType === 'mobile'}"
      matTooltipPosition="above">phone_iphone
    </mat-icon>
    <mat-icon (click)="previewUrlStr = previewUrlStr + '&'" matTooltip="Start Over"
      matTooltipPosition="above">refresh</mat-icon>
    <a [href]="previewUrlStr" target="_blank">
      <mat-icon matTooltip="Open Preview in new tab" matTooltipPosition="above">open_in_new</mat-icon>
    </a>
    <mat-icon (click)="showPreviewForm =!showPreviewForm" matTooltip="Live Preview on your page"
      [ngClass]="{active: showPreviewForm}" matTooltipPosition="above">preview</mat-icon>
  </div>
  <!-- new tab url input -->
  <form #testForm="ngForm" *ngIf="showPreviewForm" class="full-width">
    <mat-label translate>Live preview: </mat-label>
    <mat-form-field class="full-width">
      <input matInput placeholder="Enter URL to check" name="pageUrl" [(ngModel)]="pageUrl" required url>
    </mat-form-field>
    <div class="app-add-edit-buttons">
      <button class="app-secondary-rounded-btn" (click)="showPreviewForm =!showPreviewForm" translate>Cancel</button>
      <button class="app-rounded-btn" (click)="openPreviewPage()" translate [disabled]="!testForm?.form.valid">
        See On Page</button>
    </div>
  </form>
  <!-- preview -->
  <div #previewContainer class="preview-container">
    <div class="preview-container-frame">
      <iframe *ngIf="previewUrlStr"
        [width]="getPreviewWidth(previewContainer.clientHeight, previewContainer.clientWidth)"
        [height]="getPreviewHeight(previewContainer.clientHeight, previewContainer.clientWidth)"
        [src]="previewUrlStr | safe">
      </iframe>
    </div>
  </div>
</div>