import { AccountFeature } from '../../shared/models/IAccount';
import { comparisonInputType, comparisonValueType, KeyValuePair, predicateGroupName, RuleField, ruleOperatorType, UnitStatus } from '../../shared/models/models';
import { AudienceRules } from '../insights/audience-rules-models';
import { ThemeSpec, UnitUiTypeId, UnitVisibilityType } from './models';

export const UnitTriggers = [
  {
    id: null,
    name: 'All'
  },
  {
    id: 'exit_intent',
    name: 'On Exit Intent'
  },
  {
    id: 'trd_units_ready',
    name: 'On Page Load'
  },
  {
    id: 'scroll',
    name: 'On Page Scroll'
  },
  {
    id: 'trd_page_read',
    name: 'On Page Read'
  },
  {
    id: 'trd_first_interaction',
    name: 'After First interaction'
  },
  {
    id: 'short_timeout',
    name: 'After 3 seconds'
  },
  {
    id: 'long_timeout',
    name: 'After 10 seconds'
  },
  {
    id: 'thirty_timeout',
    name: 'After 30 seconds'
  },
  {
    id: 'sixty_timeout',
    name: 'After 60 seconds'
  }
];

export const unitStatusesSelect: KeyValuePair[] = [
  { id: null, name: 'All' },
  { id: UnitStatus.Active.toString(), name: 'Active' },
  { id: UnitStatus.Scheduled.toString(), name: 'Scheduled' },
  { id: UnitStatus.Expired.toString(), name: 'Expired' },
  { id: UnitStatus.Paused.toString(), name: 'Paused' },
  { id: UnitStatus.Closed.toString(), name: 'Closed' }
];

export const UnitUiTypes: { id: string; name: string; feature?: AccountFeature }[] = [
  { id: null, name: 'All' },
  { id: UnitUiTypeId.Basic.toString(), name: 'Promotion' },
  { id: UnitUiTypeId.Form.toString(), name: 'Form' },
  { id: UnitUiTypeId.Survey.toString(), name: 'Survey', feature: 'survey' },
  { id: UnitUiTypeId.Recommend.toString(), name: 'Content Recommendations' },
  { id: UnitUiTypeId.Hub.toString(), name: 'Content Hub', feature: 'contentHub' },
  { id: UnitUiTypeId.Script.toString(), name: 'Remarketing', feature: 'pixelBasedRetargeting' },
  { id: UnitUiTypeId.Fastext.toString(), name: 'Fastext' },
];

export const UnitVisibilities: { id: UnitVisibilityType; name: string }[] = [
  { id: 'light', name: 'Lightbox' },
  { id: 'slider', name: 'Slider' },
  { id: 'fixed', name: 'Powerbar' },
  { id: 'embedded', name: 'Embedded' },
  { id: 'full', name: 'Full Screen Popup' },
];

export const UnitVisibilitiesFilter: { id: string; name: string }[] =
[{ id: null, name: 'All'}, ...UnitVisibilities];

export const pageRulesFields: RuleField[] = [
  {
    name: 'Page Tags',
    predicate: 'PageContext.Tags',
    valueType: comparisonValueType.tags_collection,
    valuePlaceholder: 'tags',
    isDaysPredicate: false,
    fieldType: comparisonInputType.autocomplete,
    operatorType: ruleOperatorType.ruleCollectionOperators,
    group: predicateGroupName.url,
    description: 'Show unit only on pages with these page tags'
  },
  {
    name: 'Area',
    predicate: 'PageContext.AreaId',
    valueType: comparisonValueType.areas_collection,
    valuePlaceholder: 'Area',
    isDaysPredicate: false,
    fieldType: comparisonInputType.autocomplete,
    operatorType: ruleOperatorType.ruleStringFromListOperators,
    group: predicateGroupName.url,
    description: 'Show unit only on pages in these areas'
  },
  {
    name: 'Domain',
    predicate: 'PageContext.Domain',
    valueType: comparisonValueType.domains_collection,
    valuePlaceholder: 'Domain',
    isDaysPredicate: false,
    fieldType: comparisonInputType.multiselect,
    operatorType: ruleOperatorType.ruleStringFromListOperators,
    group: predicateGroupName.url,
    description: 'Show unit only on pages under these domains  i.e "mysite.com"'
  },
  {
    name: 'Url',
    predicate: 'PageContext.Url',
    valueType: comparisonValueType.string,
    valuePlaceholder: 'page url',
    isDaysPredicate: false,
    fieldType: comparisonInputType.url,
    operatorType: ruleOperatorType.ruleStringOperators,
    group: predicateGroupName.url,
    description: 'Show unit only on these URLs, i.e "https://mysite.com/homepage.html?someparam=1"'
  },
  {
    name: 'Url Path',
    predicate: 'PageContext.Path',
    valueType: comparisonValueType.string,
    valuePlaceholder: 'page url',
    isDaysPredicate: false,
    fieldType: comparisonInputType.textField,
    operatorType: ruleOperatorType.ruleStringOperatorsFull,
    group: predicateGroupName.url,
    description: 'Url Path i.e "homepage.html"'
  },
  {
    name: 'Is Home Page',
    predicate: 'PageContext.IsHomePage',
    valueType: comparisonValueType.boolean,
    valuePlaceholder: '',
    isDaysPredicate: false,
    fieldType: comparisonInputType.hidden,
    operatorType: ruleOperatorType.ruleBooleanOperators,
    group: predicateGroupName.url,
    description: 'Urls like "https://www.mydomain.com" or "https://mydomain.com"'
  }
];

export const DeviceRulesFields = [
  {
    name: 'Device Platform',
    predicate: 'Platform',
    valueType: comparisonValueType.platforms_collection,
    valuePlaceholder: 'choose the device type',
    isDaysPredicate: false,
    fieldType: comparisonInputType.select,
    operatorType: ruleOperatorType.ruleSelectOneOperators,
    group: predicateGroupName.device,
    description: 'Desktop or Mobile'
  },
  {
    name: 'Device',
    predicate: 'VisitorDevice',
    valueType: comparisonValueType.devices_collection,
    valuePlaceholder: 'choose the device name',
    isDaysPredicate: false,
    fieldType: comparisonInputType.multiselect,
    operatorType: ruleOperatorType.ruleStringFromListOperators,
    group: predicateGroupName.device,
    description: ''
  },
  {
    name: 'User Agent',
    predicate: 'BrowserUserAgent',
    valueType: comparisonValueType.string,
    valuePlaceholder: 'specify User-Agent',
    isDaysPredicate: false,
    fieldType: comparisonInputType.textField,
    operatorType: ruleOperatorType.ruleStringOperators,
    group: predicateGroupName.device,
    description: ''
  }
];

export const IntegrationRulesFields = [
  {
    name: 'Identified',
    predicate: 'Identified',
    valueType: comparisonValueType.boolean,
    isDaysPredicate: false,
    fieldType: comparisonInputType.hidden,
    operatorType: ruleOperatorType.ruleBooleanOperators,
    group: predicateGroupName.maGeneral,
    description: 'Is visitor identified by Marketing Automation'
  },
  {
    name: 'Lead Lists',
    predicate: 'LeadLists',
    valueType: comparisonValueType.malists_collection,
    valuePlaceholder: 'select list',
    isDaysPredicate: false,
    fieldType: comparisonInputType.autocomplete,
    operatorType: ruleOperatorType.ruleCollectionOperators,
    group: predicateGroupName.maGeneral,
    description: ''
  }
];

export const UnitsAudienceRules = [
  {
    name: 'Is a New Visitor',
    predicate: 'NewVisitor',
    valueType: comparisonValueType.boolean,
    isDaysPredicate: false,
    fieldType: comparisonInputType.hidden,
    operatorType: ruleOperatorType.ruleBooleanOperators,
    group: predicateGroupName.activity,
    description: ''
  },
  {
    name: 'Pages in the Current Session',
    predicate: 'SessionPageviewsCount',
    valueType: comparisonValueType.int,
    valuePlaceholder: 'pageviews count',
    isDaysPredicate: false,
    fieldType: comparisonInputType.number,
    operatorType: ruleOperatorType.ruleIntOperators,
    group: predicateGroupName.activity,
    description: 'The number of pages viewed by visitor in the current session'
  }
];

export const SFOpportunityFields = [
  {
    name: 'SF Opportunity Stage',
    predicate: 'CrmDealStagesInLast{days}Days',
    valueType: comparisonValueType.sf_opportunity_stages_collection,
    valuePlaceholder: 'stage',
    isDaysPredicate: true,
    fieldType: comparisonInputType.autocomplete,
    operatorType: ruleOperatorType.ruleCollectionOperatorContainsAny,
    group: predicateGroupName.salesforce,
    description: 'Visitors from accounts associated with opportunities at a specific stage'
  }
];

export const driftEvents = [
  { name: 'Conversation started', id: 'startConversation' },
  { name: 'Phone captured', id: 'phoneCapture' },
  { name: 'Playbook clicked', id: 'playbookClicked' },
  { name: 'Meeting booked', id: 'meetingBooked' },
  { name: 'Email captured', id: 'emailCapture' }
];

export const VisitorRulesFields: RuleField[] = [...AudienceRules.filter(r => r.predicate !== 'PagesVisited'), ...UnitsAudienceRules];

export const deviceTypes = [
  { name: 'Desktop', id: 'Desktop' },
  { name: 'Mobile', id: 'Mobile' }
];

export const deviceNames = [
  { name: 'Android', id: 'Android' },
  { name: 'IPhone', id: 'IPhone' },
  { name: 'IPad', id: 'IPad' },
  { name: 'Windows', id: 'Windows' },
  { name: 'Mac', id: 'Mac' },
  { name: 'Other', id: 'Other' }
];

export const predefinedThemes: ThemeSpec[] = [
  {
    name: 'purple',
    backgroundColor: '#ffffff',
    textColor: '#7348fe',
    buttonBackgroundColor: '#7348fe',
    buttonTextColor: '#efeaff',
  },
  {
    name: 'light',
    backgroundColor: '#e0e0e0',
    textColor: '#424242',
    buttonBackgroundColor: '#757575',
    buttonTextColor: '#fafafa'
  },
  {
    name: 'rich',
    backgroundColor: 'linear-gradient(red, yellow)',
    textColor: '#fff',
    buttonBackgroundColor: '#fff',
    buttonTextColor: '#ff6376'
  },
  {
    name: 'vivid',
    backgroundColor: '#43bccc',
    textColor: '#fff',
    buttonBackgroundColor: '#ff6376',
    buttonTextColor: '#fff'
  },
  {
    name: 'trendemon',
    backgroundColor: '#efefef',
    textColor: '#EE2F66',
    buttonBackgroundColor: '#262b33',
    buttonTextColor: '#EE2F66'
  },
  {
    name: 'dark',
    backgroundColor: '#424242',
    textColor: '#e0e0e0',
    buttonBackgroundColor: '#212121',
    buttonTextColor: '#fafafa'
  },
  {
    name: 'Prestyled Subscribe',
    backgroundColor: '#fff',
    textColor: '#585e62',
    buttonBackgroundColor: '#f7ad00',
    buttonTextColor: '#fff'
  },
  {
    name: 'Prestyled Download',
    backgroundColor: '#fff',
    textColor: '#25284E',
    buttonBackgroundColor: '#25284E',
    buttonTextColor: '#fff'
  },
  {
    name: 'Prestyled Survey',
    backgroundColor: '#fff',
    textColor: '#634e89',
    buttonBackgroundColor: '#634e89',
    buttonTextColor: '#fff'
  }
];
