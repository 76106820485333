import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tiny-loader',
  standalone: true,
  template: `
  <div class="loader">
    <div class="dot dot1"></div>
    <div class="dot dot2"></div>
    <div class="dot dot3"></div>
    <div class="dot dot4"></div>
  </div>
  `,
  styles: [
    `
       /* change font size to change size */
      .loader {
        font-size: 20px;
        position: relative;
        width: 1em;
        height: 0.25em;
        margin: 0 auto;
      }

      .dot {
        width: 0.25em;
        height: 0.25em;
        border-radius: 0.5em;
        background: #e0e0e0;
        position: absolute;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }

      .dot1, .dot2 {
        left: 0;
      }

      .dot3 { left: 0.37em; }

      .dot4 { left: 0.75em; }

      @keyframes reveal {
        from { transform: scale(0.001); }
        to { transform: scale(1); }
      }

      @keyframes slide {
        to { transform: translateX(0.37em) }
      }


      .dot1 {
        animation-name: reveal;
      }

      .dot2, .dot3 {
        animation-name: slide;
      }

      .dot4 {
        animation-name: reveal;
        animation-direction: reverse; /* thx @HugoGiraudel */
      }
        `
  ]
})
export class TinyLoaderComponent {
  @Input() height = '60px';
}
