<div [formGroup]="form">
    <ng-container formArrayName="questionsArray">
        <ng-container *ngFor="let _ of questionsArray.controls; index as q">

            <div [formGroupName]="q">
                <!-- question line -->
                <div class="question-line">
                    <div class="question-num">{{(q+1)}}.</div>
                    <mat-form-field class="flex-filler">
                        <mat-label translate>Question</mat-label>
                        <input matInput [formControlName]="'question'">
                    </mat-form-field>
                    <mat-icon class="app-rule-engine-action-icon arrow" *ngIf="(q + 1) < questionsArray.length"
                        [matTooltip]="'Move question down' | translate" matTooltipPosition="above"
                        (click)="onQuestionDown(q)">south
                    </mat-icon>
                    <mat-icon class="app-rule-engine-action-icon arrow" *ngIf="q > 0"
                        [matTooltip]="'Move question up' | translate" matTooltipPosition="above"
                        (click)="onQuestionUp(q)">north
                    </mat-icon>
                    <mat-icon class="app-rule-engine-action-icon delete" *ngIf="questionsArray.length > 1"
                        [matTooltip]="'Remove the question' | translate" matTooltipPosition="above"
                        (click)="onDeleteQuestion(q)">remove_circle</mat-icon>
                </div>

                <!-- answers -->
                <div class="answers-container" formArrayName="answers">
                    <div class="answers-title" translate>Answers</div>

                    <div class="answer-line" *ngFor="let _ of answersArray(q).controls; index as a">
                        <mat-form-field class="flex-filler" subscriptSizing="dynamic" [hideRequiredMarker]="true">
                            <mat-label></mat-label>
                            <input matInput [formControlName]="a">
                        </mat-form-field>
                        <mat-icon class="app-rule-engine-action-icon"
                            [style.visibility]="answersArray(q).length === 1 ? 'hidden' : 'visible'"
                            [matTooltip]="'Remove the answer' | translate" matTooltipPosition="above"
                            (click)="onDeleteAnswer(q, a)">remove_circle</mat-icon>
                    </div>

                    <div class="answer-actions" *ngIf="answersArray(q).length < 10">
                        <a class="app-rule-engine-action" (click)="onAddAnswer(q)" href="javascript:void(0);">
                            + {{'Add an Answer' | translate}}</a>
                    </div>
                </div>
            </div>

        </ng-container>
    </ng-container>

    <div class="question-actions" *ngIf="questionsArray.length < 10">
        <a class="app-rule-engine-action" (click)="onAddQuestion()" href="javascript:void(0);" translate>
            + {{'Add a Question' | translate}}</a>
    </div>

</div>