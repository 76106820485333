<div class="filter-item {{'filter-name-' + name}}">
  <ng-container *ngIf="!multiSelect">
    <!-- autocomplete single select -->
    <app-autocomplete *ngIf="isAutocomplete" [width]="width" heightSizing="dynamic" [label]="placeholder |translate"
      [placeholder]="emptyPlaceholder" [itemsList]="items" [groupedItems]="groupedItems"
      [emptyValueAllowed]="showClearButton" [emptyValue]="autocompleteEmptyValue" [disabled]="disabled"
      [(ngModel)]="autocompleteValue" (ngModelChange)="valueChanged($event)" [matTooltip]="tooltip">
    </app-autocomplete>
    <!-- drop-down single select -->
    <mat-form-field *ngIf="!isAutocomplete" class="full-width" subscriptSizing="dynamic">
      <mat-label>{{placeholder ? (placeholder | translate) : ''}}</mat-label>
      <mat-select [(value)]="value" (selectionChange)="valueChanged(value)">
        <mat-option *ngFor="let item of items" [value]="item.id" class="{{'filter-' + name}} {{'option-' + item.id}}">
          {{item.name | translate}}
        </mat-option>
      </mat-select>
      <button matSuffix mat-icon-button class="reset-btn" *ngIf="showClearButton && value" aria-label="Clear"
        (click)="clearValue(); $event.stopPropagation()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>
  <!-- autocomplete multi select -->
  <ng-container *ngIf="multiSelect">
    <mat-form-field class="filter-multiselect" [style.width]="width" subscriptSizing="dynamic">
      <app-multiselect-autocomplete [itemsList]="items" [placeholder]="placeholder | translate"
        [(ngModel)]="autocompleteValue" class="multiselect-autocomplete" (ngModelChange)="valueChangedMulti($event)">
      </app-multiselect-autocomplete>
    </mat-form-field>
  </ng-container>
</div>