import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RuleField, RulesConfig, Rule, RulesGroup, comparisonValueType, comparisonInputType, ruleOperatorType, predicateGroups, RuleOptionsConfig, RulesOptions, predicateGroupName } from '../../models/models';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray, AbstractControl } from '@angular/forms';
import * as _ from 'lodash-es';
import { DateFormatValidator } from '../../directives/date-format-validator.directive';
import { debounceTime } from 'rxjs/operators';
import { AccountContextService } from '../../services/account-context.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SettingsService } from 'src/app/modules/account/settings/account-settings.service';
import { numberValidator } from '../../validators/number.validator';
import { emailValidator } from '../../validators/email.validator';
import { urlValidator } from '../../validators/url.validator';
import { lastValueFrom } from 'rxjs';
import { stripeDomainValidator } from '../../validators/strip-domain.validator';

export interface RuleSavedEvent {
  isValid: boolean;
  rulesObject: RulesConfig;
}

@Component({
  selector: 'app-rule-engine-generator',
  templateUrl: './rule-engine-generator.component.html',
  styleUrls: ['./rule-engine-generator.component.scss']
})
export class RuleEngineGeneratorComponent implements OnInit {

  constructor(private accountContext: AccountContextService, private settingsService: SettingsService) { }
  @Input() rulesOptions: RulesOptions;
  @Input() rulesData: RulesConfig;
  @Input() ruleFields: RuleField[];
  @Input() autocompleteFieldName = false;
  @Input() hideLoader = false;
  usedRuleFields: RuleField[];

  config: RuleOptionsConfig = {
    singleGroup: false,
    fixedRules: false,
    rulesRequired: false,
    hideGroupsOperatorToggle: false
  };

  ruleCollectionOperators = [
    { name: 'CollectionContainsAnyOf', label: 'Contains Any' },
    { name: 'CollectionNotContainsAnyOf', label: 'Not Contains Any' },
    { name: 'CollectionContainsAll', label: 'Contains All' }
  ];

  ruleCollectionOperatorsInclElementContains = [
    { name: 'CollectionContainsAnyOf', label: 'Contains Any' },
    { name: 'CollectionNotContainsAnyOf', label: 'Not Contains Any' },
    { name: 'CollectionContainsAll', label: 'Contains All' },
    { name: 'CollectionElementContains', label: 'Contains Text' }
  ];

  ruleCollectionPositive = [
    { name: 'CollectionElementContains', label: 'Contains' },
    { name: 'CollectionElementStartsWith', label: 'Starts With' },
    { name: 'CollectionElementEndsWith', label: 'Ends With' }
  ];

  ruleCollectionOperatorsReduced = [
    { name: 'CollectionContainsAnyOf', label: 'Contains Any' },
    { name: 'CollectionContainsAll', label: 'Contains All' }
  ];

  ruleCollectionOperatorContainsAny = [
    { name: 'CollectionContainsAnyOf', label: 'Contains Any' }
  ];

  ruleIntGreaterThanOperators = [
    { name: 'GreaterThan', label: 'Greater Than' },
    { name: 'GreaterThanOrEqual', label: 'Greater Than Or Equal' },
  ];

  ruleIntOperators = [
    { name: 'Equal', label: 'Equal' },
    { name: 'NotEqual', label: 'Not Equal' },
    { name: 'GreaterThan', label: 'Greater Than' },
    { name: 'GreaterThanOrEqual', label: 'Greater Than Or Equal' },
    { name: 'LessThan', label: 'Less Than' },
    { name: 'LessThanOrEqual', label: 'Less Than Or Equal' }
  ];

  ruleStringOperatorsForQuery = [
    { name: 'StringEqualsCaseInsensitive', label: 'Equal' },
    { name: 'StringNotEqualsCaseInsensitive', label: 'Not Equal' },
    { name: 'StringStartsWith', label: 'Starts with' },
    { name: 'StringEndsWith', label: 'Ends with' },
    { name: 'StringContains', label: 'Contains' },
    { name: 'StringNotContains', label: 'Not Contains' }
  ];

  ruleStringOperators = [
    { name: 'StringEqualsCaseInsensitive', label: 'Equal' },
    { name: 'StringNotEqualsCaseInsensitive', label: 'Not Equal' },
    { name: 'StringStartsWith', label: 'Starts with' },
    { name: 'StringEndsWith', label: 'Ends with' },
    { name: 'StringContains', label: 'Contains' },
    { name: 'StringNotContains', label: 'Not Contains' },
    { name: 'StringMatchesRegex', label: 'Matches Regular expression' }
  ];
  ruleStringOperatorsFull = [
    ...this.ruleStringOperators,
    { name: 'StringNullOrEmpty', label: 'Is Empty' },
    { name: 'StringNotNullOrEmpty', label: 'Not Empty' },
  ];

  ruleStringOperatorsReduced = [
    { name: 'StringEqualsCaseInsensitive', label: 'Equal' },
    { name: 'StringStartsWith', label: 'Starts with' },
    { name: 'StringEndsWith', label: 'Ends with' },
    { name: 'StringContains', label: 'Contains' },
    { name: 'StringMatchesRegex', label: 'Matches Regular expression' }
  ];

  ruleStringOperatorsReducedSimple = [
    { name: 'StringEqualsCaseInsensitive', label: 'Equal' },
    { name: 'StringStartsWith', label: 'Starts with' },
    { name: 'StringEndsWith', label: 'Ends with' },
    { name: 'StringContains', label: 'Contains' },
  ];

  ruleIntOperatorsFull = [
    ...this.ruleIntOperators,
    { name: 'StringNullOrEmpty', label: 'Is Empty' },
    { name: 'StringNotNullOrEmpty', label: 'Not Empty' },
  ];
  ruleStringFromListOperators = [
    { name: 'In', label: 'Includes' },
    { name: 'NotIn', label: 'Does not include' }
  ];

  ruleStringFromListOperatorsInclude = [
    { name: 'In', label: 'Includes' }
  ];

  ruleSelectOneOperators = [
    { name: 'Equal', label: 'Equal' },
    { name: 'NotEqual', label: 'Not Equal' },
  ];

  ruleBooleanOperators = [
    { name: 'IsTrue', label: 'True' },
    { name: 'IsFalse', label: 'False' }
  ];

  daysPredicateOptions = [
    { name: 7, label: 'in the last 7 days' },
    { name: 30, label: 'in the last 30 days' },
    { name: 90, label: 'in the last 90 days' }
  ];

  sfDaysPredicateOptions = [
    { name: 90, label: 'and updated within the past 90 days' },
    { name: 365, label: 'and updated within the past year' },
    { name: 0, label: 'and updated at any time' }
  ];

  operators = new Map();
  rules: UntypedFormGroup;
  comparisonInputType = comparisonInputType;
  comparisonValueType = comparisonValueType;
  ruleFieldGrouped: { group; fields: RuleField[]; hidden: boolean }[];
  predicateGroupsDef = predicateGroups;
  @Output() saved = new EventEmitter<RuleSavedEvent>();
  predicateGroupName = predicateGroupName;
  readonly chipsSeparatorKeysCodes: number[] = [ENTER, COMMA];

  async ngOnInit() {
    // filter rules by feature
    await this.accountContext.waitForInit();
    this.usedRuleFields = this.ruleFields.filter(r => !r.feature || this.accountContext.supports(r.feature));

    // filter rules by client app
    const accountFeatures = await lastValueFrom(this.settingsService.getAccountFeatures());
    const clientApps = accountFeatures.clientApps ?? [];
    this.usedRuleFields = this.usedRuleFields.filter(r => !r.clientApp || clientApps.includes(r.clientApp));
    this.groupRulesFields(this.usedRuleFields);

    this.operators.set(ruleOperatorType.ruleIntGreaterThanOperators, this.ruleIntGreaterThanOperators);
    this.operators.set(ruleOperatorType.ruleIntOperators, this.ruleIntOperators);
    this.operators.set(ruleOperatorType.ruleIntOperatorsFull, this.ruleIntOperatorsFull);
    this.operators.set(ruleOperatorType.ruleStringOperators, this.ruleStringOperators);
    this.operators.set(ruleOperatorType.ruleStringOperatorsFull, this.ruleStringOperatorsFull);
    this.operators.set(ruleOperatorType.ruleBooleanOperators, this.ruleBooleanOperators);
    this.operators.set(ruleOperatorType.ruleCollectionOperators, this.ruleCollectionOperators);
    this.operators.set(ruleOperatorType.ruleStringFromListOperators, this.ruleStringFromListOperators);
    this.operators.set(ruleOperatorType.ruleSelectOneOperators, this.ruleSelectOneOperators);
    this.operators.set(ruleOperatorType.ruleCollectionOperatorsReduced, this.ruleCollectionOperatorsReduced);
    this.operators.set(ruleOperatorType.ruleCollectionOperatorContainsAny, this.ruleCollectionOperatorContainsAny);
    this.operators.set(ruleOperatorType.ruleStringFromListOperatorsInclude, this.ruleStringFromListOperatorsInclude);
    this.operators.set(ruleOperatorType.ruleCollectionOperatorsInclElementContains, this.ruleCollectionOperatorsInclElementContains);
    this.operators.set(ruleOperatorType.ruleStringOperatorsForQuery, this.ruleStringOperatorsForQuery);
    this.operators.set(ruleOperatorType.ruleStringOperatorsReduced, this.ruleStringOperatorsReduced);
    this.operators.set(ruleOperatorType.ruleCollectionPositive, this.ruleCollectionPositive);

    this.rules = new UntypedFormGroup({
      rulesOperator: new UntypedFormControl(this.rulesData?.rulesOperator || 'Or'),
      rulesGroups: new UntypedFormArray([])
    });

    if (this.rulesData) {
      this.loadData();
    } else {
      this.addRulesGroup();
    }

    if (this.rulesOptions?.config) {
      this.config = { ...this.config, ...this.rulesOptions.config };
    }

    this.buildRulesAndSend(this.rules);

    this.rules.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.buildRulesAndSend(this.rules);
      });
  }

  loadData() {
    let index = 0;
    const data = this.rulesData;


    data.rulesGroups.forEach(group => {
      this.addRulesGroup(group);
      group.rules.forEach(line => {
        if (line.comparisonPredicate) {
          if (line.comparisonPredicate.includes('Days')) {
            line.daysPredicate = +line.comparisonPredicate.replace(/[a-zA-Z]/g, '');
            line.comparisonPredicate = line.comparisonPredicate.replace(/[0-9]/, '{days}').replace(/[0-9]/g, '');
          }
          if (this.isArrayInputType(this.getInputType(line.comparisonPredicate))) {
            line.comparisonArray = line.comparisonValue.split('|').map(v => this.decodeArrayValue(v));
          }
          this.addRuleLine(index, line);
        } else {
          this.addRuleLine(index);
        }
      });
      index++;
    });
  }

  initRulesGroup(group?: RulesGroup) {
    return new UntypedFormGroup({
      rulesOperator: new UntypedFormControl(group?.rulesOperator || 'Or'),
      rules: new UntypedFormArray([])
    });
  }
  initRuleLine(rule?: Rule) {
    const line = new UntypedFormGroup({
      comparisonPredicate: new UntypedFormControl(rule?.comparisonPredicate),
      comparisonOperator: new UntypedFormControl(rule?.comparisonOperator),
      comparisonValue: new UntypedFormControl(rule?.comparisonValue || ''),
      comparisonArray: new UntypedFormControl(rule?.comparisonArray || []),
      daysPredicate: new UntypedFormControl(rule?.daysPredicate || 90)
    });

    if (rule) {
      this.setValidators(line, this.getInputType(rule.comparisonPredicate));
      this.setValidatorsBasedOnOperator(line, rule.comparisonOperator);
    }

    return line;
  }

  addRuleLine(j, rule?: Rule) {
    const group = this.rules.get('rulesGroups') as UntypedFormArray;
    const control = group.controls[j].get('rules') as UntypedFormArray;
    const newLine = this.initRuleLine(rule);
    control.push(newLine);
    return false;
  }

  addRulesGroup(group?: RulesGroup) {
    const control = this.rules.get('rulesGroups') as UntypedFormArray;
    control.push(this.initRulesGroup(group));
    if (!group) {
      this.addRuleLine(control.length - 1);
    }
    return false;
  }

  removeRuleLine(groupIndex: number, lineIndex: number) {
    const group = this.rules.get('rulesGroups') as UntypedFormArray;
    const control = group.controls[groupIndex].get('rules') as UntypedFormArray;
    control.removeAt(lineIndex);
  }

  getGroupOperator(groupIndex: number): string {
    const group = this.rules.get('rulesGroups') as UntypedFormArray;
    return group.controls[groupIndex].get('rulesOperator').value;
  }

  getGeneralOperator(): string {
    return this.rules.get('rulesOperator').value;
  }

  removeRulesGroup(index: number) {
    const control = this.rules.get('rulesGroups') as UntypedFormArray;
    control.removeAt(index);
    return false;
  }
  getRulesGroups(form: UntypedFormGroup) {
    return (form.controls.rulesGroups as UntypedFormArray).controls;
  }
  getRuleLines(form: UntypedFormGroup) {
    return (form.controls.rules as UntypedFormGroup).controls;
  }
  resetLineDataOnChange(value, line) {
    const options = this.getOptions(value);
    line.patchValue({
      comparisonOperator: options[0].name,
      comparisonValue: '',
      comparisonArray: []
    });
    this.setValidators(line, this.getInputType(value));
    this.setValidatorsBasedOnOperator(line, options[0].name);
  }

  onOperatorChange(value, line) {
    let inputType = this.getInputType(line.get('comparisonPredicate').value);

    if (line.get('comparisonOperator').value === 'CollectionElementContains') {
      inputType = comparisonInputType.textField;
      line.patchValue({
        comparisonValue: '',
        comparisonArray: []
      });
    }
    this.setValidators(line, inputType);
    this.setValidatorsBasedOnOperator(line, value);
  }
  private setValidators(line: any, inputType: comparisonInputType) {
    line.get('comparisonOperator').setValidators([Validators.required]);

    switch (inputType) {
      case comparisonInputType.number:
        line.get('comparisonValue').setValidators([Validators.required, numberValidator]);
        line.get('comparisonArray').clearValidators();
        break;
      case comparisonInputType.dateTime:
        line.get('comparisonValue').setValidators([Validators.required, DateFormatValidator()]);
        line.get('comparisonArray').clearValidators();
        break;
      case comparisonInputType.multiselect:
      case comparisonInputType.autocomplete:
      case comparisonInputType.chips:
      case comparisonInputType.freeTextAutocomplete:
        line.get('comparisonArray').setValidators([Validators.required]);
        line.get('comparisonValue').clearValidators();
        break;
      case comparisonInputType.hidden:
        line.get('comparisonArray').clearValidators();
        line.get('comparisonValue').clearValidators();
        break;
      default:
        line.get('comparisonValue').setValidators([Validators.required]);
        line.get('comparisonArray').clearValidators();
        break;
    }
    line.get('comparisonValue').updateValueAndValidity();
    line.get('comparisonArray').updateValueAndValidity();
  }

  private setValidatorsBasedOnOperator(line: any, comparisonOperatorName: string) {
    const inputType = this.getInputType(line.get('comparisonPredicate').value);
    if (comparisonOperatorName === 'StringNullOrEmpty' || comparisonOperatorName === 'StringNotNullOrEmpty') {
      line.get('comparisonValue').clearValidators();
    }
    if (comparisonOperatorName === 'StringEqualsCaseInsensitive' || comparisonOperatorName === 'StringNotEqualsCaseInsensitive') {
      if (inputType === comparisonInputType.email) {
        line.get('comparisonValue').setValidators([Validators.required, emailValidator]);
      }
      if (inputType === comparisonInputType.url) {
        line.get('comparisonValue').setValidators([Validators.required, urlValidator, stripeDomainValidator]);
      }
    } else {
      if (inputType === comparisonInputType.email) {
        line.get('comparisonValue').setValidators([Validators.required]);
      }
      if (inputType === comparisonInputType.url) {
        line.get('comparisonValue').setValidators([Validators.required, stripeDomainValidator]);
      }
    }
    line.get('comparisonValue').updateValueAndValidity();
  }

  getOptions(value: string): any {
    const el = this.usedRuleFields.find(element => element.predicate === value);
    let operatorType = ruleOperatorType.ruleCollectionOperators;
    if (el) {
      operatorType = el.operatorType;
    }
    if (this.operators.get(operatorType) !== undefined) {
      return this.operators.get(operatorType);
    }
    return this.operators.get(comparisonValueType.collection);
  }

  getPlaceholder(value): string {
    return this.usedRuleFields.find(e => e.predicate === value)?.valuePlaceholder || 'Choose Value';
  }

  ifDaysPredicate(value): any {
    if (value) {
      const el = this.usedRuleFields.find(element => element.predicate === value);
      return el.isDaysPredicate;
    }
    return false;
  }

  ifSalesforceField(value): any {
    if (value) {
      const el = this.usedRuleFields.find(element => element.predicate === value);
      if (el.group === predicateGroupName.salesforce) {
        return true;
      }
    }
    return false;
  }

  buildRulesAndSend(form: UntypedFormGroup) {
    const viewModelRuleConfig: RulesConfig = form.getRawValue();
    if (viewModelRuleConfig.rulesGroups.length === 1
      && viewModelRuleConfig.rulesGroups[0].rules.length === 1
      && viewModelRuleConfig.rulesGroups[0].rules[0].comparisonPredicate === null) {
      return null;
    }

    viewModelRuleConfig.rulesGroups.forEach(ruleGroup => {
      ruleGroup.rules = ruleGroup.rules.filter(r => r.comparisonOperator?.length && r.comparisonPredicate?.length);
    });

    const validRuleGroups = viewModelRuleConfig.rulesGroups.filter(gr => gr.rules?.length);

    if (validRuleGroups.length === 0) { return null; }

    const validObj: RulesConfig = {
      rulesOperator: viewModelRuleConfig.rulesOperator,
      rulesGroups: validRuleGroups
    } as RulesConfig;

    this.disableEnableFieldsGroup(predicateGroupName.salesforce, false);

    validObj.rulesGroups.forEach((group) => {
      group.rules.forEach(line => {
        if (line.comparisonPredicate === 'PageContext.Url' || line.comparisonPredicate === 'PageContext.Path') {
          line.comparisonValue = line.comparisonValue.trim();
        }
        let inputType = this.getInputType(line.comparisonPredicate);
        if (line.comparisonOperator === 'CollectionElementContains') {
          inputType = comparisonInputType.textField;
        }
        if (this.isArrayInputType(inputType)) {
          line.comparisonValue = line.comparisonArray.map(v => this.encodeArrayValue(v)).join('|');
        }
        if (line.comparisonPredicate) {
          // we can configure SF field only once per Audience
          if (line.comparisonPredicate.includes('CrmDealStages')) {
            this.disableEnableFieldsGroup(predicateGroupName.salesforce, true);
          }
          if (line.comparisonPredicate.includes('{days}')) {
            line.comparisonPredicate = line.comparisonPredicate.replace('{days}', line.daysPredicate.toString());
          }
          switch (this.getInputType(line.comparisonPredicate)) {
            case comparisonInputType.number: {
              line.predicateType = 'Double';
              break;
            }
            case comparisonInputType.dateTime: {
              line.predicateType = 'DateTime';
              break;
            }
          }
        }
        delete line.daysPredicate;
        delete line.comparisonArray;
      });
    });
    this.saved.emit({ isValid: form.valid, rulesObject: validObj });
  }

  getInputType(value): comparisonInputType {
    const el = this.usedRuleFields.find(element => element.predicate === value);
    let inputType = comparisonInputType.number;
    if (el && el.fieldType !== undefined) {
      inputType = el.fieldType;
    }
    return inputType;
  }

  getPredicateName(predicate: string) {
    return this.usedRuleFields.find(f => f.predicate === predicate)?.name;
  }

  getPredicateValuesGroupedOrNot(predicate: string) {
    return this.usedRuleFields.find(f => f.predicate === predicate)?.valuesGrouped;
  }

  getCollection(value: string): any {
    const field = this.usedRuleFields.find(f => f.predicate === value);
    return field.availableValues ?? this.rulesOptions.data[field.valueType];
  }

  getCollectionValues(value): any {
    return this.getCollection(value).map(v => typeof v === 'object' ? v.name : v);
  }

  printProperty(object, prop): string {
    if (typeof object === 'string' || object instanceof String) {
      return object.toString();
    }
    return object[prop].toString();
  }

  groupRulesFields(usedRuleFields: RuleField[]) {
    const groupToValues = usedRuleFields.reduce((obj, item) => {
      let isValid = true;
      if ((this.getCollection(item.predicate) === undefined || this.getCollection(item.predicate).length === 0)
        && (item.fieldType === comparisonInputType.multiselect || item.fieldType === comparisonInputType.autocomplete)) {
        isValid = false;
      }
      if (isValid) {
        obj[item.group] = obj[item.group] || [];
        obj[item.group].push(item);
      }
      return obj;
    }, {});

    this.ruleFieldGrouped = Object.keys(groupToValues).map((key) =>
      ({ group: key, fields: groupToValues[key], hidden: false })
    );
  }

  predicateGroupNameByValue(name: string): string {
    const group = this.predicateGroupsDef.find(e => +e.name === +name);
    if (group.showTitle) {
      return this.predicateGroupsDef.find(e => +e.name === +name).label;
    }
    return '';
  }

  resetAllRules() {
    this.rules = new UntypedFormGroup({
      rulesOperator: new UntypedFormControl(this.rulesData?.rulesOperator || 'Or'),
      rulesGroups: new UntypedFormArray([])
    });
    this.addRulesGroup();
    this.saved.emit({ isValid: !this.config.rulesRequired, rulesObject: null });
    this.rules.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.buildRulesAndSend(this.rules));
    return false;
  }

  disableEnableFieldsGroup(predicate: predicateGroupName, disable: boolean) {
    const group = this.ruleFieldGrouped.filter(g => g.group === predicate.toString());
    if (group.length > 0) {
      group[0].hidden = disable;
    }
  }

  addToValuesArray(field: AbstractControl, event: MatChipInputEvent): void {
    const input = event.chipInput;
    const value = event.value;

    if ((value || '').trim()) {
      field.value.push(value.trim());
    }
    if (input) {
      input.inputElement.value = '';
    }
    field.updateValueAndValidity();
  }

  removeFromValuesArray(field: AbstractControl, value: string): void {
    const index = field.value.indexOf(value);
    if (index >= 0) {
      field.value.splice(index, 1);
    }
    field.updateValueAndValidity();
    field.markAsDirty();
  }

  private isArrayInputType(inputType: comparisonInputType): boolean {
    return inputType === comparisonInputType.multiselect
      || inputType === comparisonInputType.autocomplete
      || inputType === comparisonInputType.chips
      || inputType === comparisonInputType.freeTextAutocomplete;
  }

  private encodeArrayValue(value: string): string {
    return value.replaceAll('|', '%7C');
  }

  private decodeArrayValue(value: string): string {
    return value.replaceAll('%7C', '|');
  }
}
