<!-- edit mode -->
<div *ngIf="isEditMode" class="wrapper">
    <form [formGroup]="form" class="value-fill">
        <mat-form-field class="full-width" [hideRequiredMarker]="true">
            <mat-label *ngIf="!isMinHeight"></mat-label>
            <input #editInput matInput formControlName="editValue" (keydown.enter)="onEndEdit()"
                (focusout)="onEndEdit()">
        </mat-form-field>
    </form>
    <div class="table-actions">
        <mat-icon (click)="onEndEdit()">done</mat-icon>
        <mat-icon (click)="onCancelEdit()">close</mat-icon>
    </div>
</div>
<!-- view mode -->
<div *ngIf="!isEditMode" class="wrapper" (click)="editOnClick && onStartEdit()">
    <div class="empty-value-label" *ngIf="!value">{{emptyValueText}}</div>
    <div [ngClass]="{'value-fill': valueFullWidth}">{{value}}</div>
    <div class="table-actions ml-2">
        <mat-icon class="mt-2" (click)="onStartEdit()">edit</mat-icon>
        <mat-icon class="mt-2" *ngIf="delete" [ngClass]="{'disabled' : isDeleteDisabled}" [matTooltip]="deleteTooltip"
            matTooltipPosition="right" (click)="onDelete()">delete</mat-icon>
    </div>
</div>