import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { marker as _ } from '@vandres/ngx-translate-extract-marker';
import { UnitPurpose } from 'src/app/modules/account/experience/experiences';
import { AccountFeature } from '../../models/IAccount';
import { AnimationOptions } from 'ngx-lottie';
import { AccountContextService } from '../../services/account-context.service';
import { showHideAnimation } from '../../animations/show-hide.animation';

export interface UnitTypeOverrideText {
  id: UnitPurpose;
  name: string;
  description: string;
}

interface UnitTypeItem {
  name: string;
  image?: string;
  description: string;
  id: UnitPurpose;
  feature?: AccountFeature;
}

@Component({
  selector: 'app-select-unit-type',
  templateUrl: './select-unit-type.component.html',
  styleUrls: ['./select-unit-type.component.scss'],
  animations: [showHideAnimation]
})
export class SelectUnitTypeComponent implements OnInit {
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() disabledTypes: UnitPurpose[] = [];
  @Input() hiddenTypes: UnitPurpose[] = [];
  @Input() showTopArrow = false;
  @Output() unitTypeSelected = new EventEmitter<UnitPurpose>();

  animationOptions: AnimationOptions = {
    path: '/assets/arrow.json'
  };
  types: UnitTypeItem[] = [
    {
      name: _('Increase Engagement With Content'),
      image: '/assets/experiences/unit-type-select-content.jpg',
      description: _('Get your visitors to engage with more relevant posts and articles using personalized recommendations'),
      id: 'engagment'
    },
    {
      name: _('Capture Leads'),
      image: '/assets/experiences/unit-type-select-capture_leads.jpg',
      description: _('Get your visitors to fill forms to reach gated content, request demos or event sign ups'),
      id: 'leads'
    },
    {
      name: _('Promote Pages'),
      image: '/assets/experiences/unit-type-select-offer.jpg',
      description: _('Get your visitors to specific pages on your site'),
      id: 'promote'
    },
    {
      name: _('Map Personas with Surveys'),
      image: '/assets/experiences/unit-type-select-survey.jpg',
      description: _('Ask users which persona they fit best to personalize their experience'),
      id: 'survey',
      feature: 'survey'
    },
    {
      name: _('Remarket to Selected Audiences'),
      image: '/assets/experiences/unit-type-select-remarketing.jpg',
      description: _('Share audiences for remarketing Campaigns with media network.'),
      id: 'script',
      feature: 'pixelBasedRetargeting'
    },
    {
      name: _('Empower Sales with Content Hubs'),
      image: '/assets/experiences/unit-type-select-hubs.jpg',
      description: _('Create dedicated destinations of curated content for your sales teams.'),
      id: 'hub',
      feature: 'contentHub'
    }
  ];
  showTypes: UnitTypeItem[] = [];

  constructor(private accountContext: AccountContextService) { }

  ngOnInit(): void {
    // exclude hidden type
    this.showTypes = this.types.filter(t => !this.hiddenTypes.includes(t.id));
  }

  onUnitTypeSelected(unitType: UnitPurpose): void {
    this.unitTypeSelected.emit(unitType);
  }

  isDisabled(unitType: UnitPurpose): boolean {
    const unitTypeFeature = this.types.find(t => t.id === unitType)?.feature;
    return (this.disabledTypes && this.disabledTypes.includes(unitType))
      || (unitTypeFeature && !this.accountContext.supports(unitTypeFeature));
  }

}
