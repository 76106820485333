<div class="color-input-container">
    <mat-form-field class="color-picker-input" floatLabel="always"
        subscriptSizing="dynamic" [hideRequiredMarker]="!label">
        <mat-label>{{label}}</mat-label>
        <input matInput [(ngModel)]="color" [placeholder]="defaultEmptyColor ?? 'Ex. #fff'" required readonly>
    </mat-form-field>
    <mat-icon class="reset-color" *ngIf="defaultEmptyColor && isChanged" matTooltip="Use original color"
        matTooltipPosition="above" (click)="onColorReset()" @showHideAnimation>undo</mat-icon>
    <input class="color-picker-sample" #picker="ngxColorPicker" [style.background]="color" [(colorPicker)]="color"
        [cpPosition]="position" cpColorMode="hex" (colorPickerChange)="onColorChange()"
        (colorPickerSelect)="picker.closeDialog()" (colorPickerOpen)="colorPickerOpen.emit()"
        (colorPickerClose)="colorPickerClose.emit()" readonly />
</div>