<div class="option-cards-container" [class.option-cards-container-small]="size === 'small'">
    <div class="option-card" [class.option-card-disabled]="isDisabled(GoalType.Destination)"
        (click)="onTypeSelected(GoalType.Destination)">
        <div *ngIf="showTopArrow" class="option-card-top-arrow">
            <div style=" position: relative; left: -50%;" lottie [options]="animationOptions"></div>
        </div>
        <img class="card-image" src="/assets/goals/dest_url.jpg">
        <div class="option-text-container">
            <div class="option-title" translate>Destination URL</div>
            <div class="option-description">
                <span *ngIf="isGoal" translate>Set a goal based on a URL on a page running the Trendemon
                    script.</span>
                <span *ngIf="!isGoal" translate>Set an event based on a URL on a page running the Trendemon
                    script.</span>
            </div>
            <div class="option-example" translate>
                For example, the Thank You page following a form submission.
            </div>
        </div>
    </div>
    <div class="option-card" [class.option-card-disabled]="isDisabled(GoalType['Action Conversion'])"
        (click)="onTypeSelected(GoalType['Action Conversion'])">
        <img class="card-image" src="/assets/goals/tag_fire.jpg">
        <div class="option-text-container">
            <div class="option-title" translate>Tag Fire</div>
            <div class="option-description" translate>
                <span *ngIf="isGoal" translate>Set a goal based on a tag fired, either directly or via Google Tag
                    Manager.</span>
                <span *ngIf="!isGoal" translate>Set an event based on a tag fired, either directly or via Google Tag
                    Manager.</span>
            </div>
            <div class="option-example" translate>
                For example, form submission load or video play.</div>
        </div>
    </div>
    <div class="option-card" *ngIf="isGoal" [class.option-card-disabled]="isDisabled(GoalType['3rd party'], false)"
        (click)="onTypeSelected(GoalType['3rd party'], false)">
        <img class="card-image" src="/assets/goals/MAP.jpg">
        <div class="option-text-container">
            <div class="option-title" translate>Marketing Automation Goal</div>
            <div class="option-description" translate>
                Set a goal based on a specific field and value from your marketing automation.</div>
            <div class="option-example" translate>
                For example: Lead Status is MQL or SQL</div>
        </div>
    </div>
    <div class="option-card" *ngIf="isGoal" [class.option-card-disabled]="isDisabled(GoalType['3rd party'], true)"
        (click)="onTypeSelected(GoalType['3rd party'], true)">
        <img class="card-image" src="/assets/goals/crm.jpg">
        <div class="option-text-container">
            <div class="option-title" translate>CRM Goal</div>
            <div class="option-description" translate>
                Set a goal based on a specific field and value from your CRM.</div>
            <div class="option-example" translate>
                For example, opportunity status is closed-won.</div>
        </div>
    </div>
</div>