<mat-form-field *ngIf="inputControl" class="full-width" floatLabel="always" subscriptSizing="dynamic">
    <mat-label></mat-label>
    <mat-chip-grid #chipList [disabled]="disabled">

        <mat-chip-row *ngFor="let item of selected" (removed)="remove(item)">
            {{ item }}
            <button matChipRemove> <mat-icon>cancel</mat-icon> </button>
        </mat-chip-row>

        <input type="text" #inputTrigger matInput [formControl]="inputControl" [placeholder]="inputPlaceholder"
            [matAutocomplete]="autoComplete" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addFreeTextItem($event)"
            (mousedown)="markAsTouched()">
    </mat-chip-grid>

    <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let item of filteredItems" [value]="selected">
            {{ item }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<div *ngIf="!isValid && inputControl?.touched" class="error">
    At lease 1 item must be set
</div>