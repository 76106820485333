import { Component, OnInit, Input, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FiltersService } from '../filters.service';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss']
})
export class TablePaginatorComponent implements OnInit, OnDestroy {

  DEFAULT_PAGE_SIZE = 30;
  @Input() totalItems: number;
  @Input() onSortChange: EventEmitter<any>;
  // pageIndex: number;
  // limit = 30;
  // offset: number;
  pageSizeOptions: number[] = [10, this.DEFAULT_PAGE_SIZE, 50, 100];
  sub: Subscription;
  lastKnownFilter: Params = undefined;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  constructor(private filterService: FiltersService) {
    this.filterService.setDefaults([{ key: 'limit', value: String(this.DEFAULT_PAGE_SIZE) }, { key: 'offset', value: String(0) }]);
  }

  ngOnInit(): void {
    this.paginator.pageSize = this.DEFAULT_PAGE_SIZE;
    this.onSortChange?.subscribe(d => {
      this.paginator.firstPage();
    });

    this.sub = this.filterService.filterActivelyChanged.subscribe(p => {
      if (this.paginator.pageIndex !== 0) {
        this.paginator.firstPage(); // we want to go to first page if one of the other filters was changed
      }
    });

    this.sub = this.filterService.currentFilter.subscribe(p => {
      if (p.limit && this.paginator.pageSize !== Number(p.limit)) {
        this.paginator.pageSize = Number(p.limit);
      }
      if (p.offset && this.paginator.pageIndex !== Number(p.offset) / this.paginator.pageSize) {
        this.paginator.pageIndex = Number(p.offset) / this.paginator.pageSize;
      }
    });
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  async pageEvent(event: PageEvent) {
    const offset = this.paginator.pageIndex * event.pageSize;
    if (event.pageSize === this.DEFAULT_PAGE_SIZE && !this.filterService.currentFilter.value.limit) {
      return;
    }
    this.filterService.applyM({ limit: event.pageSize, offset }, false);
  }

}
