import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-label-tooltip',
  template: `
    <span class="label-tooltip" matTooltip="{{tooltipText}}" matTooltipPosition="above">
      {{ labelText }}
    </span>
    `,
  styles: [
    '.label-tooltip fa-icon{padding:0 4px; color: inherit}',
  ]
})
export class LabelTooltipComponent {
  @Input() labelText: string;
  @Input() tooltipText: string;
}
