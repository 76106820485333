import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { SignedDigitsOnlyRegEx } from '../constants/validation-regex';

export const signedDigitsValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }
    const value = control.value as string;
    return SignedDigitsOnlyRegEx.test(value) ? null : { signedDigits: true };
};

@Directive({
    selector: '[appSignedDigits]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SignedDigitsValidatorDirective, multi: true }]
})
export class SignedDigitsValidatorDirective implements Validator {
    @Input('appSignedDigits') isEnabled: boolean = true;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.isEnabled || (this.isEnabled as any) === '' ? signedDigitsValidator(control) : null;
    }
}
