import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment as config } from '../../../../environments/environment';
import { ErrorInterceptorSkipHeader } from '../../shared/constants/interceptors-headers';
import { IListResponse } from '../../shared/models/IListResponse';
import { StatusRequest } from '../../shared/models/models';
import { SupportedCreativeExperience, SupportedExperiences } from '../experience/experiences';
import { ExperienceCreative, ExperienceEmbedded, ExperiencePreStyle, ExperienceUnitImage } from '../experience/models';

@Injectable({
  providedIn: 'root'
})
export class PreStyleService {

  constructor(private http: HttpClient) { }

  // pre-style
  // ---------

  getList(): Observable<IListResponse<ExperiencePreStyle>> {
    return this.http.get<IListResponse<ExperiencePreStyle>>(`${config.adminBaseUrl}/api/PreStyle/list`);
  }

  add(request: ExperiencePreStyle): Observable<void> {
    const headers = new HttpHeaders().set(ErrorInterceptorSkipHeader, '');
    return this.http.post<void>(`${config.adminBaseUrl}/api/PreStyle`, request, { headers });
  }

  update(id: number, request: ExperiencePreStyle): Observable<void> {
    return this.http.patch<void>(`${config.adminBaseUrl}/api/PreStyle/${id}`, request);
  }

  updateTitle(id: number, name: string, thumbnailUrl: string): Observable<void> {
    const body: Params = { name, thumbnailUrl };
    return this.http.patch<void>(`${config.adminBaseUrl}/api/PreStyle/${id}/title`, body);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${config.adminBaseUrl}/api/PreStyle/${id}`);
  }

  // pre-style lib
  // -------------

  getLibListMinData(filters: Params = {}): Observable<IListResponse<ExperiencePreStyle>> {
    return this.http.get<IListResponse<ExperiencePreStyle>>(
      `${config.adminBaseUrl}/api/PreStyle/lib/min_data`, { params: filters });
  }

  getLibListFullData(filters: Params = {}): Observable<IListResponse<ExperiencePreStyle>> {
    return this.http.get<IListResponse<ExperiencePreStyle>>(
      `${config.adminBaseUrl}/api/PreStyle/lib/full_data`, { params: filters });
  }

  addToLib(request: ExperiencePreStyle): Observable<number> {
    return this.http.post<number>(`${config.adminBaseUrl}/api/PreStyle/lib`, request);
  }

  updateLib(id: number, request: ExperiencePreStyle): Observable<void> {
    return this.http.patch<void>(`${config.adminBaseUrl}/api/PreStyle/lib/${id}`, request);
  }

  changeLibStatus(id: number, request: StatusRequest): Observable<void> {
    return this.http.patch<void>(`${config.adminBaseUrl}/api/PreStyle/lib/${id}/change_status`, request);
  }

  uploadLibImage(request: ExperienceUnitImage): Observable<{ imgLink: string }> {
    const formData = new FormData();
    Object.keys(request).forEach(key => formData.append(key, request[key]));
    const res = this.http.post<{ imgLink: string }>(`${config.adminBaseUrl}/api/PreStyle/lib/upload_image`, formData);
    return res;
  }

  // conversion methods
  // ------------------

  preStyleToSupportedLayout(preStyle: ExperiencePreStyle, additionalOverrideFields: string[] = []): SupportedCreativeExperience {
    const layout = SupportedExperiences.find(x => x.layoutId === preStyle.layoutId);
    const overrideFields = ['theme', 'secondaryTextColor', 'backgroundColor', 'textColor', 'buttonBackgroundColor', 'buttonTextColor',
      'css', 'customHtml', 'height', 'width', 'buttonText', 'title', 'subTitle', 'successText', 'imgUrl', 'isOpenInNewWindow',
      'isUnitLink', 'scriptCode', 'formFields', 'maProvider', 'maProviderListToSendLeads', 'maFormReference', 'emailNotificationList',
      'emailNotificationEnabled', 'recommendationsLimit', 'showRecommendTags', 'showRecommendArea', 'surveyQuestions', 'screenPosition',
      'recommendIsCarousel', 'recommendCarouselDisplayLimit', 'recommendCarouselShowIndicator', 'recommendCarouselIndicatorColor', 'closeButton',
      ...additionalOverrideFields];
    const surveyQuestions = preStyle.surveyQuestions?.map(q => ({ ...q, questionId: 0 }));
    const overrides: Partial<ExperienceCreative> = Object.fromEntries(Object.entries(preStyle).filter(e => overrideFields.includes(e[0])));
    overrides.surveyQuestions = surveyQuestions;
    overrides.contentPoolId = layout.overrides.contentPoolId;
    const classes = [...new Set([...preStyle.classes, ...layout.classes])]
      .filter(c => !['light', 'full', 'slider', 'fixed', 'embedded'].includes(c));
    return {
      layoutId: preStyle.layoutId,
      preStyleId: preStyle.unitId,
      libPreStyleId: preStyle.libPreStyleId,
      purpose: layout.purpose,
      name: preStyle.name,
      type: preStyle.uiType,
      classes,
      positions: layout.positions,
      description: null,
      demoImage: preStyle.thumbnailUrl ? `${config.mediaUrl}/tasks_logo/${preStyle.thumbnailUrl}` : null,
      thumbnailUrl: preStyle.thumbnailUrl,
      showFields: layout.showFields,
      showInVisibiltyTypes: layout.showInVisibiltyTypes,
      forceConfig: true,
      overrides,
      defaultFields: preStyle.formFields,
      defaultSurveyQuestions: surveyQuestions,
      defaultTheme: preStyle.theme,
    } as SupportedCreativeExperience;
  }

  layoutToPreviewUnit(layout: SupportedCreativeExperience): ExperienceCreative & ExperienceEmbedded {
    return {
      ...layout.overrides,
      guid: 'preview',
      link: layout.type === 'fastext' || layout.type === 'basic' ? 'https://trendemon.com/' : null,
      uiType: layout.type,
      isUnitLink: layout.type === 'fastext',
      visibilityType: layout.overrides.visibilityType,
      classes: [...layout.classes, layout.overrides.visibilityType],
    } as ExperienceCreative & ExperienceEmbedded;
  }
}
