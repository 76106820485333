import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { FiltersService } from '../filters.service';
import { Subscription } from 'rxjs';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})
export class DateRangeFilterComponent implements OnInit, OnDestroy {
  maxDate: Date;
  DEFAULT_PERIOD_DAYS = 29;
  value: { begin: Date; end: Date };
  sub: Subscription;
  @Input() beginName = 'from_date';
  @Input() endName = 'to_date';
  @Input() persistant = true;
  @Input() allowTodayDate = false;
  @Input() onlyCompare = false;
  @Output() filterChanged = new EventEmitter<{ onlyComapre: boolean }>();

  get persistantKey(): string {
    return `filter.${this.beginName}___${this.endName}`;
  }
  constructor(private filterService: FiltersService) {

  }

  ngOnInit(): void {
    let defaultEndDate = this.allowTodayDate ? moment().startOf('day') : moment().subtract(1, 'day').startOf('day');
    let defaultStartDate = moment(defaultEndDate).subtract(this.DEFAULT_PERIOD_DAYS, 'days').startOf('day');

    if (this.persistant) {
      const range = sessionStorage.getItem(this.persistantKey);
      if (range) {
        const rangObj = JSON.parse(range);
        defaultStartDate = moment(rangObj[this.beginName]);
        defaultEndDate = moment(rangObj[this.endName]);
      }
    }

    this.maxDate = this.allowTodayDate ? moment().startOf('day').toDate() : moment().subtract(1, 'day').startOf('day').toDate();
    this.value = { begin: defaultStartDate.toDate(), end: defaultEndDate.toDate() };
    this.filterService.setDefault(this.beginName, defaultStartDate.format('YYYY-MM-DD'));
    this.filterService.setDefault(this.endName, defaultEndDate.format('YYYY-MM-DD'));

    this.sub = this.filterService.currentFilter.subscribe(p => {
      if (p[this.beginName] && p[this.endName]) {
        this.value = { begin: moment(p[this.beginName]).toDate(), end: moment(p[this.endName]).toDate() };

        const dates = {};
        dates[this.beginName] = p[this.beginName];
        dates[this.endName] = p[this.endName];
        this.persist(dates);
      }
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  apply(dateRange: DateRange<Date>) {
    if (!dateRange.start) {
      return;
    }
    const dates = {};
    dates[this.beginName] = moment(dateRange.start).format('YYYY-MM-DD');
    dates[this.endName] = moment(dateRange.end ?? dateRange.start).format('YYYY-MM-DD');
    this.filterService.applyM(dates);
    this.persist(dates);
    this.filterChanged.emit({ onlyComapre: this.onlyCompare });
  }

  private persist(dates: any): void {
    if (this.persistant) {
      sessionStorage.setItem(this.persistantKey, JSON.stringify(dates));
    }
  }
}
