import { RulesConfig } from '../../shared/models/models';
import { IntegrationType } from '../settings/integrations/integrations';

export interface UpdateCompaniesTargetListResponse {
    id: number;
    companiesAdded: number;
}

export interface CompaniesTargetListItem {
    id: number;
    name: string;
    companiesCount: number;
    updatedAt: string;
    listType: CompaniesTargetListItemType;
    buildType: CompaniesTargetListBuildType;
    configObject?: CompaniesTargetListConfigObject;
    listConfig: RulesConfig;
    integrationId: IntegrationType;
}

export interface CompaniesTargetListUsed {
    id: number;
    audiences: number[];
    units: number[];
    orchs: number[];
    reports: number[];
}

export enum CompaniesTargetListItemType {
  Company = 1,
  Domain = 2,
  CompanyExact = 3,
}

export enum CompaniesTargetListBuildType {
  Static = 1,
  Dynamic = 2
}

export enum CompaniesTargetListConfigObject {
  Opportunity = 1,
  Lead,
  Account
}

export interface CompaniesTargetListSaveData {
  name: string;
  file: Blob;
  listType: CompaniesTargetListItemType;
  replaceItems: boolean;
  buildType: CompaniesTargetListBuildType;
  configObject: CompaniesTargetListConfigObject;
  listConfig: RulesConfig;
  integrationId: IntegrationType;
}
