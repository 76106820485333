import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaterialModule } from 'src/app/modules/shared/submodule-material.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { showHideAnimation } from 'src/app/modules/shared/animations/show-hide.animation';

export type ColorPickerPosition = 'auto' | 'top' | 'left' | 'right' | 'bottom' | 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right';

@Component({
  selector: 'app-color-picker-input',
  standalone: true,
  imports: [CommonModule, FormsModule, MaterialModule, ColorPickerModule],
  templateUrl: './color-picker-input.component.html',
  styleUrls: ['./color-picker-input.component.scss'],
  animations: [showHideAnimation],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ColorPickerInputComponent, multi: true }
  ]
})
export class ColorPickerInputComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() position: ColorPickerPosition = 'bottom-left';
  @Input() defaultEmptyColor: string = null;
  @Input() smallHeight = false;
  @Output() colorPickerOpen = new EventEmitter<void>();
  @Output() colorPickerClose = new EventEmitter<void>();
  color = '#fff';
  valueColor = '#fff';
  isChanged = false;
  private onValueChange = (_: string) => { };

  writeValue(color: string): void {
    this.color = color ?? this.defaultEmptyColor;
    this.valueColor = color;
  }
  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }
  registerOnTouched(fn: any): void {
    // console.log('ColorPickerInputComponent registerOnTouched');
  }

  onColorChange(): void {
    this.isChanged = true;
    this.valueColor = this.color;
    this.onValueChange(this.valueColor);
  }

  onColorReset(): void {
    this.isChanged = false;
    this.color = this.defaultEmptyColor;
    this.valueColor = null;
    this.onValueChange(this.valueColor);
  }
}
