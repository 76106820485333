import { AccountGoal } from '../../shared/models/models';
import { IntegrationType } from '../settings/integrations/integrations';

export interface GoalSettings extends AccountGoal {
  url: string;
  selector: string;
  order: number;
  isGoal: boolean;
  integrationId: IntegrationType;
  integrationFields: MaRule[];
  createdDate: string;
}



export interface MaRule {
  value: string;
  field: string;
  operator: MaRuleOperator;
}

export enum MaRuleOperator {
  Equals = 0,
  GreaterThan,
  LessThan,
  NotEmpty,
  NotIn,
  Contains,
}

export enum MaFieldType {
  Numeric,
  Array,
  Hidden
}

