import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { UserNotificationService } from 'src/app/modules/shared/user-notification.service';
import { ThemeService } from '../../services/theme.service';
import { predefinedThemes } from '../experience-configs';
import { SupportedCreativeExperience } from '../experiences';
import { ExperienceTheme, Theme, ThemeSpec } from '../models';
import { IUnitEditorBase } from '../unit-editor-base';

@Component({
  selector: 'app-unit-theme',
  templateUrl: './unit-theme.component.html',
  styleUrls: ['./unit-theme.component.scss']
})
export class UnitThemeComponent implements IUnitEditorBase<ExperienceTheme>, OnInit, AfterViewInit, OnDestroy {
  @Input() unit: ExperienceTheme;
  @Input() selectedLayout: SupportedCreativeExperience;
  @ViewChild('themeForm') themeForm: NgForm;

  themes: ThemeSpec[];
  advancedBg: boolean;
  protected isChangedPrivate = false;
  private themeChangedSub: Subscription;
  private valueChangeSub: Subscription;

  constructor(
    private themeService: ThemeService,
    private notifications: UserNotificationService
  ) {
    this.themes = this.themeService.themes;
  }

  ngOnInit(): void {
    this.advancedBg = !this.isSolid(this.unit.backgroundColor);
    this.themeChangedSub = this.themeService.themeChanged$.subscribe(() => this.isChangedPrivate = false);
  }

  ngAfterViewInit(): void {
    this.valueChangeSub = this.themeForm.form.valueChanges.subscribe(() => this.isChangedPrivate = true);
  }

  ngOnDestroy(): void {
    this.themeChangedSub?.unsubscribe();
    this.valueChangeSub?.unsubscribe();
  }

  // ------------------------------------------
  // API methods

  changeTheme(theme: Theme, layout: SupportedCreativeExperience, simpleBgDefaultColor: string = null): void {
    this.themeService.changeTheme(this.unit, theme, layout);
    this.advancedBg = !this.isSolid(this.unit.backgroundColor);
    if (this.advancedBg && simpleBgDefaultColor) {
      this.unit.backgroundColor = simpleBgDefaultColor;
      this.advancedBg = false;
    }
  }

  get isChanged(): boolean { return this.isChangedPrivate; }

  // ------------------------------------------
  // Component methods

  onThemeChange(theme: Theme) {
    this.isChangedPrivate = true;
    this.changeTheme(theme, this.selectedLayout);
  }

  isCustomThemes(themeName: string) {
    return predefinedThemes.find(t => t.name === themeName) === undefined;
  }

  changeBgType(event: MatCheckboxChange) {
    const advancesBg = event.checked;
    if (!advancesBg) {
      this.onThemeChange(this.unit.theme);
      this.unit.backgroundColor = '#fff';
    }
  }

  saveTheme(name: string, silent = false) {
    name = name || `${this.selectedLayout.name}_${new Date().toISOString()}`;
    this.themeService.saveTheme(this.unit, name);
    if (!silent) { this.notifications.notifySuccess(`Theme "${name}" Saved`); }
  }

  private isSolid(bg: string): boolean {
    return !bg || bg.startsWith('rgb') || bg.startsWith('#');
  }
}
