<app-loader *ngIf="loading"></app-loader>

<div class="chain-wrap">
  <div class="chain">
    <div class="chain-info chain-item">
      <div class="chain-info-domain">{{info.name}}</div>
      <div class="chain-info-date">{{info.date}}</div>
      <div class="chain-info-system">{{info.system}}</div>
    </div>

    <div class="chain-circles" *ngFor="let circle of circles; let first = first; let last = last; let index = index;">

      <div class="chain-item chain-arrow-start" *ngIf="first && !StartWithBreakLine">
        <div class="chain-arrow">
          <div class="end-arrow"></div>
        </div>
      </div>

      <div class="chain-item chain-item-arrows-start-long" *ngIf="first && StartWithBreakLine">
        <div class="chain-arrow">
          <div class="end-arrow"></div>
          <div class="break-arrows">
            <div class="break-arrow"></div>
            <div class="break-arrow"></div>
            <div class="break-arrow"></div>
          </div>
        </div>
      </div>

      <div class="chain-item chain-circle">
        <div class="chain-circle-top-info">
          <div class="chain-circle-top-session">{{circle?.session_source}}</div>
          <div class="chain-circle-top-date">{{circle?.event_date}}</div>
        </div>
        <div class="chain-item-circle">
          <img [class.default-img]="circle.imgsrc === defaultImagePath" src="{{circle.imgsrc}}" />
        </div>
        <div class="chain-item-goalname">{{circle?.goalname}}</div>
      </div>


      <div class="chain-item-arrows-{{circle.formatted.arrowType}}" *ngIf="!last">
        <div class="full-arrow">
          <div class="arrow-{{circle.formatted.arrowType}}">
            <div class="chain-arrow">
              <div class="start-arrow"></div>
              <div class="break-arrows" *ngIf="circle.formatted.arrowType==='longer'">
                <div class="break-arrow"></div>
                <div class="break-arrow"></div>
                <div class="break-arrow"></div>
              </div>
            </div>
          </div>
          <div class="arrow-separator"></div>
          <div class="arrow-{{circle.formatted.arrowType}}">
            <div class="chain-arrow">
              <div class="end-arrow"></div>
              <div class="break-arrows" *ngIf="circle.formatted.arrowType==='longer'">
                <div class="break-arrow"></div>
                <div class="break-arrow"></div>
                <div class="break-arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="chain-period-info">
          <div class="chain-period-info-duration">{{circles[index+1].duration_day}}</div>
          <div class="chain-period-info-session" *ngIf="showPeriodSessionInfo">{{circles[index+1].formatted.session}}
          </div>
          <div class="chain-period-info-pages" *ngIf="showPeriodSessionInfo">{{circles[index+1].formatted.pages}}</div>
        </div>
      </div>

    </div>
  </div>
</div>