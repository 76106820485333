<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<app-loader *ngIf="!hideLoader && !rules"></app-loader>
<form *ngIf="rules" [formGroup]="rules" class="rulesForm">
  <div formArrayName="rulesGroups">
    <div *ngFor="let RulesGroup of getRulesGroups(rules); let i = index" class="rule-block
      margin-vertical-10">
      <div class="padding-vertical-4 text-right delete-group-link"
        *ngIf="!this.config.singleGroup && !config.fixedRules">
        <a *ngIf="getRulesGroups(rules).length>1" (click)="removeRulesGroup(i)" href="#" translate>
          Delete Group
        </a>
      </div>
      <div [formGroupName]="i" class="rule-block-inner padding-10">
        <div formArrayName="rules">
          <div [formGroupName]="j" *ngFor="let Rule of getRuleLines(RulesGroup); let j = index"
            class="padding-vertical-4 padding-horizontal-4 margin-vertical-4">
            <div class="rulesGroup">
              <!-- readonly parameter -->
              <mat-form-field class="comparisonPredicate" *ngIf="config.fixedRules">
                <mat-label translate>Parameter</mat-label>
                <input matInput readonly [value]="getPredicateName(Rule.get('comparisonPredicate').value)" />
              </mat-form-field>
              <!-- selectable parameter - autocomplete -->
              <app-rule-engine-predicate *ngIf="!config.fixedRules && autocompleteFieldName"
                formControlName="comparisonPredicate" class="comparisonPredicate" [rulesOptions]="rulesOptions"
                [ruleFields]="usedRuleFields" (selectionChange)="resetLineDataOnChange($event, Rule)">
              </app-rule-engine-predicate>
              <!-- selectable parameter - dropdown list -->
              <mat-form-field class="comparisonPredicate" *ngIf="!config.fixedRules && !autocompleteFieldName">
                <mat-label translate>Choose Parameter</mat-label>
                <mat-select matNativeControl formControlName="comparisonPredicate" #comparisonPredicate class="padding-horizontal-4">
                  <mat-optgroup *ngFor="let group of ruleFieldGrouped">
                    <mat-label translate>{{predicateGroupNameByValue(group.group)}}</mat-label>
                    <mat-option *ngFor="let rule of group.fields" [value]="rule.predicate" #opt
                      [disabled]="group.hidden" [matTooltip]="rule.description">
                      <span translate>{{rule.name}}</span> <span class='predicate-description' translate
                        *ngIf="rule.description && !opt.selected">[{{rule.description}}]</span>
                    </mat-option>
                  </mat-optgroup>

                </mat-select>
              </mat-form-field>

              <mat-form-field class="daysPredicate" floatLabel="always"
                *ngIf="ifDaysPredicate(Rule.get('comparisonPredicate').value) && !ifSalesforceField(Rule.get('comparisonPredicate').value)">
                <mat-label></mat-label>
                <mat-select matNativeControl formControlName="daysPredicate" class="padding-horizontal-4">
                  <mat-option *ngFor="let option of daysPredicateOptions" [value]="option.name">{{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="comparisonOperator">
                <mat-label translate>Choose Operator</mat-label>
                <mat-select matNativeControl formControlName="comparisonOperator" class="padding-horizontal-4"
                  (selectionChange)="onOperatorChange($event.value, Rule)">
                  <mat-option *ngFor="let operator of getOptions(Rule.get('comparisonPredicate').value)"
                    [value]=" operator.name">{{operator.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- single value -->
              <mat-form-field class="comparisonValue" *ngIf="
                 (Rule.get('comparisonOperator').value != 'StringNullOrEmpty' && Rule.get('comparisonOperator').value != 'StringNotNullOrEmpty') && (
                 getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.textField ||
                 getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.number ||
                 getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.url ||
                 getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.email ||
                 getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.dateTime) ||
                 Rule.get('comparisonOperator').value == 'CollectionElementContains'">
                <mat-label>{{getPlaceholder(Rule.get('comparisonPredicate').value)}}</mat-label>
                <input matInput formControlName="comparisonValue">
                <div
                  *ngIf="Rule.get('comparisonValue').invalid && (Rule.get('comparisonValue').dirty || Rule.get('comparisonValue').touched)"
                  class="error rule-error">
                  <div *ngIf="Rule.get('comparisonValue').errors.required" translate>
                    The field is required.
                  </div>
                  <div *ngIf="Rule.get('comparisonValue').errors.number" translate>
                    The field must contain only digits.
                  </div>
                  <div *ngIf="Rule.get('comparisonValue').errors.email" translate>
                    The field must be a valid email address.
                  </div>
                  <div *ngIf="Rule.get('comparisonValue').errors.url" translate>
                    The field must be a valid Url.
                  </div>
                  <div *ngIf="Rule.get('comparisonValue').errors.dateError" translate>
                    The date must be in format 'YYYY/MM/DD'.
                  </div>
                </div>
              </mat-form-field>
              <!-- multiselect values - not grouped -->
              <mat-form-field class="comparisonValue"
                *ngIf="getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.multiselect && !getPredicateValuesGroupedOrNot(Rule.get('comparisonPredicate').value)">
                <mat-label>{{getPlaceholder(Rule.get('comparisonPredicate').value)}}</mat-label>
                <mat-select multiple formControlName="comparisonArray">
                  <mat-option *ngFor="let collection of getCollection(Rule.get('comparisonPredicate').value)"
                    [value]="printProperty(collection, 'id')">
                    {{printProperty(collection, 'name')}}
                  </mat-option>
                </mat-select>
                <div
                  *ngIf="Rule.get('comparisonArray').invalid && (Rule.get('comparisonArray').dirty || Rule.get('comparisonArray').touched)"
                  class="error rule-error">
                  <div *ngIf="Rule.get('comparisonArray').errors.required" translate>
                    The field is required.
                  </div>
                </div>
              </mat-form-field>
              <!-- multiselect values - grouped -->
              <mat-form-field class="comparisonValue"
                *ngIf="getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.multiselect && getPredicateValuesGroupedOrNot(Rule.get('comparisonPredicate').value)">
                <mat-label>{{getPlaceholder(Rule.get('comparisonPredicate').value)}}</mat-label>
                <mat-select multiple formControlName="comparisonArray">
                  <mat-optgroup *ngFor="let group of getCollection(Rule.get('comparisonPredicate').value) | keyvalue">
                    <mat-label translate>{{group.key}}</mat-label>
                    <mat-option *ngFor="let collection of group.value" [value]="printProperty(collection, 'id')">
                      {{printProperty(collection, 'name')}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <div
                  *ngIf="Rule.get('comparisonArray').invalid && (Rule.get('comparisonArray').dirty || Rule.get('comparisonArray').touched)"
                  class="error rule-error">
                  <div *ngIf="Rule.get('comparisonArray').errors.required" translate>
                    The field is required.
                  </div>
                </div>
              </mat-form-field>
              <!-- single value from list -->
              <mat-form-field class="comparisonValue"
                *ngIf="getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.select">
                <mat-label>{{getPlaceholder(Rule.get('comparisonPredicate').value)}}</mat-label>
                <mat-select formControlName="comparisonValue">
                  <mat-option *ngFor="let collection of getCollection(Rule.get('comparisonPredicate').value)"
                    [value]="printProperty(collection, 'id')">
                    {{printProperty(collection, 'name')}}
                  </mat-option>
                </mat-select>
                <div
                  *ngIf="Rule.get('comparisonValue').invalid && (Rule.get('comparisonValue').dirty || Rule.get('comparisonValue').touched)"
                  class="error rule-error">
                  <div *ngIf="Rule.get('comparisonValue').errors.required" translate>
                    The field is required.
                  </div>
                </div>
              </mat-form-field>
              <!-- autocomplete value -->
              <mat-form-field class="comparisonValue full-width" floatLabel="always" [hideRequiredMarker]="true"
                *ngIf="getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.autocomplete">
                <mat-label></mat-label>
                <app-multiselect-autocomplete formControlName="comparisonArray"
                  [itemsList]="getCollection(Rule.get('comparisonPredicate').value)"
                  [required]="true"></app-multiselect-autocomplete>
              </mat-form-field>
              <!-- free text multiple values -->
              <mat-form-field class="comparisonValue full-width"
                *ngIf="getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.chips">
                <mat-chip-grid #value formControlName="comparisonArray">
                  <mat-chip-row *ngFor="let value of Rule.get('comparisonArray').value"
                    (removed)="removeFromValuesArray(Rule.get('comparisonArray'), value)">
                    {{value}}
                    <button matChipRemove> <mat-icon>cancel</mat-icon> </button>
                  </mat-chip-row>
                  <input placeholder="add a value" [matChipInputFor]="value"
                    [matChipInputSeparatorKeyCodes]="chipsSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addToValuesArray(Rule.get('comparisonArray'), $event)">
                </mat-chip-grid>
                <div
                  *ngIf="Rule.get('comparisonArray').invalid && (Rule.get('comparisonArray').dirty || Rule.get('comparisonArray').touched)"
                  class="error rule-error">
                  <div *ngIf="Rule.get('comparisonArray').errors.required" translate>
                    At least 1 value needs to be added
                  </div>
                </div>
              </mat-form-field>
              <!-- free text autocomplete -->
              <app-multi-freetext-autocomplete class="comparisonValue"
                *ngIf="getInputType(Rule.get('comparisonPredicate').value)==comparisonInputType.freeTextAutocomplete && Rule.get('comparisonOperator').value != 'CollectionElementContains'"
                placeholderEmpty="Add a value" formControlName="comparisonArray"
                [itemsList]="getCollectionValues(Rule.get('comparisonPredicate').value)" [required]="true">
              </app-multi-freetext-autocomplete>
              <!-- salesforce opprortunity value -->
              <mat-form-field class="daysPredicate sfDaysPredicate"
                *ngIf="ifDaysPredicate(Rule.get('comparisonPredicate').value) && ifSalesforceField(Rule.get('comparisonPredicate').value)">
                <mat-select matNativeControl formControlName="daysPredicate" class="padding-horizontal-4">
                  <mat-option *ngFor="let option of sfDaysPredicateOptions" [value]="option.name">{{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-icon *ngIf="!config.fixedRules && getRuleLines(RulesGroup).length>1"
                matTooltip="{{'Delete Condition' | translate}}" (click)="removeRuleLine(i, j)"
                class="margin-horizontal-4 colored-icon">
                remove_circle</mat-icon>

            </div>
            <div class="app-add-edit-buttons-left margin-vertical-4 padding-horizontal-10 comp-group-operator"
              *ngIf="getRuleLines(RulesGroup).length > 1 && j < getRuleLines(RulesGroup).length-1">
              {{getGroupOperator(i)}}
            </div>
          </div>
        </div>
        <div class="margin-vertical-4 padding-horizontal-10" *ngIf="!config.fixedRules">
          <a (click)="addRuleLine(i)" href="#" translate>
            + Add Condition
          </a>
        </div>
        <div class="app-add-edit-buttons-left"
          *ngIf="!this.config.hideInGroupOperatorToggle && getRuleLines(RulesGroup).length > 1">
          <span translate>Relation between conditions:</span>
          <mat-button-toggle-group formControlName="rulesOperator" aria-label="" class="margin-horizontal-10">
            <mat-button-toggle value="And">And</mat-button-toggle>
            <mat-button-toggle value="Or">Or</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div> <!-- rule-block-inner-->
      <div class="app-add-edit-buttons-left margin-vertical-4 padding-horizontal-10 comp-general-operator"
        *ngIf="!this.config.singleGroup && getRulesGroups(rules).length>1 && i < getRulesGroups(rules).length-1">
        {{getGeneralOperator()}}
      </div>
      <div class="margin-vertical-10"
        *ngIf="!this.config.singleGroup && !config.fixedRules && i==getRulesGroups(rules).length-1">
        <a (click)="addRulesGroup()" class="margin-vertical-4" href="#" translate>
          + Add Conditions Group
        </a>
      </div>
    </div>
  </div>

  <div class="app-add-edit-buttons-left"
    *ngIf="!this.config.hideGroupsOperatorToggle && !this.config.singleGroup && getRulesGroups(rules).length>1">
    <span translate>Relation between groups:</span>
    <mat-button-toggle-group formControlName="rulesOperator" aria-label="" class="margin-horizontal-10">
      <mat-button-toggle value="And">And</mat-button-toggle>
      <mat-button-toggle value="Or">Or</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="app-add-edit-buttons">
    <a *ngIf="!this.config.fixedRules" href="#" (click)="resetAllRules()">reset all</a>
  </div>

  <br />

  <!--<div class="app-add-edit-buttons margin-vertical-10">
    <button mat-flat-button color="primary" type="submit" translate>Save</button>
    <button mat-flat-button class="flat-basic-button" (click)="onCancel()" translate>Cancel</button>
  </div>-->
</form>

<pre style="display: none;"> {{rules?.value | json}} </pre>