<mat-chip-grid #chipList>

  <mat-chip-row *ngFor="let item of selected" [removable]="removable" (removed)="remove(item)">
    {{item.name}}
    <button matChipRemove *ngIf="removable"> <mat-icon>cancel</mat-icon> </button>
  </mat-chip-row>

  <input #inputTrigger matInput [formControl]="inputControl" [matAutocomplete]="autoComplete"
    [matChipInputFor]="chipList" [placeholder]="inputPlaceholder" (focus)="isInputFocused = true"
    (blur)="isInputFocused = false">

  @if(hasArrowDown) {
    <mat-icon>arrow_drop_down</mat-icon>
  }
</mat-chip-grid>

<mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="toggleSelection($event.option.value)">
  <mat-option *ngFor=" let item of filteredItems" [value]="item" [disabled]="disabledItems.includes(item.id)">
    <mat-checkbox [checked]="isItemSelected(item)"> {{ item.name }} </mat-checkbox>
  </mat-option>
</mat-autocomplete>