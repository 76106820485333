import { Component, Input } from '@angular/core';
import { showHideAnimation } from '../animations/show-hide.animation';

@Component({
  selector: 'app-code-display',
  template: ` <div class="code-container">
                <pre [style.max-height]="maxHeight" [class.flex-filler]="fullWidth">{{text}}</pre>
                <div>
                  <button type="button" mat-icon-button [cdkCopyToClipboard]="text" (click)="showCopied = true"
                    (mouseenter)="isCopyButtonHover = true"
                    (mouseleave)="isCopyButtonHover = false; showCopied = false">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <div class="copied-label" *ngIf="isCopyButtonHover && showCopied" @showHideAnimation>copied</div>
                </div>
              </div>`,
  styles: [
    `
        .code-container {
          display: flex;
        }
        pre {
            display: block;
            padding: 9.5px;
            margin: 0 0 10px;
            font-size: 13px;
            line-height: 1.42857;
            word-break: break-all;
            word-wrap: break-word;
            color: #333333;
            background-color: #f5f5f5;
            border: 1px solid #ccc;
            border-radius: 2px;
            overflow: auto;
        }
        .copied-label {
            color: #999;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
        }`
  ],
  animations: [showHideAnimation]
})
export class CodeDisplayComponent {
  @Input() text = '';
  @Input() maxHeight: string;
  @Input() fullWidth = false;

  isCopyButtonHover = false;
  showCopied = false;
}
