import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ModalsService } from 'src/app/modules/shared/services/modals.service';
import { SurveyQuestion } from '../models';
import { marker as _ } from '@vandres/ngx-translate-extract-marker';

export interface SurveySaveEvent {
  isValid: boolean;
  questions: SurveyQuestion[];
}

@Component({
  selector: 'app-experience-survey-edit',
  templateUrl: './experience-survey-edit.component.html',
  styleUrls: ['./experience-survey-edit.component.scss']
})
export class ExperienceSurveyEditComponent implements OnInit {

  @Input() questions: SurveyQuestion[];
  @Input() isPreStyleEdit = false;
  @Output() save = new EventEmitter<SurveySaveEvent>();

  form: UntypedFormGroup;
  isAnswerDeletedMsgShowen = false;
  isAnswerChangedMsgShowen = false;

  public constructor(private modals: ModalsService) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      questionsArray: new UntypedFormArray((this.questions || [this.defaultQuestion]).map(q => this.getQuestionFormGroup(q)))
    });

    this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => this.sendSaveEvent());
  }

  onAddQuestion(): void {
    this.questionsArray.push(this.getQuestionFormGroup(this.defaultQuestion));
  }

  onQuestionDown(questionIdx: number): void {
    const q = this.questionsArray.at(questionIdx);
    this.questionsArray.removeAt(questionIdx);
    this.questionsArray.insert(questionIdx + 1, q);
  }

  onQuestionUp(questionIdx: number): void {
    const q = this.questionsArray.at(questionIdx);
    this.questionsArray.removeAt(questionIdx);
    this.questionsArray.insert(questionIdx - 1, q);
  }

  onAddAnswer(questionIdx: number): void {
    this.answersArray(questionIdx).push(new UntypedFormControl('', Validators.required));
  }

  onDeleteQuestion(questionIdx: number): void {
    this.questionsArray.removeAt(questionIdx);
  }

  async onDeleteAnswer(questionIdx: number, answerIdx: number): Promise<void> {
    let isDelete = true;
    const questionId = this.questionsArray.at(questionIdx).get('questionId').value;
    if (!this.isPreStyleEdit && questionId !== 0 && !this.isAnswerDeletedMsgShowen) {
      isDelete = await this.modals.Confirm(_('Changing the answers order will invalidate the previous configured answers.'), _('Warning'), _('Delete Answer'));
      this.isAnswerDeletedMsgShowen = true;
    }

    if (isDelete) {
      this.answersArray(questionIdx).removeAt(answerIdx);
    }
  }

  answersArray(questionIdx: number): UntypedFormArray {
    return this.questionsArray.controls[questionIdx].get('answers') as UntypedFormArray;
  }

  get questionsArray(): UntypedFormArray {
    return this.form.get('questionsArray') as UntypedFormArray;
  }

  get defaultQuestion(): SurveyQuestion { return { questionId: 0, question: '', answers: [''] }; }

  private getQuestionFormGroup(q: SurveyQuestion): UntypedFormGroup {
    const answersControls = q.answers.map(a => new UntypedFormControl(a, Validators.required));
    if (q.questionId !== 0) {
      answersControls.forEach(a => a.valueChanges.subscribe(() => {
        if (!this.isAnswerChangedMsgShowen) {
          this.isAnswerChangedMsgShowen = true;
          this.modals.Alert(_('Changing the answers meaning will invalidate the previous configured answers.'), _('Warning'));
        }
      }));
    }

    return new UntypedFormGroup({
      questionId: new UntypedFormControl(q.questionId),
      question: new UntypedFormControl(q.question, Validators.required),
      answers: new UntypedFormArray(answersControls)
    });
  }

  private sendSaveEvent(): void {
    this.save.emit({
      isValid: this.form.valid,
      questions: this.questionsArray.value
    });
  }
}
