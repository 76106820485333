import { Pipe, PipeTransform } from '@angular/core';
import { CompaniesTargetListBuildType } from '../../account/models/companies-target-lists.models';

@Pipe({name: 'buildTargetListType'})
export class TargetListBuildTypePipe implements PipeTransform {

  transform(targetListBuildType: CompaniesTargetListBuildType) {
    switch (targetListBuildType) {
      case CompaniesTargetListBuildType.Static: return 'Static';
      case CompaniesTargetListBuildType.Dynamic: return 'Dynamic';
      default: return '';
    }
  }
}
