import { lastValueFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as config } from '../../../../environments/environment';
import { Params } from '@angular/router';
import { ChangeOrderRequest, StatusRequest, KeyValuePair, DataResponse } from '../../shared/models/models';
import {
  ExperienceUnit, ExperienceUnitGroup, ExperienceUnitImage, UnitLead, SurveyQuestionForAudience,
  ExperienceUnitListItem, ExperienceUnitGroupListItem, ExperienceTargets
} from '../experience/models';
import { map } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { InsightsUnitGraphItem, InsightsUnitStats, UnitAllTimeStats, SurveyQuestionResult } from '../models/models';
import { IListResponse } from '../../shared/models/IListResponse';
import { ImageResizeType } from '../settings/models/settings-models';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {

  constructor(
    private http: HttpClient
  ) { }

  // campaigns
  // ---------

  // get campaigns
  getUnits(filters: Params): Observable<IListResponse<ExperienceUnit>> {
    const units = this.http.get<IListResponse<ExperienceUnit>>(`${config.adminBaseUrl}/api/experience/units`, { params: filters });
    return units;
  }

  // get campaigns + offers
  getAllUnits(filters: Params): Observable<IListResponse<ExperienceUnitListItem>> {
    const units = this.http.get<IListResponse<ExperienceUnitListItem>>(`${config.adminBaseUrl}/api/experience/all_units`, { params: filters });
    return units;
  }

  getSingleUnit(id: number): Observable<ExperienceUnit> {
    const unit = this.http.get<ExperienceUnit>(`${config.adminBaseUrl}/api/experience/units/${id}`).pipe(
      map(v => _.cloneDeep(v)));
    return unit;
  }

  public async changeStatus(id: number, request: StatusRequest): Promise<void> {
    await lastValueFrom(this.http.patch<void>(`${config.adminBaseUrl}/api/experience/units/${id}/change_status`, request));
  }

  public async changeOrder(request: ChangeOrderRequest): Promise<void> {
    await lastValueFrom(this.http.patch<void>(`${config.adminBaseUrl}/api/experience/units/change_order`, request));
  }

  public async addUnit(request: ExperienceUnit): Promise<number> {
    return lastValueFrom(this.http.post<number>(`${config.adminBaseUrl}/api/experience/units`, request));
  }

  public async duplicateUnit(unitId: number): Promise<void> {
    await lastValueFrom(this.http.post<number>(`${config.adminBaseUrl}/api/experience/units/${unitId}/duplicate`, {}));
  }

  public async saveUnitAsOffer(unitId: number): Promise<void> {
    await lastValueFrom(this.http.post<number>(`${config.adminBaseUrl}/api/experience/units/${unitId}/save_as_offer`, {}));
  }

  public async updateUnit(id: number, request: ExperienceUnit): Promise<void> {
    return lastValueFrom(this.http.patch<void>(`${config.adminBaseUrl}/api/experience/units/${id}`, request));
  }

  // embedded groups
  // ---------------

  getUnitGroups(returnUsage: boolean = false): Observable<IListResponse<ExperienceUnitGroupListItem>> {
    return this.http.get<IListResponse<ExperienceUnitGroupListItem>>(`${config.adminBaseUrl}/api/experience/groups?returnUsage=${returnUsage}`);
  }

  addUnitGroup(request: ExperienceUnitGroup): Observable<number> {
    return this.http.post<number>(`${config.adminBaseUrl}/api/experience/groups`, request);
  }

  updateUnitGroup(request: ExperienceUnitGroup): Observable<void> {
    const { id, ...body } = request;
    return this.http.put<void>(`${config.adminBaseUrl}/api/experience/groups/${id}`, body);
  }

  uploadImage(request: ExperienceUnitImage): Observable<{ imgLink: string }> {
    request.resizeType ??= ImageResizeType.NoResize;
    const formData = new FormData();
    Object.keys(request).forEach(key => formData.append(key, request[key]));
    const res = this.http.post<{ imgLink: string }>(`${config.adminBaseUrl}/api/experience/uploadImage`, formData);
    return res;
  }

  deleteUnitGroup(groupId: number): Observable<void> {
    return this.http.delete<void>(`${config.adminBaseUrl}/api/experience/groups/${groupId}`);
  }

  // leads
  // -----

  getUnitsWithLeads(filters: Params): Observable<KeyValuePair[]> {
    // const fromDateStr = fromDate ? moment(fromDate).format('YYYY-MM-DD') : '';
    // const toDateStr = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
    return this.http.get<KeyValuePair[]>(`${config.adminBaseUrl}/api/Experience/units-with-leads`, { params: filters });
  }

  getUnitLeads(filters: Params): Observable<{ data: UnitLead[]; count: number }> {
    // const params: { [param: string]: string } = {
    //   period,
    // };
    // if (unitId) { params.unit_id = String(unitId); }
    // if (pageSize) { params.page_size = String(pageSize); }
    // if (page) { params.page = String(page); }
    //
    return this.http.get<{ data: UnitLead[]; count: number }>(`${config.insightsBaseUrl}/api/unitleads`, { params: filters });
  }

  getUnitLeadsCount(filters: Params): Observable<number> {
    // let query = `?period=${period}`;
    // if (unitId) {
    //   query += `&unit_id=${unitId}`;
    // }
    return this.http.get<number>(`${config.insightsBaseUrl}/api/unitleads/count`, { params: filters });
  }

  getUnitLeadById(leadId: number): Observable<UnitLead> {
    return this.http.get<UnitLead>(`${config.insightsBaseUrl}/api/unitleads/single/?lead_id=${leadId}`);
  }

  // others
  // ------

  getUnitsStats(filters: Params): Observable<DataResponse<InsightsUnitStats>> {
    return this.http.get<DataResponse<InsightsUnitStats>>(`${config.insightsBaseUrl}/api/personalization/units/stats`, { params: filters });
  }

  getUnitsGraphData(filters: Params): Observable<DataResponse<InsightsUnitGraphItem[]>> {
    return this.http.get<DataResponse<InsightsUnitGraphItem[]>>(`${config.insightsBaseUrl}/api/personalization/units/graph`, { params: filters });
  }

  getAllSurveyQuestions(): Observable<SurveyQuestionForAudience[]> {
    const questions = this.http.get<SurveyQuestionForAudience[]>(`${config.adminBaseUrl}/api/experience/survey-questions`);
    return questions;
  }

  getUnitAllTimeStats(unitId: number): Observable<DataResponse<UnitAllTimeStats>> {
    return this.http.get<DataResponse<UnitAllTimeStats>>(`${config.insightsBaseUrl}/api/personalization/unit/alltimestats/?unit_id=${unitId}`);
  }

  getSurveyResult(unitId: number): Observable<DataResponse<SurveyQuestionResult[]>> {
    return this.http.get<DataResponse<SurveyQuestionResult[]>>(`${config.insightsBaseUrl}/api/personalization/survey/results/?unit_id=${unitId}`);
  }

  getUnitTargeting(unit: ExperienceUnit): ExperienceTargets {
    return {
      predefinedAudiences: unit.predefinedAudiences,
      segmentIds: unit.segmentIds,
      audienceFilter: unit.audienceFilter,
      urlFilter: unit.urlFilter,
      deviceFilter: unit.deviceFilter,
      maFilter: unit.maFilter,
    };
  }
}
