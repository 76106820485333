import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, ValidationErrors } from '@angular/forms';
import { FieldName, SupportedCreativeExperience } from '../experiences';
import { ExperienceCreative, Theme, CustomCloseButton } from '../models';
import { ContentPoolListItem } from '../../models/content-pool';
import { lastValueFrom, Subscription } from 'rxjs';
import { ContentPoolService } from '../../services/content-pool.service';
import { UnitThemeComponent } from '../unit-theme/unit-theme.component';
import { MatSelectChange } from '@angular/material/select';
import { IntegrationType } from '../../settings/integrations/integrations';
import { IntegrationsService } from '../../settings/integrations/integrations.service';
import { UserNotificationService } from 'src/app/modules/shared/user-notification.service';
import { IntegrationStatus, MaField, MaList } from '../../models/integrations';
import { SurveySaveEvent } from '../experience-survey-edit/experience-survey-edit.component';
import { collapseExpandAnimation } from 'src/app/modules/shared/animations/collapse-expand.animation';

type BackgroundType = 'color' | 'image' | 'none';

@Component({
  selector: 'app-unit-creative-customize',
  templateUrl: './unit-creative-customize.component.html',
  styleUrls: ['./unit-creative-customize.component.scss'],
  animations: [collapseExpandAnimation]
})
export class UnitCreativeCustomizeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() unit: ExperienceCreative;
  @Input() selectedLayout: SupportedCreativeExperience;
  @Input() exportLeadsIntegrations: IntegrationStatus[];
  @Input() isPreStyleEdit = false;
  @Input() formContainerElement: HTMLElement;
  @Output() formTypeChange = new EventEmitter<string>();
  @ViewChild('customizeForm') form: NgForm;
  @ViewChild(UnitThemeComponent) themeComponent: UnitThemeComponent;

  IntegrationType = IntegrationType;
  contentPools: ContentPoolListItem[];
  backgroundType: BackgroundType;
  lastImageUrl: string;
  isAdvancedBg = false;
  isCustomCloseBtn = false;
  isShowButton = false;
  lastButtonText: string;
  isHeightCustom = false;
  isWidthCustom = false;
  isSurveyValid = true;
  formReset = false;
  isFieldsFormValid = true;
  exportLeadsMaFields: MaField[];
  exportLeadsMaLists: MaList[];
  maxRecommendCarouselDisplayOption = [1, 2, 3, 4, 5];

  private isChangedPrivate = false;
  private valueChangeSub: Subscription;

  constructor(
    private integrationsService: IntegrationsService,
    private notifications: UserNotificationService,
    private contentPoolsService: ContentPoolService,
  ) { }

  // API methods

  init(formReset: boolean): void {
    this.isAdvancedBg = !this.isColorSolid(this.unit.backgroundColor);
    this.lastButtonText = this.unit.buttonText;
    this.isShowButton = !!this.unit.buttonText?.trim();
    this.isHeightCustom = !!this.unit.height;
    this.isWidthCustom = !!this.unit.width;
    this.lastImageUrl = this.unit.imgUrl;
    this.backgroundType = this.unit.imgUrl ? 'image' : (this.unit.backgroundColor ? 'color' : 'none');
    this.formReset = formReset;
    this.isFieldsFormValid = formReset ? true : this.isFieldsFormValid;
    this.isCustomCloseBtn = !!this.unit.closeButton;
    if (this.unit.maProvider && !this.exportLeadsMaFields) {
      this.loadIntegrationsFieldsLists(this.unit.maProvider);
    }
    setTimeout(() => this.isChangedPrivate = false, 0);
  }

  changeTheme(theme: Theme, layout: SupportedCreativeExperience, simpleBgDefaultColor: string = null): void {
    this.themeComponent.changeTheme(theme, layout, simpleBgDefaultColor);
  }

  get isChanged() { return this.isChangedPrivate || this.themeComponent.isChanged; }

  // Component methods

  async ngOnInit(): Promise<void> {
    this.init(false);
    if (!this.isPreStyleEdit) {
      this.contentPools = await lastValueFrom(this.contentPoolsService.getList());
    }
  }

  ngAfterViewInit(): void {
    this.valueChangeSub = this.form.form.valueChanges.subscribe(() => this.isChangedPrivate = true);
    if (this.isPreStyleEdit) {
      this.changeTheme('custom', this.selectedLayout);
    }
  }

  ngOnDestroy(): void {
    this.valueChangeSub?.unsubscribe();
  }

  showField(fieldName: FieldName): boolean {
    if (this.selectedLayout) {
      if (this.selectedLayout.showFields && this.selectedLayout.showFields.length) {
        return this.selectedLayout.showFields.includes(fieldName);
      }
    }
    return false;
  }

  onBackgroundTypeChange(backgroundType: BackgroundType): void {
    this.backgroundType = backgroundType;
    this.unit.backgroundColor = backgroundType === 'color' ? (this.selectedLayout.overrides.backgroundColor || '#e8e6fe') : null;
    this.unit.imgUrl = backgroundType === 'image' ? this.unit.imgUrl = this.lastImageUrl : '';
  }

  onAdvanceBgChange(isAdvancedBg: boolean): void {
    this.isAdvancedBg = isAdvancedBg;
    if (!isAdvancedBg && !this.isColorSolid(this.unit.backgroundColor)) {
      this.unit.backgroundColor = '#ffffff';
    }
  }

  onCustomCloseBtnChange(isCustomCloseBtn: boolean): void {
    if (isCustomCloseBtn) {
      this.unit.closeButton = {
        shape: 'round',
        color: '#AEB0B7',
        position: 'inside',
        background: '#FFFFFF',
        border: '#AEB0B7',
        size: '17'
      } as CustomCloseButton;
    } else {
      this.unit.closeButton = null;
    }
    this.isCustomCloseBtn = isCustomCloseBtn;
  }

  onMaxDisplayPagesChange(): void {
    if (this.unit.recommendationsLimit < this.unit.recommendCarouselDisplayLimit) {
      this.unit.recommendationsLimit = this.unit.recommendCarouselDisplayLimit;
    }
  }

  onShowButtonChange(showButton: boolean): void {
    if (showButton) {
      this.unit.buttonText = this.lastButtonText || this.selectedLayout.overrides.buttonText;
    } else {
      this.lastButtonText = this.unit.buttonText;
      this.unit.buttonText = '';
    }
  }

  async loadIntegrationsFieldsLists(integrationId?: IntegrationType) {
    if (integrationId) {
      try {
        this.exportLeadsMaFields = (await lastValueFrom(this.integrationsService.getIntegrationsFields(integrationId)))
          .filter(field => field.readOnly === false);
        if (integrationId !== IntegrationType.salesforce) {
          this.exportLeadsMaLists = (await lastValueFrom(this.integrationsService.getIntegrationsLists(integrationId)))
            .filter(list => list.canPushLeads === true);
        }
      } catch (ex) {
        this.notifications.error(ex, 'Something went wrong. Please check Integration Connection.');
      }
    }
  }

  onSurveyChange(surveyEvent: SurveySaveEvent): void {
    this.unit.surveyQuestions = surveyEvent.questions;
    this.isSurveyValid = surveyEvent.isValid;
    this.isChangedPrivate = true;
  }

  onFieldsChange(fieldsData: any) {
    this.formReset = false;
    this.unit.formFields = fieldsData.formFields;
    this.isFieldsFormValid = fieldsData.isValid;
    // if (!fieldsData.fromInit && this.formContainerElement) {
    //   setTimeout(() => {
    //     this.formFieldsContainer.nativeElement.scroll({
    //       top: this.formFieldsContainer.nativeElement.scrollHeight,
    //       left: 0,
    //       behavior: 'smooth'
    //     });
    //   }, 100);
    // }
    // this.changeDetectorRef.detectChanges();
  }

  applyFormType(ev: MatSelectChange) {
    this.formTypeChange.emit(ev.value);
    this.unit.formFields = null;
    this.unit.maProvider = null;
    this.isFieldsFormValid = true;
    const maScript = this.unit.scriptCode ? this.unit.scriptCode : this.selectedLayout.overrides.scriptCode;
    this.unit.scriptCode = ev.value === 'leads-ma' ? maScript : undefined;
  }

  beforeUnloadingMarker(): void {
    const u = new URL(window.location.href);
    u.searchParams.set('load-inprog', 'true');
    window.history.pushState({}, this.unit?.name || 'Editor', u.toString());
  }

  get titleErrors(): ValidationErrors | null { return this.form?.form.get('Title')?.errors; }
  get isAnyExportLeadsIntegration(): boolean { return this.exportLeadsIntegrations && this.exportLeadsIntegrations.length > 0; }
  get isRecommendCarousel(): boolean { return this.unit.uiType === 'recommend' && this.unit.recommendIsCarousel; }
  get isFastext(): boolean { return this.unit.uiType === 'fastext'; }
  get isPowerbarImage(): boolean { return this.selectedLayout?.classes.includes('fixed-image'); }
  get videoScript(): AbstractControl { return this.form?.form.get('Video'); }

  get setOfAllowedRecommendations() {
    if (this.unit.visibilityType === 'slider') {
      if (this.unit.layoutId === 207) {// swider
        return [1];
      } else {
        return [1, 2];
      }
    } else if (this.unit.visibilityType === 'fixed') {
      return [1];
    }
    return [1, 2, 3, 4, 5, 6, 7, 8, 9];
  }

  private isColorSolid(bg: string): boolean {
    return !bg || bg.startsWith('rgb') || bg.startsWith('#');
  }
}
