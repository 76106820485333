<form>
    <mat-form-field class="full-width" [style.width]="width" [subscriptSizing]="heightSizing">
        <mat-label>{{placeholder && !selectedItem.value && !isFocused ? placeholder : label}}</mat-label>
        <input type="text" #input matInput [formControl]="selectedItem" [matAutocomplete]="auto"
            spellcheck="false" (focus)="isFocused = true" (blur)="isFocused = false">
        <button class="reset-btn" *ngIf="selectedItem.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="onResetSelection()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="dispalyName" panelWidth="auto"
            class="app-autocomplete-panel" classList="app-autocomplete-panel"
            (optionSelected)="onItemSelected($event.option.value)" (closed)="onPanelClosed()">
            <!-- grouped items -->
            <ng-container *ngIf="groupedItems">
                <mat-optgroup *ngFor="let group of groupedFilteredOptions | async" [label]="group.groupName">
                    <mat-option *ngFor="let option of group.items" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-optgroup>
            </ng-container>
            <!-- non grouped items -->
            <ng-container *ngIf="!groupedItems">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </ng-container>
            <mat-option *ngIf="isFilteredEmpty" [value]="null" [disabled]="true" translate>Not found</mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>