import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DigitsOnlyRegEx } from '../constants/validation-regex';

export const digitsValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }
    const value = control.value as string;
    return DigitsOnlyRegEx.test(value) ? null : { digits: true };
};

@Directive({
    selector: '[appDigits]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DigitsValidatorDirective, multi: true }]
})
export class DigitsValidatorDirective implements Validator {
    @Input('appDigits') isEnabled: boolean = true;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.isEnabled || (this.isEnabled as any) === '' ? digitsValidator(control) : null;
    }
}
