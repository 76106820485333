<form>
    <mat-form-field class="full-width">
        <mat-label translate>Choose Parameter</mat-label>
        <input type="text" matInput [formControl]="searchText" [matAutocomplete]="auto">
        <mat-icon matTextSuffix class="input-warn-icon" *ngIf="selectedItem?.isTooManyValues"
            [matTooltip]="tooManyValuesTooltip" matTooltipPosition="above">
            warning_amber</mat-icon>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="dispalyName"
            (optionSelected)="onItemSelected($event.option.value)" (closed)="onPanelClosed()">
            <mat-optgroup *ngFor="let group of ruleFieldGrouped">
                <mat-label translate>{{predicateGroupNameByValue(group.group)}}</mat-label>
                <mat-option *ngFor="let rule of group.fields" [value]="rule" #opt [disabled]="group.hidden"
                    [matTooltip]="rule.description">
                    <span translate>{{rule.name}}</span><br />
                    <span class='predicate-description' *ngIf="rule.description && !opt.selected"
                        translate>[{{rule.description}}]</span>
                </mat-option>
            </mat-optgroup>
            <mat-option disabled *ngIf="ruleFieldGrouped.length === 0" translate>
                No fields found
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>