import { Directive, OnInit, Input, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { AccountContextService } from '../modules/shared/services/account-context.service';
import { Subscription } from 'rxjs';
import { AccountFeature } from '../modules/shared/models/IAccount';

/*
  <div appFeature feature="FEATURE-NAME" action="disable">
  <div appFeature feature="FEATURE-NAME">
*/
@Directive({
  selector: '[appFeature]'
})
export class FeatureDirective implements OnInit, OnDestroy {
  @Input() feature: AccountFeature | AccountFeature[];
  @Input() action: 'hide' | 'disable' = 'hide';
  @Input() featureDisableCheck = false;
  sub: Subscription;
  constructor(private renderer: Renderer2, private elementRef: ElementRef, private accountContext: AccountContextService) {
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
  ngOnInit(): void {
    if (this.feature && !this.featureDisableCheck) {
      this.sub = this.accountContext.selectedAccount$.subscribe((acc) => {
        if (!this.isSupported(this.feature)) {
          if (!this.action || this.action === 'hide') {
            this.elementRef.nativeElement.style.display = 'none';
          } else {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'disabled');
            this.renderer.addClass(this.elementRef.nativeElement, 'disabled-module');
          }
        } else {
          if (!this.action || this.action === 'hide') {
            this.elementRef.nativeElement.style.display = null;
          } else {
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
            this.renderer.removeClass(this.elementRef.nativeElement, 'disabled-module');
          }
        }
      });
    }
  }

  private isSupported(feature: AccountFeature | AccountFeature[]): boolean {
    if (Array.isArray(feature)) {
      return !feature.map(f => this.accountContext.supports(f)).includes(false);
    } else {
      return this.accountContext.supports(feature);
    }
  }
}
