import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-value-edit',
  templateUrl: './value-edit.component.html',
  styleUrls: ['./value-edit.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ValueEditComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: ValueEditComponent, multi: true }
  ]
})
export class ValueEditComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() valueFullWidth = false;
  @Input() emptyValueText = '';
  @Input() delete = false;
  @Input() deleteTooltip = '';
  @Input() isDeleteDisabled = false;
  @Input() isInputDisabled = false;
  @Input() validators: ValidatorFn | ValidatorFn[];
  @Input() isMinHeight = false;
  @Input() editOnClick = false;
  @Output() deleted = new EventEmitter();
  @ViewChild('editInput') editInput: ElementRef;

  isEditMode = false;
  value = '';
  form = new FormGroup({
    editValue: new FormControl('')
  });
  propagateChange = (_: any) => { };
  onValidatorChange = () => { };
  isCancel = false;
  lastError: string = null;

  constructor() { }

  ngOnInit(): void {
    this.editValue.setValidators(this.validators);
    this.editValue.statusChanges.subscribe((status) => {
      const errorsString = JSON.stringify(this.editValue.errors);
      if (this.lastError !== errorsString) {
        this.onValidatorChange();
        this.lastError = errorsString;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isInputDisabled && this.editValue) {
      if (changes.isInputDisabled.currentValue) {
        this.editValue.disable();
      } else {
        this.editValue.enable();
      }
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.editValue.setValue(this.value);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // console.log('registerOnTouched');
  }
  setDisabledState?(isDisabled: boolean): void {
    // console.log('setDisabledState');
  }

  // Validator interface - start
  validate(control: AbstractControl): ValidationErrors {
    return this.editValue.errors;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }
  // Validator interface - end

  onStartEdit() {
    this.editValue.setValue(this.value);
    this.editValue.markAsUntouched();
    this.isEditMode = true;
    setTimeout(() => this.editInput.nativeElement.focus(), 0);
  }

  onEndEdit() {
    // the timeout gives a chance to onCancelEdit to run before this
    setTimeout(() => {
      if (!this.isCancel && this.form.valid) {
        this.value = this.editValue.value;
        this.isEditMode = false;
        this.propagateChange(this.value);
      } else if (this.isCancel && this.form.invalid) {
        this.editValue.setValue(this.value);
      }
      this.isCancel = false;
    }, 200);
  }

  onCancelEdit() {
    this.isCancel = true;
    this.isEditMode = false;
  }

  onDelete() {
    this.deleted.emit();
  }

  get editValue(): FormControl<string> {
    return this.form.controls.editValue;
  }

}
