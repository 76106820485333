import { Component, OnInit, Input, OnDestroy, HostBinding, Optional, Self, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, BehaviorSubject } from 'rxjs';
import { NgControl, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { filter, startWith } from 'rxjs/operators';
import { KeyValue } from 'src/app/modules/account/models/models';

export type MultiselectAutocompleteFloatType = 'never' | 'always' | 'auto';

@Component({
  selector: 'app-multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MultiselectAutocompleteComponent }]
})
export class MultiselectAutocompleteComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator, MatFormFieldControl<string[]> {
  static nextId = 0;
  selected: KeyValue[] = [];
  removable = true;
  filteredItems: KeyValue[] = [];
  lastFilteredItems: KeyValue[] = [];
  inputControl: FormControl<string>;
  isInputFocused = false;

  private changeCallback: (_: any) => void;
  private itemsListReceived = new BehaviorSubject<boolean>(false);

  @Input() itemsList: KeyValue[] = [];
  @Input() placeholderEmpty = '';
  @Input() floatLabel: MultiselectAutocompleteFloatType = 'never';
  @Input() disabledItems: string[] = [];
  @Input() hasArrowDown = false;


  // mat form control properties
  @Input() set value(ids: string[]) {
    if (ids) {
      this.setSelected(ids);
    }
    this.stateChanges.next();
  }
  get value() {
    return this.selected.map(item => item.id);
  }
  stateChanges = new Subject<void>();
  @HostBinding() id = `multiselect-autocomplete-${MultiselectAutocompleteComponent.nextId++}`;
  @Input()
  get placeholder() {
    return this.placeholderVal;
  }
  set placeholder(plh) {
    this.placeholderVal = plh;
    this.stateChanges.next();
  }
  private placeholderVal: string;
  focused = false;
  get empty(): boolean {
    return (!this.selected || this.selected.length === 0) && !this.inputControl.value;
  }
  get shouldLabelFloat(): boolean {
    switch (this.floatLabel) {
      case 'always': return true;
      case 'never': return false;
      case 'auto': return !this.empty || this.isInputFocused;
    }
  }
  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;
  @Input()
  get disabled(): boolean { return this.disabledVal; }
  set disabled(value: boolean) {
    this.disabledVal = coerceBooleanProperty(value);
    if (this.disabledVal) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
    this.stateChanges.next();
  }
  private disabledVal = false;
  private _errorStateVal = false;
  get errorState(): boolean {
    const newErrorState = this.required && this.selected.length === 0 && (this.inputControl.touched || this.ngControl.control.touched);
    if (newErrorState !== this._errorStateVal) {
      this._errorStateVal = newErrorState;
      this.stateChanges.next();
    }
    return this._errorStateVal;
  }
  controlType?: string; // unused
  autofilled?: boolean;
  @HostBinding('attr.aria-describedby') describedBy = '';
  // mat form control properties - end

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>) {

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  // mat form control methods
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }
  onContainerClick(event: MouseEvent): void {
    return;
  }
  // mat form control properties - end

  // control value accessor methods
  async writeValue(ids: string[] | string) {
    const idsArray = Array.isArray(ids) ? ids : ids ? ids.split(',') : [];
    if (idsArray.length === 0) {
      this.selected = [];
    } else {
      if (this.itemsListReceived.value) {
        this.setSelected(idsArray);
      } else {
        // items list not set yet - wait for it
        this.itemsListReceived.pipe(
          filter(x => x)
        ).subscribe(() => this.setSelected(idsArray));
      }
    }
  }
  registerOnChange(fn: (_: any) => void) {
    this.changeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }
  // control value accessor methods - end

  validate(control: AbstractControl): ValidationErrors {
    return this._required && this.selected.length === 0 ? { required: true } : null;
  }
  // registerOnValidatorChange?(fn: () => void): void {
  //   throw new Error('Method not implemented.');
  // }

  async ngOnInit(): Promise<void> {
    this.inputControl = new FormControl({ value: '', disabled: this.disabled });

    this.inputControl.valueChanges
      .pipe(startWith(''))
      .subscribe(filteredText => {
        if (this.itemsListReceived.value) {
          this.setFilteredItems(filteredText);
        } else {
          // items list not set yet - wait for it
          this.itemsListReceived.pipe(
            filter(x => x)
          ).subscribe(() => this.setFilteredItems(filteredText));
        }
      });
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsList) {
      if (Array.isArray(changes.itemsList.currentValue) && changes.itemsList.currentValue.length > 0) {
        this.lastFilteredItems = [];
        this.setFilteredItems('');
        this.itemsListReceived.next(true);
      }
    }
  }

  remove(item: KeyValue) {
    const itemIndex = this.selected.indexOf(item);
    if (itemIndex >= 0) {
      this.selected.splice(itemIndex, 1);
      this.emitChange();
    }
  }

  toggleSelection(item: KeyValue) {
    const itemIndex = this.selected.findIndex(x => x.id === item.id);
    if (itemIndex >= 0) {
      this.selected.splice(itemIndex, 1);
    } else {
      this.selected.push(item);
    }
    this.inputControl.setValue('');
    this.emitChange();
  }

  isItemSelected(item: KeyValue): boolean {
    return !!(this.selected?.find(x => x.id === item.id));
  }

  get inputPlaceholder(): string {
    return this.empty && this.placeholderEmpty ? this.placeholderEmpty : this.placeholder;
  }

  private setFilteredItems(filterText: string) {
    if (this.filteredItems.length > 0) {
      this.lastFilteredItems = this.filteredItems;
    }
    this.filteredItems = this.getFilteredItems(filterText);
    this.stateChanges.next();
  }

  private getFilteredItems(filterText: string): KeyValue[] {
    const filteredItems = (typeof filterText !== 'string' || filterText === '')
      ? this.itemsList
      : this.itemsList.filter(x => x.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()));
    return filteredItems.slice(0, 100);
  }

  private setSelected(ids: string[]) {
    this.selected = this.itemsList.filter(item => ids.includes(item.id));
  }

  private emitChange() {
    if (this.changeCallback) {
      this.changeCallback(this.value);
    }
  }
}
