import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-multi-freetext-autocomplete',
  templateUrl: './multi-freetext-autocomplete.component.html',
  styleUrls: ['./multi-freetext-autocomplete.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: MultiFreeTextAutocompleteComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: MultiFreeTextAutocompleteComponent }
  ]
})
export class MultiFreeTextAutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input() itemsList: string[] = [];
  @Input() placeholder = '';
  @Input() placeholderEmpty = '';
  @Input() required = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  selected: string[] = [];
  filteredItems: string[] = [];
  inputControl: FormControl<string>;
  onChange = (selected: string[]) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;
  prevIsValid = true;
  onValidatorChange = () => { };

  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChild('inputTrigger') inputTrigger: ElementRef<HTMLInputElement>;

  constructor() { }

  ngOnInit(): void {
    this.inputControl = new FormControl('');

    this.inputControl.valueChanges
      .pipe(startWith(''))
      .subscribe(filteredText => {
        this.setFilteredItems(filteredText);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsList) {
      if (Array.isArray(changes.itemsList.currentValue)) {
        this.setFilteredItems(this.inputControl?.value ?? '');
      }
    }
  }

  // implementing ControlValueAccessor
  writeValue(selectedItems: string[]): void {
    this.selected = selectedItems?.slice() ?? [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // implementing ControlValueAccessor - end

  // implementing Validator interface
  validate(control: AbstractControl): ValidationErrors {
    return this.isValid ? null : { required: true };
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }
  // implementing Validator interface - end

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    const item = event.option.viewValue;
    if (!this.selected.includes(item)) {
      this.selected.push(item);
      this.handleValueChange();
    }
    this.setFilteredItems(this.inputControl.value);
    setTimeout(() => this.autoTrigger.openPanel(), 0);

    // Clear the input value
    this.inputTrigger.nativeElement.value = '';
    this.inputControl.setValue(null);
  }

  addFreeTextItem(event: MatChipInputEvent): void {
    const item = (event.value || '').trim();
    if (item && !this.selected.includes(item)) {
      this.selected.push(item);
      this.handleValueChange();
    }

    // Clear the input value
    event.chipInput.clear();
    this.inputControl.setValue(null);
  }

  remove(item: string) {
    const itemIndex = this.selected.indexOf(item);
    if (itemIndex >= 0) {
      this.selected.splice(itemIndex, 1);
      this.setFilteredItems(this.inputControl.value);
      this.handleValueChange();
    }
  }

  get inputPlaceholder(): string {
    return this.selected.length === 0 && this.placeholderEmpty ? this.placeholderEmpty : this.placeholder;
  }

  get isValid(): boolean { return !this.required || this.selected.length > 0; }

  private setFilteredItems(filterText: string) {
    if (this.itemsList) {
      const unselectedItems = this.itemsList.filter(i => !this.selected.includes(i));
      const allFilteredItems = (typeof filterText !== 'string' || filterText === '')
        ? unselectedItems
        : unselectedItems.filter(x => x.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()));
      this.filteredItems = allFilteredItems.slice(0, 100);
    } else {
      this.filteredItems = [];
    }
  }

  private handleValueChange(): void {
    if (this.prevIsValid !== this.isValid) {
      this.prevIsValid = this.isValid;
      this.inputControl.setErrors(this.validate(null));
      this.onValidatorChange();
    }
    this.onChange(this.selected);
  }
}
