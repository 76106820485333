import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SubscriptSizing } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { KeyValuePair, KeyValueGroup } from '../../models/models';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AutocompleteComponent }
  ]
})
export class AutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() itemsList: KeyValuePair[] = [];
  @Input() groupedItems: KeyValueGroup[];
  @Input() emptyValueAllowed = false;
  @Input() emptyValue: string = null;
  @Input() required = false;
  @Input() width: string;
  @Input() heightSizing: SubscriptSizing = 'fixed';
  selectedItem = new UntypedFormControl(null);
  filteredOptions: Observable<KeyValuePair[]>;
  groupedFilteredOptions: Observable<KeyValueGroup[]>;
  isFilteredEmpty = false;
  isItemSelected = false;
  isResetSelection = false;
  isFocused = false;
  lastSelectedId: string;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  onChange = (id: string) => { };

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.required) {
      this.selectedItem.setValidators(Validators.required);
    }

    this.filteredOptions = this.selectedItem.valueChanges
      .pipe(
        filter(_ => !!this.itemsList),
        startWith(''),
        map(value => typeof value === 'string' || typeof value === 'undefined' ? value : value.name),
        map(name => name ? this.filteredList(name) : (this.itemsList?.slice() || [])),
        tap(list => {
          this.isFilteredEmpty = this.itemsList ? list.length === 0 : this.isFilteredEmpty;
          this.changeDetectorRef.detectChanges();
        })
      );
    this.groupedFilteredOptions = this.selectedItem.valueChanges
      .pipe(
        filter(_ => !!this.groupedItems),
        startWith(''),
        map(value => typeof value === 'string' || typeof value === 'undefined' ? value : value.name),
        map(name => name ? this.filteredGroupList(name) : (this.groupedItems?.slice() || [])),
        tap(list => {
          this.isFilteredEmpty = this.groupedItems ? list.length === 0 : this.isFilteredEmpty;
          this.changeDetectorRef.detectChanges();
        })
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemsList?.currentValue?.length || changes.groupedItems?.currentValue?.length) {
      this.setLastSelectedItem();
    }
  }

  // ControlValueAccessor interface - start
  writeValue(id: string): void {
    this.lastSelectedId = id;
    this.setLastSelectedItem();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // console.log('AutocompleteComponent registerOnTouched');
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectedItem.disable();
    } else {
      this.selectedItem.enable();
    }
  }
  // ControlValueAccessor interface - end

  onItemSelected(item: KeyValuePair): void {
    this.isItemSelected = true;
    this.lastSelectedId = item.id;
    this.onChange(item.id);
  }

  onResetSelection(): void {
    this.isResetSelection = true;
    this.selectedItem.setValue('');
    if (this.emptyValueAllowed) {
      this.onChange(this.emptyValue);
    }
    setTimeout(() => this.autocompleteTrigger.openPanel(), 0);
  }

  onPanelClosed(): void {
    if (!this.isResetSelection) {
      if (this.emptyValueAllowed && !this.selectedItem.value) {
        this.lastSelectedId = null;
        this.onChange(this.emptyValue);
      } else if (!this.isItemSelected && !this.emptyValueAllowed) {
        this.setLastSelectedItem();
        setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
      }
    } else if (!this.emptyValueAllowed) {
      this.setLastSelectedItem();
    }

    this.isResetSelection = false;
    this.isItemSelected = false;
  }

  dispalyName(item: KeyValuePair): string {
    return item?.name;
  }

  private filteredList(name: string): KeyValuePair[] {
    const filterValue = name.toLowerCase();
    return this.itemsList.filter(i => i.name.toLowerCase().includes(filterValue));
  }

  private filteredGroupList(name: string): KeyValueGroup[] {
    const filterValue = name.toLowerCase();
    const filteredGroups: KeyValueGroup[] = [];
    for (const group of this.groupedItems) {
      filteredGroups.push({ groupName: group.groupName, items: group.items.filter(i => i.name.toLowerCase().includes(filterValue)) });
    }
    return filteredGroups.filter(g => g.items.length > 0);
  }

  private setLastSelectedItem(): void {
    let item = this.itemsList?.find(x => x.id === this.lastSelectedId);
    item = item ?? this.groupedItems?.flatMap(g => g.items).find(x => x.id === this.lastSelectedId);
    this.selectedItem.setValue(item);
  }
}
