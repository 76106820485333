import { Component, OnInit, Input } from '@angular/core';
import { ExperienceUnit, ExperienceUnitGroupListItem, ThemeSpec, UnitGroupType } from '../models';
import { lastValueFrom, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import LZString from 'lz-string';
import { ExperienceService } from '../../services/experience.service';
import { PreSavedParamsService } from 'src/app/modules/shared/services/pre-saved-params.service';

@Component({
  selector: 'app-unit-preview',
  templateUrl: './unit-preview.component.html',
  styleUrls: ['./unit-preview.component.scss']
})
export class UnitPreviewComponent implements OnInit {

  @Input() unit: ExperienceUnit;
  @Input() inAccount = true;
  @Input() maxHeight = 677;
  @Input() hideTopButtons = false;
  previewType: 'desktop' | 'mobile' = 'desktop';
  previewHeight: number;
  urlPreviewSubject = new Subject<string>();
  previewUrlStr: string;
  showPreviewForm = false;
  pageUrl: string;
  compressedParams: string;
  allGroups: ExperienceUnitGroupListItem[] = [];

  constructor(
    private experienceService: ExperienceService,
    private preSavedParamsService: PreSavedParamsService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.inAccount) {
      const groupsRes = await lastValueFrom(this.experienceService.getUnitGroups());
      this.allGroups = groupsRes.items;
    }

    const compressedUrl = this.getCompressedPreviewUrl();
    this.previewUrlStr = `${environment.engagmentPreviewBaseUrl}/preview-unit.html?layoutType=${this.unit.uiType}&p=${compressedUrl}`;
    this.compressedParams = compressedUrl;

    this.urlPreviewSubject.pipe(
      distinctUntilChanged(),
      debounceTime(800),
    )
      .subscribe(newQueryString => {
        this.previewUrlStr = `${environment.engagmentPreviewBaseUrl}/preview-unit.html?layoutType=${this.unit.uiType}&p=${newQueryString}`;
        this.compressedParams = newQueryString;
      });
  }

  get previewUrlDetector(): string {
    const compressedUrl = this.getCompressedPreviewUrl();
    this.urlPreviewSubject.next(compressedUrl);
    return compressedUrl;
  }

  getCompressedPreviewUrl(): string {
    const group = this.unit.groupId ? this.allGroups.find(g => g.id === this.unit.groupId) : null;
    const groupConfig = group?.groupType === UnitGroupType.AutoEmbed ? {
      selector: group.selector,
      placementRules: group.placementRules
    } : null;

    const previewObj = {
      id: this.unit.unitId,
      viewModelId: this.unit.guid,
      params: {
        backgroundColor: this.unit.backgroundColor,
        textColor: this.unit.textColor,
        buttonBackgroundColor: this.unit.buttonBackgroundColor,
        buttonTextColor: this.unit.buttonTextColor,
        secondaryTextColor: this.unit.secondaryTextColor,
        carouselIndicatorColor: this.unit.recommendCarouselIndicatorColor,
      } as ThemeSpec,
      css: this.unit.css?.replace(/{{ID}}/g, this.unit.guid).replace(/{{VISIBILITY}}/g, this.unit.visibilityType),
      model: {
        ...this.unit,
        txt: this.unit.subTitle,
        limit: this.unit.recommendationsLimit,
        fields: this.unit.formFields,
        logoRelativeUrl: this.unit.imgUrl,
        groupId: group?.name,
        groupConfig,
        recommendIsCarousel: this.unit.recommendIsCarousel,
        displayLimit: this.unit.recommendCarouselDisplayLimit,
        carouselShowIndicator: this.unit.recommendCarouselShowIndicator,
      }
    };

    const compressed = LZString.compressToEncodedURIComponent(JSON.stringify(previewObj));
    return compressed;
  }

  getPreviewHeight(currentHeight: number, currentWidth: number): number {
    if (this.previewType === 'desktop') {
      return this.previewHeight ? this.previewHeight : this.previewHeight = Math.max(Math.min(currentHeight, window.screen.availHeight), this.maxHeight);
    } else {
      return 667;
    }
  }
  getPreviewWidth(currentHeight: number, currentWidth: number): string {
    if (this.previewType === 'desktop') { return '100%'; }
    return '375';
  }

  async openPreviewPage(): Promise<void> {
    const paramsId = await this.preSavedParamsService.create('unit', this.compressedParams);
    const separator = this.pageUrl.indexOf('?') < 0 ? '?' : '&';
    const queryParams = this.preSavedParamsService.getQueryParameters(paramsId, 'unit');
    const url = `${this.pageUrl}${separator}${queryParams}&td=demo`;
    this.showPreviewForm = false;
    window.open(url, '_blank');
  }

}
