import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appSpinnerColor]'
})
export class SpinnerColorDirective implements AfterViewInit {

    @Input('appSpinnerColor') color: string;

    constructor(
        private elem: ElementRef
    ) { }

    ngAfterViewInit() {
        const element = this.elem.nativeElement;
        const circle = element.querySelector('circle');
        circle.style.stroke = this.color;
        circle.style['stroke-linecap'] = 'round';
    }
}
