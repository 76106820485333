<div class="select-container" [class.select-container-small]="size === 'small'">
    <div *ngFor="let type of showTypes" class="type-select" [class.select-container-disabled]="isDisabled(type.id)"
        (click)="onUnitTypeSelected(type.id)">
        <div *ngIf="showTopArrow && !isDisabled(type.id)" class="type-select-top-arrow">
            <div class="type-select-top-arrow-inner" lottie [options]="animationOptions"></div>
        </div>

        <img class="type-select-image" src="{{type.image}}" @showHideAnimation/>
        <div class="name h4" translate>{{type.name}}</div>
        <div class="description h6" translate>{{type.description}}</div>
        <div></div>
    </div>
</div>