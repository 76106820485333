<div class="layout">
    <!-- layout name -->
    <div *ngIf="!layout.demoImage" class="layout-name" [class.active]="isSelected" (click)="onSelected()">
        <div>{{layout.name}}</div>
    </div>
    <!-- layout thumbnail -->
    <div *ngIf="layout.demoImage" class="layout-image" [matTooltip]="layout.name" matTooltipPosition="above"
        (click)="onSelected()">
        <img [src]="layout.demoImage" [class.active]="isSelected">
    </div>
    <!-- layout actions -->
    <div class="layout-actions-container" [class.hide-actions]="!showActions">
        <mat-icon class="layout-action" appPermission permission="account:editor" action="invisible" (click)="onEdit()">
            edit</mat-icon>
        <mat-icon class="layout-action" *ngIf="showStatusChange && status === Status.Active" appPermission
            permission="account:editor" action="invisible" (click)="onStatusChange(Status.Paused)">
            pause </mat-icon>
        <mat-icon class="layout-action" *ngIf="showStatusChange && status === Status.Paused" appPermission
            permission="account:editor" action="invisible" (click)="onStatusChange(Status.Active)">
            play_arrow</mat-icon>
        <mat-icon class="layout-action" appPermission permission="account:editor" action="invisible"
            (click)="onStatusChange(Status.Closed)">delete</mat-icon>
    </div>
</div>