import { JourneyOverview, JourneyOverviewCircle, JourneyOverviewInfo } from '../../../account/models/models';
import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash-es';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-journey-overview',
  templateUrl: './journey-overview.component.html',
  styleUrls: ['./journey-overview.component.scss'],
  providers: [TruncatePipe]
})


export class JourneyOverviewComponent implements OnInit {

  @Input() details: JourneyOverview;
  @Input() showPeriodSessionInfo = true;

  circles: JourneyOverviewCircle[] = [];
  startInfo: JourneyOverviewInfo;
  index: number;

  StartWithBreakLine = false;
  info: JourneyOverviewInfo;
  loading = false;

  completeImagePath = '/assets/insights/goal_completed.png';
  defaultImagePath = '/assets/insights/slider_image.png';

  constructor(
    private truncatePipe: TruncatePipe,
    private translate: TranslateService) {
  }

  ngOnInit() {
    if (this.details) {

      this.info = this.details.startitem;
      if (!this.details.circles[0]) {
        this.StartWithBreakLine = true;
      }
      _.filter(this.details.circles, (v: JourneyOverviewCircle) => {
        if (v) {
          v.formatted = {};
          this.circles.push(v);
        }
      });

      const countCircle = this.circles.length;
      const sumDuration = this.calcSumDuration(this.details.circles);

      Promise.all(_.map(this.circles, item => this.validateLoadImages(item, this.defaultImagePath, this.completeImagePath))).then((result) => {
        this.circles = _.map(this.circles, (v: JourneyOverviewCircle, i: number) => {
          v.goalname = this.truncatePipe.transform(v.goalname, [20, '...']);
          v = this.formattingValue(v);
          if (i < countCircle - 1) {
            v.formatted.arrowType = this.getArrowBetweenCircle(sumDuration, this.circles[i + 1], countCircle);
          }
          return v;
        });
        this.loading = false;
      });
    }
  }


  private calcSumDuration(cirles: JourneyOverviewCircle[]): number {
    const durationArrary = _.map(this.circles, (item) => item.duration);
    return _.reduce(durationArrary, (sum, item) => sum + item, 0);
  }

  private formattingValue(circle: JourneyOverviewCircle): JourneyOverviewCircle {
    if (circle.imgdef === 2) {
      circle.imgsrc = this.defaultImagePath;
    }
    if (circle.session_count === 0) {
      circle.formatted.session = this.translate.instant('same session');
    } else {
      circle.formatted.session = `${circle.session_count} ${this.translate.instant('session(s)')}`;
    }
    if (circle.pages === 0) {
      circle.formatted.pages = this.translate.instant('same pages');
    } else {
      circle.formatted.pages = `${circle.pages} ${this.translate.instant('page(s)')}`;
    }
    if (circle.imgdef === 1) {
      circle.imgsrc = this.completeImagePath;
    }
    return circle;
  }

  private validateLoadImages(circle: JourneyOverviewCircle, defaultImagePath: string, completeImagePath: string) {
    return new Promise((resolve, reject) => {
      if (circle.imgdef === 0) {
        const image = new Image();
        image.src = circle.imgsrc;
        image.onload = () => resolve(circle);
        image.onerror = () => {
          circle.imgsrc = defaultImagePath;
          resolve(circle);
        };
      } else if (circle.imgdef === 1) {
        circle.imgsrc = defaultImagePath;
        return resolve(circle);
      } else {
        circle.imgsrc = completeImagePath;
        return resolve(circle);
      }
    });
  }

  private getArrowBetweenCircle(allDuration: number, nextCircle: JourneyOverviewCircle, countCircle: number): string {
    const percent = _.round(nextCircle.duration * 100 / (allDuration || 1));
    if (countCircle === 2) {
      return 'long';
    } else {
      if (percent <= 30) {
        return 'short';
      } else if (percent > 30 && percent < 60) {
        return 'long';
      } else {
        return 'longer';
      }
    }
  }



}
